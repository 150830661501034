import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { MenuService } from '../services/menu.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { ConfirmationComponent } from '../../../../shared/components/dialog/confirmation/confirmation.component';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ConstantService } from '../../../../shared/services/constant.service';
import { muyBiLogo } from '../../../../model/const/common.const';
import { NoRecordsAvailableComponent } from 'src/app/shared/components/common/no-records-available/no-records-available.component';


@Component({
  selector: 'app-menu-list',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    InfiniteScrollModule,
    TranslateModule,
    MatProgressBarModule,
    NoRecordsAvailableComponent,
  ],
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss'],
  providers: [EncryptionService]
})
export class MenuListComponent {
  totalRecord: number = 0;
  subscribeListAPI!: Subscription;
  subscribeDeleteAPI!: Subscription;
  serachMenu: String = '';
  menuListData: any;
  private menuService = inject(MenuService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private encDecObj = inject(EncryptionService);
  constantService = inject(ConstantService)
  muybiLogo = muyBiLogo;
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  pageNumber: number = 1;
  perPages: number = 25;
  menuSearchPayload = {
    searchValue: '',
  };
  menuProgressLoader = false;
  menuPermission: any;
  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.menuProgressLoader = state;
    });
  }

  ngOnInit(): void {
    this.menuPermission = this.constantService.getModuleWiseRole('menu');

    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
    this.getMenuList();
  }

  //#region Menu List

  getMenuList() {
    this.pageNumber = 1;
    let menuPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
      search_text: this.menuSearchPayload.searchValue
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.menuService.getMenuList(menuPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.menuListData = res.data.data;
          this.totalRecord = res.data.pagination.total;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  menuListScrollDown() {
    this.pageNumber += this.pageNumber;
    let menuPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      business_location_id: this.selectedBranchLocationId,
    }
    this.constantService.progressLoader.next(true);
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.subscribeListAPI = this.menuService.getMenuList(menuPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.totalRecord = res.data.pagination.total;
          this.menuListData = [...this.menuListData, ...res.data.data];
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion menu List

  //#region Search Menu
  serachMenus() {
    this.serachMenu = this.serachMenu.trimStart();
    const searchText = this.serachMenu.trim();

    if (searchText !== this.menuSearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.menuSearchPayload.searchValue = searchText;
        this.getMenuList();
      } else if (!searchText && this.menuSearchPayload.searchValue !== '') {
        this.menuSearchPayload.searchValue = '';
        this.getMenuList();
      }
    }
  }

  //#endregion Search Menu

  createEditMenu(action: string, id?: number) {
    if (action == 'Add') {
      this.router.navigate(['/menu/create']);
    } else {
      this.router.navigate([`/menu/edit/${id}`]);
    }
  }

}
