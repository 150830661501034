import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class WaitingListService {

  constructor(private httpClient: HttpClient) { }

  getWaitingList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.pageNumber);
    params = params.append('per_page', data.perPages);
    params = params.append('business_location_id', data.business_location_id);
    return this.httpClient.get<ResponseMessage>('get-waitinglist', { params });
  }

  getQueueNumber(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('business_location_id', data.business_location_id);
    return this.httpClient.get<ResponseMessage>(`get-queue-number`, { params });
  }

  addWaiting(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('add-waiting', data);
  }

  getWaitingDetail(id: any): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`get-waiting-details/${id}`);
  }

  updateWaiting(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('update-waiting', data);
  }

  deleteWaiting(id: any): Observable<ResponseMessage> {
    return this.httpClient.delete<ResponseMessage>(`delete-waiting/${id}`);
  }

  sendWaitingAlert(id: any): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`send-waiting-alert/${id}`);
  }
}
