<div class="block">
  <div class="mb-16"
    [ngClass]="{'flex flex-justify-between flex-wrap gap-8px  flex-align-center section-box': !isOpenDialog}">
    <h1 class="text-grey-light mb-0">{{'image_library' | translate}}</h1>

    <button mat-flat-button *ngIf="!isOpenDialog" class="btn-danger btn-lg"
      [disabled]="selectedImageCheckboxes.length == 0" (click)="deleteImageFromLibrary()">
      {{'delete_all' | translate}}
    </button>
  </div>

  <div class="main-panel custom-scroll overflow-auto" infinite-scroll (scrolled)="getScrolledDown()"
    [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="3" [infiniteScrollThrottle]="0">
    <div class="image-gridcontainer mb-28">
      <div
        class="uploadimage-bx text-center flex flex-column flex-align-center flex-justify-center bg-grey-lighter cursor-pointer"
        (click)="openDialog()">
        <span class="icon-image-plus mb-16 d-block fileicon"></span>
        <span class="d-block fw-bold text-grey-medium fs-14">{{'upload_image' | translate}}</span>
        <input type="file" type="file" class="form-control" id="upload-image" (change)="uploadImage($event)"
          accept=".png, .jpg, .jpeg" #fileInput hidden (click)="imageCheck()">
      </div>
      <ng-container *ngIf="imageLibrayryArr.length > 0">


        <div class="image-uploadedbox" *ngFor="let image of imageLibrayryArr;let index= index;">
          <ng-container *ngIf="image.url">
            <mat-checkbox [id]="'image_'+index" (change)="changeImageCheckboxe($event,image.media_id)"
              [checked]="isChecked(image.media_id)">
            </mat-checkbox>
            <img [src]="image.url" alt="Image 1" class="img-fit" />
          </ng-container>
        </div>
      </ng-container>
    </div>

    <!-- <div class="block text-center flex-align-center" *ngIf="imageLibrayryArr.length == 0">
      <img src="assets/images/empty-state.png" alt="No Record Found" class="mb-24" />
      <div class="text-primary fw-medium">{{'no_records' | translate}}</div>
    </div> -->

    <ng-container *ngIf="imageLibrayryArr.length == 0">
      <app-no-records-available></app-no-records-available>
    </ng-container>


  </div>


  <div *ngIf="isOpenDialog"
    class="flex-wrap flex-justify-end gap-16px btn-wrapper grid grid grid-column  mobile-halfgrid">
    <button mat-flat-button (click)="cancel()" class="btn-outline-primary">{{'cancel' | translate}}</button>
    <button mat-flat-button (click)="selectImage()" [disabled]="selectedImageCheckboxes.length == 0"
      class="btn-half">{{'select' | translate}}</button>
  </div>

</div>