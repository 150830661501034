
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableDynamicClasses',
  standalone: true,
})
export class TableDynamicClassPipe implements PipeTransform {
  transform(
    table: any,
    type: 'table' | 'text',
    selectTableType?: number,
    selectedTableId?: number
  ): { [key: string]: boolean } {
    if (type === 'table') {
      // Calculate table-specific classes
      return {
        'available': table.status === 1 && selectTableType === 1,
        'not-available': table.status === 2 && selectTableType === 2,
        'cursor-pointer': table.status === 1,
        'selected': table.status === 1 && table.id === selectedTableId,
      };
    } else if (type === 'text') {
      // Calculate text-specific classes
      return {
        'text-green': table.status === 1,
        'text-red': table.status === 2,
      };
    }
    return {}; // Default fallback
  }
}

