import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, inject, } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InvoiceService } from '../service/invoice.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ConstantService } from '../../../../shared/services/constant.service';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { ToastrService } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductListingComponent } from '../../checkout/product-listing/product-listing.component';
import { monthList } from '../../../../model/const/common.const';
import { TrimValueDirective } from '../../../../shared/directives/trim-value.directive';
import { CardMaskDirective } from '../../../../shared/directives/card-mask.directive';
import { FlotNumberDirective } from '../../../../shared/directives/flot-number.directive';
import { ShareViaEmailMobileComponent } from '../../../../shared/components/dialog/share-via-mobile-email/share-via-mobile-email.component';
import { DayMonthYearFormatDirective } from '../../../../shared/directives/day-month-year-format.directive';
import { EncryptionService } from 'src/app/shared/services/encryption.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UppercaseAlphaNumericDirective } from 'src/app/shared/directives/uppercase-alpha-numeric.directive';
import { InvoicePreviewComponent } from '../invoice-preview/invoice-preview.component';

@Component({
  selector: 'app-create-invoice',
  standalone: true,
  providers: [
    DatePipe,
    EncryptionService,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule,
    MatExpansionModule,
    MatTooltipModule,
    TranslateModule,
    CardMaskDirective,
    // FlotNumberDirective,
    TrimValueDirective,
    UppercaseAlphaNumericDirective,
    // DayMonthYearFormatDirective
  ],
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent {
  fb = inject(FormBuilder);
  route = inject(Router);
  dialog = inject(MatDialog);
  datePipe = inject(DatePipe);
  encDecObj = inject(EncryptionService);
  invoiceService = inject(InvoiceService);
  toastr = inject(ToastrService);
  cdr = inject(ChangeDetectorRef);
  ac = inject(ActivatedRoute);
  editId = this.ac.snapshot.params['id'];
  selectedBranchLocationId = 0;
  constantService = inject(ConstantService);
  addedItem: any = this.route.getCurrentNavigation()?.extras.state;
  createInvoiceForm!: FormGroup;
  paymentMethodForm1!: FormGroup;
  paymentMethodForm2!: FormGroup;
  subscribeAPI!: Subscription;
  displayedColumns: string[] = ['action', 'items', 'price', 'qty', 'itemTotal'];
  menuCode: any;
  paymentMethod = '2';
  selectedBranchLocation = 'select_business_location';
  monthsList = monthList;
  yearsList: number[] = [];
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  orderItems: any = new MatTableDataSource([]);
  initialSubTotal: any = 0;
  calulatedSubtotal: any = 0;
  sharViaFlag: boolean = false
  gift_card: any = '';
  toDay = new Date();
  taxPar = 5;
  tax = 0;
  minDueDate: any;
  panelOpenState = true;
  invoiceProgressLoader = false;
  platform: string = 'web';
  frequencyOptions = [
    { value: '1', label: 'weekly' },
    { value: '2', label: 'monthly' },
    { value: '3', label: 'quaterly' },
    { value: '4', label: 'yearly' },
    { value: '5', label: 'custom_date' }
  ];
  paymentObj: any = {
    subTotal: 0,
    creditCardProcessingFee: 0,
    tax: 0,
    discount: 0,
    lateFee: 0,
    shippingFee: 0,
    total: 0
  }
  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.invoiceProgressLoader = state;
    });
    window.addEventListener('message', this.receiveMessageFromApp.bind(this), false);
  }

  ngOnInit() {
    this.platform = this.constantService.getFlutterAppPlatform();
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    this.minDueDate = tomorrow
    this.initForm();
    this.calculateSubTotal();
    if (!this.editId) {
      if (localStorage.getItem(this.selectedBranchLocation)) {
        const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
        this.selectedBranchLocationId = Number(id);
        this.getInvoiceId(this.selectedBranchLocationId);
      }
    }
  }
  initForm() {
    this.createInvoiceForm = this.fb.group({
      id: [''],
      customer_first_name: ['', Validators.required],
      customer_last_name: ['', Validators.required],
      customer_email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      invoice_title: ['', Validators.required],
      invoice_id: [{ value: '', disabled: true }, Validators.required],
      message: [''],
      service_date: ['', Validators.required],
      due_date: ['', Validators.required],
      invoice_status: [''],
      send_option: ['1', Validators.required],
      schedule_custom_date: [''],
      frequency: ['1', Validators.required],
      recurring_type: [''],
      order_items: [],
      discount: [''],
      processing_fee: [''],
      late_fee: [''],
      total_amount: [''],
      payment_method: ['1', Validators.required],
      giftcard_id: [''],
      share_email_phone_number: [''],
      share_via: [''],
      user_business_location_id: [''],
      sub_total: [],
      tax: [''],
    });
    this.paymentMethodForm1 = this.fb.group(({
      card_number: ['',],
      card_holder_name: [''],
      mm: [''],
      yy: [''],
      cvv: ['']
    }))
    this.paymentMethodForm2 = this.fb.group({
      gift_card: ['']
    });
    if (this.editId) {
      this.minDueDate = '';
      this.createInvoiceForm.patchValue({ id: this.editId })
      this.getInvoice();
    }
    this.generateYearRange();
    this.onYearChange();
  }
  private generateYearRange() {
    for (let i = 0; i <= 10; i++) {
      this.yearsList.push(this.currentYear + i);
    }
  }
  checkServiceDate() {
    if (this.createInvoiceForm.value.service_date && this.createInvoiceForm.value.due_date) {
      let serviceDate = new Date(this.createInvoiceForm.value.service_date).getTime();
      let dueDate = new Date(this.createInvoiceForm.value.due_date).getTime();
      if (serviceDate > dueDate) {
        this.createInvoiceForm.patchValue({ due_date: '' })
      }
    }
  }
  getInvoice() {
    this.constantService.progressLoader.next(true);
    this.invoiceService.getInvoiceDetail(this.editId).pipe(catchError((err) => {
      this.constantService.progressLoader.next(false);
      this.toastr.error(err.error.message);
      return throwError(() => err.error.message)
    })).subscribe((invoice: ResponseMessage) => {
      if (invoice.code == 200) {
        this.constantService.progressLoader.next(false);
        invoice.data.send_option = JSON.stringify(invoice.data.send_option);
        invoice.data.frequency = JSON.stringify(invoice.data.frequency);
        this.createInvoiceForm.patchValue(invoice.data);
        this.paymentObj.creditCardProcessingFee = invoice.data.processing_fee ? invoice.data.processing_fee : 0;
        if (invoice.data?.giftcard) {
          this.paymentObj.giftCardDiscount = invoice.data?.giftcard?.value > 0 ? invoice.data?.giftcard?.value : 0;
          this.paymentMethodForm2.patchValue({ gift_card: invoice.data?.giftcard?.code });
        }
        this.paymentObj.lateFee = invoice.data.late_fee ? invoice.data.late_fee : 0;
        this.createInvoiceForm.patchValue({ discount: invoice.data.discount ? invoice.data.discount : 0 })
        this.paymentObj.shippingFee = invoice.data.shipping_fee ? invoice.data.shipping_fee : 0;

        invoice.data.order_items.forEach((item: any) => {
          item.itemTotal = parseFloat(Math.round(+item.price * item.quantity * 100) / 100 as any).toFixed(2)
        })
        this.orderItems = new MatTableDataSource(invoice.data.order_items);
        setTimeout(() => {
          this.calculateSubTotal()
        }, 500)
      } else {
        this.constantService.progressLoader.next(false);
        this.toastr.error(invoice.message);
      }
    })
  }
  private onYearChange(): void {
    this.paymentMethodForm1.controls['yy'].valueChanges.subscribe((selectedYear: number) => {
      this.updateMonthListBasedOnYear(selectedYear);
    });
  }
  private updateMonthListBasedOnYear(selectedYear: number): void {
    const isCurrentYear = selectedYear === this.currentYear;

    // Update the months list: Disable past months for the current year
    this.monthsList.forEach((month) => {
      month.disable = isCurrentYear && parseInt(month.value, 10) < this.currentMonth;
    });

    // Reset the month if it's no longer valid
    const selectedMonth = parseInt(this.paymentMethodForm1.controls.mm.value, 10);
    if (isCurrentYear && selectedMonth < this.currentMonth) {
      this.paymentMethodForm1.controls['mm'].setValue('');
    }
  }
  // private updateMonthListBasedOnYear(selectedYear: number): void {
  //   this.monthsList.forEach((month) => {
  //     month.disable = selectedYear === this.currentYear && parseInt(month.value) < this.currentMonth;
  //   });
  //   this.paymentMethodForm1.controls['mm'].setValue('');
  // }
  getInvoiceId(id: any) {
    this.constantService.progressLoader.next(true)
    this.invoiceService.getInvoiceId(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    )
      .subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.createInvoiceForm.patchValue({ invoice_id: res.data.code })
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  get order_items() {
    return this.createInvoiceForm.get('order_items') as any
  }
  // Old code
  // calculateSubTotal() {
  //   this.initialSubTotal = 0;

  //   let iniSubtotal: number;
  //   for (let i = 0; i < this.orderItems?.data?.length; i++) { this.initialSubTotal += +this.orderItems?.data[i].itemTotal };
  //   iniSubtotal = this.initialSubTotal;
  //   const calulatedSubtotal = parseFloat(Math.round(+iniSubtotal * 100) / 100 as any).toFixed(2);
  //   this.paymentObj.subTotal = calulatedSubtotal;
  //   let tax = (((parseFloat(Math.round(+iniSubtotal * 100) / 100 as any).toFixed(2) as any) * this.taxPar) / 100 as any).toFixed(2)
  //   this.paymentObj.tax = tax;
  //   this.paymentObj.total = parseFloat(Math.round((((+this.paymentObj.creditCardProcessingFee) + (+this.paymentObj.shippingFee) + (+this.paymentObj.lateFee) + (+this.paymentObj.subTotal)) + (+this.paymentObj.tax) - (+this.paymentObj.giftCardDiscount)) * 100) / 100 as any).toFixed(2)
  //   this.createInvoiceForm.patchValue({ tax: this.paymentObj.tax, discount: this.paymentObj.giftCardDiscount });
  //   this.paymentObj.total = this.paymentObj.total > 0 ? this.paymentObj.total : 0;
  // }
  calculateSubTotal() {
    // Calculate initial subtotal
    let iniSubtotal = this.orderItems?.data?.reduce((acc: number, item: { itemTotal: string | number }) =>
      acc + +item.itemTotal, 0) || 0;

    // Set subtotal with two decimal precision
    this.paymentObj.subTotal = iniSubtotal.toFixed(2);

    // Apply discount if applicable
    const discount = this.paymentObj.giftCardDiscount > 0 ? +this.paymentObj.giftCardDiscount : 0;
    iniSubtotal = parseFloat((iniSubtotal - discount).toFixed(2));

    // Calculate and set tax
    const tax = iniSubtotal > 0 ? parseFloat((iniSubtotal * this.taxPar / 100).toFixed(2)) : 0;
    this.paymentObj.tax = tax.toFixed(2);

    // Calculate and set total amount
    const total = (
      iniSubtotal +
      tax +
      +this.paymentObj.creditCardProcessingFee +
      +this.paymentObj.shippingFee +
      +this.paymentObj.lateFee
    ).toFixed(2);
    this.paymentObj.total = Math.max(0, +total);

    // Update form values
    this.createInvoiceForm.patchValue({
      tax: this.paymentObj.tax,
      discount: this.paymentObj.giftCardDiscount
    });
  }


  removeproduct(j: any) {
    this.orderItems.data = this.orderItems?.data?.filter((res: any, i: any) => { if (i != j) { return res } });
    this.calculateSubTotal();
  }
  redirectToProductAdd() {
    let invoiceData = JSON.parse(JSON.stringify(this.createInvoiceForm.value));
    let dialogRef = this.dialog.open(ProductListingComponent, {
      width: '95vw',
      autoFocus: false,
      panelClass: ['modal-wrapper'],
      data: { invoice: true, orderItems: this.orderItems?.data }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.orderItems = [];
        this.orderItems = new MatTableDataSource(res);
        this.calculateSubTotal();
        this.cdr.detectChanges();
      }
    })
  }
  changePanelState(status: boolean) {
    this.panelOpenState = status;
  }
  applyGiftCard() {
    this.paymentMethodForm2.controls.gift_card.markAsTouched()
    if (this.paymentMethodForm2.controls.gift_card.valid) {
      this.constantService.progressLoader.next(true);
      let giftCardDetail = {
        code: this.paymentMethodForm2.value.gift_card,
        coupon_type: 2,
        user_business_location_id: this.editId ? this.createInvoiceForm.value.user_business_location_id : this.selectedBranchLocationId
      }
      this.invoiceService.applyCoupon(giftCardDetail).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((err) => {
          this.toastr.error(err.error.message);
          // this.paymentMethodForm2.patchValue({ gift_card: '' });
          return throwError(() => err.error.message);
        })).subscribe((coupon: ResponseMessage) => {
          if (coupon.code == 200) {
            this.createInvoiceForm.patchValue({ giftcard_id: coupon.data.id })
            this.paymentObj.giftCardDiscount = coupon.data.value;
            this.calculateSubTotal();
            this.toastr.success(coupon.message);
          } else {
            // this.paymentMethodForm2.patchValue({ gift_card: '' });
            this.toastr.error(coupon.message)
          }
        })
    }
  }
  changeItemQuantity(i: any, action: any) {
    let item = this.orderItems?.data[i];
    const maxQuantity = 99999; // Maximum quantity limit

    if (item.quantity > 0) {
      if (action === 'increase') {

        if ((item.quantity < item.available_stock || item.available_stock == -1) && item.quantity < maxQuantity) {
          item.quantity += 1;
        } else if (item.quantity >= maxQuantity) {
          this.constantService.showError('max_quantity_reach');
        } else {
          this.constantService.showError('invoice_max_stock_reached', 'invoice_max_stock_reached_title');
        }
      } else if (action === 'decrease') {
        if (item.quantity !== 1) {
          item.quantity -= 1;
        }
      }

      item.itemTotal = parseFloat((item.price * item.quantity).toFixed(2));
    }

    this.calculateSubTotal();
  }

  changeSendOption(event: any) {
    if (event.value == '2') {
      this.createInvoiceForm.get('schedule_custom_date')?.addValidators(Validators.required);
    } else {
      this.createInvoiceForm.get('schedule_custom_date')?.removeValidators(Validators.required);
      this.createInvoiceForm.patchValue({ frequency: '1' });
      this.createInvoiceForm.get('recurring_type')?.removeValidators(Validators.required);
      this.createInvoiceForm.get('recurring_type')?.updateValueAndValidity();
    }
    this.createInvoiceForm.get('schedule_custom_date')?.updateValueAndValidity();
  }
  changeFrequency(event: any) {
    if (event.value == '2') {
      this.createInvoiceForm.get('recurring_type')?.addValidators(Validators.required);
    } else {
      this.createInvoiceForm.get('recurring_type')?.removeValidators(Validators.required);
    }
    this.createInvoiceForm.get('recurring_type')?.updateValueAndValidity();
  }
  shareVia(type: any) {
    this.createInvoiceForm.markAllAsTouched();
    // this.paymentMethodForm1.markAllAsTouched();
    // this.paymentMethodForm2.markAllAsTouched();

    if (this.createInvoiceForm.invalid) {
      return;
    }
    // if (this.paymentMethod == '2' && this.paymentMethodForm1.invalid) {
    //   return;
    // }
    // if (this.paymentMethodForm1.invalid && this.paymentMethodForm2.invalid) {
    //   return;
    // }
    let data = {
      title: type == 1 ? 'share_via_email' : 'share_via_text',
      enter_mobile: 'enter_mobile',
      enter_email: 'enter_email',
      share_via: type
    }
    let dialogRef = this.dialog.open(ShareViaEmailMobileComponent, { width: '500px', panelClass: ['modal-wrapper'], data: data });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.createInvoiceForm.patchValue({ share_email_phone_number: res, share_via: type });
        this.sendOrDraftInvoice(1)
      }
    })
  }

  sendOrDraftInvoice(invoice_status: any) {
    this.createInvoiceForm.markAllAsTouched();
    this.createInvoiceForm.patchValue({ sub_total: this.paymentObj.subTotal })
    // this.paymentMethodForm1.markAllAsTouched();
    // this.paymentMethodForm2.markAllAsTouched();
    if (this.createInvoiceForm.invalid) {
      return;
    }
    // if (this.paymentMethodForm1.invalid && this.paymentMethodForm2.invalid) {
    //   return;
    // }
    if (this.orderItems.data.length == 0) {
      this.constantService.showError('invoice_add_one_product');
      return;
    }
    let paymentDetail: any = this.paymentMethodForm1.value;

    let service_date = this.datePipe.transform(new Date(this.createInvoiceForm.value.service_date), 'YYYY-MM-dd');
    let due_date = this.datePipe.transform(new Date(this.createInvoiceForm.value.due_date), 'YYYY-MM-dd');
    this.createInvoiceForm.patchValue({
      discount: 0,
      processing_fee: this.paymentObj.creditCardProcessingFee,
      total_amount: this.paymentObj.total,
      late_fee: this.paymentObj.lateFee,
      service_date: service_date,
      due_date: due_date,
      payment_method: 1
    });
    if (this.createInvoiceForm.value.schedule_custom_date) {
      let custom_date = this.datePipe.transform(new Date(this.createInvoiceForm.value.schedule_custom_date), 'YYYY-MM-dd');
      this.createInvoiceForm.patchValue({ schedule_custom_date: custom_date });
    }
    let invoicePayload: any = JSON.parse(JSON.stringify(this.createInvoiceForm.value));
    invoicePayload.order_items = [];
    this.orderItems.data.forEach((res: any) => {
      invoicePayload.order_items.push(res)
    })

    invoicePayload.order_items = invoicePayload.order_items.map((res: any) => ({
      item_id: res.item_id,
      quantity: res.quantity
    }))
    invoicePayload.order_items = JSON.stringify(invoicePayload.order_items)
    invoicePayload.invoice_status = invoice_status;
    invoicePayload.user_business_location_id = this.editId ? this.createInvoiceForm.value.user_business_location_id : this.selectedBranchLocationId;
    this.constantService.progressLoader.next(true);
    invoicePayload = { ...invoicePayload, ...paymentDetail }

    if (!this.editId) {
      if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
      this.subscribeAPI = this.invoiceService.createInvoice(invoicePayload).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })
      ).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          if (invoice_status == 3) {
            this.invoicePreview(res.data);
          } else {
            this.resetFormsAndNavigate();
          }
        } else {
          this.toastr.error(res.message);
        }
      });
    } else {
      if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
      this.subscribeAPI = this.invoiceService.updateInvoice(invoicePayload).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })
      ).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          if (invoice_status == 3) {
            this.invoicePreview(res.data);
          } else {
            this.resetFormsAndNavigate();
          }
        } else {
          this.toastr.error(res.message);
        }
      });
    }
  }

  invoicePreview(data: any): void {
    const { invoice_image: pdfUrl, invoice_id } = data;
    if (this.platform === 'muybi_app') {
      const fileName = `invoice_${invoice_id}.pdf`;
      const downloadData = { name: fileName, url: pdfUrl };
      this.constantService.redirectToFultterURL('invoicePreview', downloadData);
    } else {
      const dialogRef = this.dialog.open(InvoicePreviewComponent, {
        width: '600px',
        panelClass: ['modal-wrapper'],
        disableClose: true,
        autoFocus: false,
        data: { pdfUrl }
      });

      dialogRef.afterClosed().subscribe((res: any) => {
        if (res?.success) {
          this.resetFormsAndNavigate();
        }
      });
    }
  }

  receiveMessageFromApp(event: MessageEvent) {
    if (event.origin.toString() === window.location.origin.toString()) {
      if (event.data.toString() === 'invoicePreviewClose') {
        this.resetFormsAndNavigate();
      }
    }
  }

  private resetFormsAndNavigate(): void {
    this.createInvoiceForm.reset();
    this.paymentMethodForm1.reset();
    this.paymentMethodForm2.reset();
    this.backToInvoiceList();
    this.createInvoiceForm.patchValue({
      share_email_phone_number: '',
      share_via: ''
    });
  }


  backToInvoiceList() {
    this.route.navigate(['/invoice']);
  }

  ngOnDestroy(): void {
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
  }
}

