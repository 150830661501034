<div class="section-box mb-24 flex flex-wrap  flex-align-center gap-8px header-bx">
  <h1 class="text-white mb-0 mobile-full fs-20 text-truncate">{{'waiting_list' | translate}}</h1>
  <div class="flex gap-16px flex-grow-1 flex-justify-end searchbox-child inputwidth-auto mt-md-15">

    <ng-container *ngIf="waitingPermission?.add == 1">

      <button mat-flat-button class="btn-outline-primary hide-mobile btn-lg"
        (click)="redirectToTable()">{{'show_table' | translate}}
      </button>

      <button mat-flat-button class="flex-shrink-0 btn-lg" (click)="addEditWaitingListDialog('Add')">
        {{'add_waiting' | translate}}
      </button>

    </ng-container>

  </div>
</div>

<div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="waitingListScrollDown()"
  [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
  <table mat-table [dataSource]="dataSourceWaiting" class="mat-elevation-z8">

    <!-- CUSTOMER NAME Column -->
    <ng-container matColumnDef="customer_name">
      <th mat-header-cell *matHeaderCellDef>{{'waiting_list_table_columns.customer_name' | translate | uppercase}}</th>
      <td mat-cell *matCellDef="let element" class="nowrap"> {{element.customer_name}} </td>
    </ng-container>

    <!-- QUEUE NUMBER Column -->
    <ng-container matColumnDef="queue_number">
      <th mat-header-cell *matHeaderCellDef class="text-center">
        {{'waiting_list_table_columns.queue_number' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="nowrap text-center"> {{element.queue_number}} </td>
    </ng-container>

    <!-- NO. OF GUESTS Column -->
    <ng-container matColumnDef="number_of_people">
      <th mat-header-cell *matHeaderCellDef class="text-center">
        {{'waiting_list_table_columns.no_of_guests' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.number_of_people}} </td>
    </ng-container>

    <!-- MOBILE NUMBER Column -->
    <ng-container matColumnDef="phone_number">
      <th mat-header-cell *matHeaderCellDef>{{'waiting_list_table_columns.mobile_number' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="nowrap"> {{element.phone_number}} </td>
    </ng-container>

    <!-- <ng-container matColumnDef="event_name">
      <th mat-header-cell *matHeaderCellDef>{{'waiting_list_table_columns.event' | translate}}</th>
      <td mat-cell *matCellDef="let element">{{element.event_name}} </td>
    </ng-container> -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="action-col">{{'waiting_list_table_columns.action' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex table-actionbtn flex-justify-start">
          <!-- <span class="badge badge-success cursor-pointer" (click)="openDialog()">{{'ready' | translate}}</span> -->
          <button (click)="sendWaitingAlert(element.id)" mat-icon-button aria-label="Message"
            matTooltip="{{ 'message' | translate }}"><span class="icon-message-text"></span></button>
          <!-- <button *ngIf="waitingPermission?.update == 1" (click)="addEditWaitingListDialog('Edit',element.id)"
            mat-icon-button aria-label="Edit" matTooltip="{{ 'edit' | translate }}"><span
              class="icon-edit-pencil"></span></button>
          <button *ngIf="waitingPermission?.delete == 1" (click)="deleteWaitingDialog(element.id)" mat-icon-button
            aria-label="Delete" matTooltip="{{ 'delete' | translate }}"><span class="icon-ic-trash"></span></button>
 -->


            <button mat-icon-button aria-label="Edit" matTooltip="{{ 'edit' | translate }}" *ngIf="waitingPermission?.update == 1" (click)="addEditWaitingListDialog('Edit',element.id)">
              <span class="icon-edit-pencil"> </span>
            </button>
            <button mat-icon-button aria-label="Edit" matTooltip="{{ 'delete' | translate }}"
            *ngIf="waitingPermission?.delete == 1" (click)="deleteWaitingDialog(element.id)" >
              <span class="icon-ic-trash"></span>
            </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <ng-container *ngIf="totalRecords == 0 && !waitingProgressLoader">
    <app-no-records-available></app-no-records-available>
  </ng-container>

</div>