import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(private httpClient: HttpClient) { }

  getReservationList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    params = params.append('sort', data.sortBy);
    params = params.append('sort_field', data.sortField);
    data.business_location_id ? (params = params.append('business_location_id', data.business_location_id)) : '';
    data.search_text ? (params = params.append('search_text', data.search_text)) : '';
    data.select_table ? (params = params.append('select_table', data.select_table)) : '';
    return this.httpClient.get<ResponseMessage>('get-reservation-list', { params });
  }

  addReservation(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('add-reservation', data);
  }

  getReservationDetail(id: any): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`get-reservation-details/${id}`);
  }

  updateReservation(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('update-reservation', data);
  }

  deleteReservation(id: any): Observable<ResponseMessage> {
    return this.httpClient.delete<ResponseMessage>(`delete-reservation/${id}`)
  }

  // Get Table List
  getTableList(data?: any): Observable<ResponseMessage> {
    const body = {
      date: data?.select_date,
      start_time: data?.select_time,
      business_location_id: data?.business_location_id
    };

    return this.httpClient.post<ResponseMessage>('check-table-availablity', body);
  }

  getAllTableList(business_location_id: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('business_location_id', business_location_id)
    return this.httpClient.get<ResponseMessage>('get-table-list', { params });
  }

}
