import { Directive, HostListener, ElementRef, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimSpaces]',
  standalone:true
})
export class TrimSpacesDirective {

  constructor(
    private el: ElementRef,
    @Optional() private ngControl: NgControl
  ) {}

  @HostListener('blur') onBlur() {
    const value = this.el.nativeElement.value;

    // If the value is only spaces, set it to an empty string
    const sanitizedValue = value.trim() === '' ? '' : value;

    // Update the UI value
    this.el.nativeElement.value = sanitizedValue;

    // Update the form control value if available
    if (this.ngControl?.control) {
      this.ngControl.control.setValue(sanitizedValue);
    }
  }
}
