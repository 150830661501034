import { Component, inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CheckoutService } from '../../service/checkout.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { AddTipComponent } from '../add-tip/add-tip.component';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ResponseMessage } from '../../../../../model/interfaces/req.res.interface';
import { ConstantService } from '../../../../../shared/services/constant.service';
import { EncryptionService } from '../../../../../shared/services/encryption.service';
import { TrimValueDirective } from '../../../../../shared/directives/trim-value.directive';
import { FlotNumberDirective } from '../../../../../shared/directives/flot-number.directive';
import { SendPaymentLinkComponent } from '../../../../billing/components/send-payment-link/send-payment-link.component';
import { numberValidator } from '../../../../../shared/validators/number-validator.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { UppercaseAlphaNumericDirective } from '../../../../../shared/directives/uppercase-alpha-numeric.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../../../auth/auth.service';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

@Component({
  selector: 'app-create-order-retail',
  standalone: true,
  imports: [
    MatFormFieldModule,
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    TrimValueDirective,
    FlotNumberDirective,
    TranslateModule,
    UppercaseAlphaNumericDirective,
    NgxMatIntlTelInputComponent
  ],
  templateUrl: './create-order-retail.component.html',
  styleUrls: ['./create-order-retail.component.scss'],
  providers: [EncryptionService]
})
export class CreateOrderRetailComponent implements OnInit {
  isOpenOrder: boolean = false;
  @Input() orderItemsData: any;
  taxPer = 0;
  appliedCoupon: any;
  couponDetail: any;
  orderFormGroup!: FormGroup;
  subscribeCoupon!: Subscription;
  subscribeOrder!: Subscription;
  tempCouponDetail = 'coupon_detail';
  tempOrderWhenBack = 'tempOrderWhenBack';
  tempOrderItemData = 'tempOrderItemData';
  tempConfirmOrderDetail = 'tempConfirmOrderDetail';
  orderCreated: any;
  public checkoutService = inject(CheckoutService);
  public constantService = inject(ConstantService);
  private dialog = inject(MatDialog);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private encDecObj = inject(EncryptionService);
  private activateRoute = inject(ActivatedRoute);
  private authService = inject(AuthService);
  preferredCountries: any = [];
  selectedBranchLocation = 'select_business_location';
  orderFormData = 'order_form_data';
  constructor() {
    this.isOpenOrder = false;
    this.checkoutService.isDisplayOrder.subscribe(res => {
      if (res.success && (res.action == 'New' || res.action == 'Existing')) {
        this.isOpenOrder = true;
        this.createOrderAddClass();
        setTimeout(() => { this.calculateOrderPrice(); },);
      }
    })
    this.orderCreated = this.activateRoute.snapshot.queryParams['orderCreated'];
    if (this.orderCreated == 'back') {
      this.isOpenOrder = true;
      this.createOrderAddClass();
    } else if (this.orderCreated == 'paid') {
      this.isOpenOrder = false;
      localStorage.removeItem(this.orderFormData);
      localStorage.removeItem(this.tempOrderWhenBack);
      localStorage.removeItem(this.tempCouponDetail);
      this.createOrderRemoveClass();
    }

    this.constantService.replaceStateUrl();
  }

  // getPrefferedCountry() {
  //   this.authService.getCountryName().subscribe((response) => {
  //     if (response) {
  //       this.preferredCountries = [response.country.toLowerCase()];
  //     }
  //   });
  // }
  
  initialOrderForm() {
    this.orderFormGroup = this.fb.group({
      order_id: [''],
      firstName: [''],
      lastName: ['',],
      phoneNumber: ['', [numberValidator()]],
      discountCode: [''],
      additionalAmount: [''],
      subTotal: [''],
      discount: [''],
      tax: [''],
      tip: [''],
      branchLocation: [''],
      grandTotal: [''],
    })

    if (this.orderCreated === 'back') {

      const encryptedOrderData = localStorage.getItem(this.orderFormData);
      const encryptedCouponData = localStorage.getItem(this.tempCouponDetail);
      const encryptedOrderItems = localStorage.getItem(this.tempOrderWhenBack);

      if (encryptedOrderData) {
        const orderFormData = JSON.parse(this.encDecObj.getDecrypt(encryptedOrderData));
        this.orderFormGroup.patchValue(orderFormData);
      }

      this.couponDetail = encryptedCouponData ? JSON.parse(this.encDecObj.getDecrypt(encryptedCouponData)) : '';
      if (encryptedOrderItems) {
        this.orderItemsData = JSON.parse(this.encDecObj.getDecrypt(encryptedOrderItems));
      }

      this.calculateOrderPrice();
    }
  }
  resetForm() {
    this.orderFormGroup.reset();
    this.orderFormGroup.updateValueAndValidity();
  }
  ngOnInit() {
    const configurationDetail = this.constantService.getConfigurationDetail();
    this.taxPer = configurationDetail ? configurationDetail.tax : 0;

    this.initialOrderForm();
    // this.getPrefferedCountry();
    this.preferredCountries = this.constantService.getPrefferedCountry();
    this.orderFormGroup.controls['additionalAmount'].valueChanges.subscribe((res: any) => {
      this.calculateOrderPrice();
    })
  }

  getBusinessLocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.orderFormGroup.controls['branchLocation'].setValue(Number(id))
    }
  }

  addNewProduct() {
    localStorage.setItem(this.tempOrderItemData, JSON.stringify(this.orderItemsData));
    this.discardOrder('AddNewItem')
  }

  discardOrder(action: string) {
    const obj = {
      success: true,
      action: action
    };
    this.isOpenOrder = false;
    if (action === 'Discard') {
      localStorage.removeItem('tempOrderItemData');
      localStorage.removeItem(this.orderFormData);
      localStorage.removeItem(this.tempOrderWhenBack)
      this.orderItemsData = [];
      this.appliedCoupon = null;
      this.orderFormGroup.controls['discountCode'].enable();
      this.couponDetail = null;
      this.resetForm();
      this.createOrderRemoveClass();
    }
    this.checkoutService.isDisplayOrder.next(obj);
  }

  createOrderRemoveClass() {
    const className = 'open_checkout';
    if (document.body.classList.contains(className)) {
      document.body.classList.remove(className);
    }
  }

  createOrderAddClass() {
    const deviceType = this.constantService.getDeviceType();
    const className = 'open_checkout';

    if (deviceType === 1) {
      document.body.classList.add(className);
    } else {
      this.createOrderRemoveClass();
    }
  }

  addTip(action: string) {
    let data = {
      action: action,
      amount: this.orderFormGroup.value.tip
    };

    const dialogRef = this.dialog.open(AddTipComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.orderFormGroup.controls['tip'].setValue(res.tipAmount);
        this.calculateOrderPrice();
      }
    })
  }

  applyCouponCode() {
    let formData = new FormData();
    this.getBusinessLocation();
    formData.append('code', this.orderFormGroup.value.discountCode);
    formData.append('user_business_location_id', this.orderFormGroup.value.branchLocation);
    formData.append('coupon_type', '1');
    if (this.subscribeCoupon) this.subscribeCoupon.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeCoupon = this.checkoutService.applyCoupon(formData).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.appliedCoupon = this.orderFormGroup.value.discountCode;
          this.orderFormGroup.controls['discountCode'].disable();
          this.couponDetail = res.data;
          localStorage.setItem(this.tempCouponDetail, this.encDecObj.setEncrypt(JSON.stringify(this.couponDetail)))
          this.calculateOrderPrice();
          this.toastr.success(res.message);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  removeCouponCode(): void {
    this.appliedCoupon = null;
    this.orderFormGroup.controls['discountCode'].enable();
    this.couponDetail = null;
    this.orderFormGroup.controls['discount'].setValue('');
    this.calculateOrderPrice();
    this.orderFormGroup.controls['discountCode'].setValue('');
  }

  itemQuantity(index: number, action: any) {
    const item = this.orderItemsData[index];
    const maxQuantity = 99999; // Maximum quantity limit
  
    if (action == 'increase') {
      if ((item.quantity < item.available_stock || item.available_stock == -1) && item.quantity < maxQuantity) {
        item.quantity += 1;
      } else {
        if (item.quantity >= maxQuantity) {
          this.constantService.showError('max_quantity_reach');
        } else {
          this.constantService.showError('max_stock_reach');
        }
      }
    } else if (action == 'decrease') {
      item.quantity -= 1;
      if (item.quantity <= 0) {
        this.orderItemsData.splice(index, 1);
        if (this.orderItemsData.length === 0) {
          this.discardOrder('Discard');
        }
      }
    }
  
    this.orderItemsData = [...this.orderItemsData];
    if (this.orderItemsData.length > 0) {
      localStorage.setItem(this.tempOrderItemData, JSON.stringify(this.orderItemsData));
    }
    this.calculateOrderPrice();
  }
  


  calculateOrderPrice() {
    // Calculate Product Total
    const productTotal = this.orderItemsData.reduce((acc: number, product: { price: number; quantity: number; }) => {
      return acc + (product.price * product.quantity);
    }, 0);
    this.orderFormGroup.controls['subTotal'].setValue(productTotal.toFixed(2));

    // Calculate Coupon Discount // 1 = Fix amount 2 = Percentage

    let discount = 0;
    if (this.appliedCoupon) {
      discount = this.couponDetail.type === 1
        ? this.couponDetail.value
        : (productTotal * this.couponDetail.value) / 100;
      discount = parseFloat(discount.toFixed(2));
      this.orderFormGroup.controls['discount'].setValue(discount);
    }

    // Calculate Tax
    const discountedTotal = productTotal - discount;
    const tax = discountedTotal > 0 ? (discountedTotal * this.taxPer) / 100 : 0;
    this.orderFormGroup.controls['tax'].setValue(tax.toFixed(2));

    // Calculate Grand Total
    const tip = Number(this.orderFormGroup.value.tip) || 0;
    const additionalAmount = Number(this.orderFormGroup.value.additionalAmount) || 0;
    const taxAmount = Number(this.orderFormGroup.value.tax) || 0;
    const grandTotal = Math.max(discountedTotal + taxAmount + tip + additionalAmount, 0);
    this.orderFormGroup.controls['grandTotal'].setValue(grandTotal.toFixed(2));
  }



  // 1 = Send payment link 2 = Process to Pay
  payment(type: number, order: any) {

    let data = {
      orderDetail: order
    };
    if (type == 1) {
      const dialogRef = this.dialog.open(SendPaymentLinkComponent, {
        width: '500px',
        panelClass: ['modal-wrapper'],
        disableClose: true,
        autoFocus: false,
        data
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res?.success) {
          this.discardOrder('Discard');
        }
      })
    } else if (type == 2) {
      this.orderFormGroup.patchValue({ order_id: order?.order_id })
      localStorage.setItem(this.tempOrderWhenBack, this.encDecObj.setEncrypt(JSON.stringify(this.orderItemsData)));
      localStorage.setItem(this.orderFormData, this.encDecObj.setEncrypt(JSON.stringify(this.orderFormGroup.value)));
      localStorage.setItem(this.tempConfirmOrderDetail, this.encDecObj.setEncrypt(JSON.stringify(order)));
      this.router.navigate(['/checkout/payment'], { queryParams: { orderCreated: 'Yes' } });
      this.createOrderRemoveClass();
    }
  }

  // 1 = Send payment link, 2 = Process to Pay, 3= Generate Order
  createOrder(type: number) {
    this.orderFormGroup.markAllAsTouched();
    if (this.orderFormGroup.invalid) return;
    this.getBusinessLocation();
    let obj = {
      order_items: JSON.stringify(this.orderItemsData),
      user_business_location_id: this.orderFormGroup.value.branchLocation,
      customer_first_name: this.orderFormGroup.value.firstName,
      customer_last_name: this.orderFormGroup.value.lastName,
      customer_phone: this.orderFormGroup.value.phoneNumber,
      sub_total: +this.orderFormGroup.value.subTotal,
      coupon_id: this.couponDetail ? this.couponDetail.id : '',
      discount: +this.orderFormGroup.value.discount,
      tax: +this.orderFormGroup.value.tax,
      tip: +this.orderFormGroup.value.tip,
      additional_amount: +this.orderFormGroup.value.additionalAmount,
      total_amount: +this.orderFormGroup.value.grandTotal,
      payment_type: null,
      order_id: this.orderFormGroup.value.order_id ? this.orderFormGroup.value.order_id : ''
    };
    if (this.subscribeOrder) this.subscribeOrder.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeOrder = this.checkoutService.createOrder(obj).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          if (type == 1 || type == 2) {
            // res.data['sub_total'] = obj.sub_total;
            this.payment(type, res.data);
          } else {
            this.constantService.showSuccess('order_placed');
            this.discardOrder('Discard');
          }
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  ngOnDestroy(): void {
    if (this.subscribeCoupon) this.subscribeCoupon.unsubscribe();
    if (this.subscribeOrder) this.subscribeOrder.unsubscribe();
  }
}
