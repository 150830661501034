<mat-dialog-content class="text-center" [ngClass]="{'pointer-none': tableProgressLoader}">
  <div class="mb-24 radius-12">
    <img src="assets/images/table-ic.png" loading="lazy" [alt]="('table' | translate)" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">
      {{ (localData.action | lowercase | translate) + ' ' + ('table' | translate) }}
    </h3>
  </div>
  <form [formGroup]="tableForm" (ngSubmit)="addUpdateTable()">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
      <mat-label>{{'table_name' | translate}}</mat-label>
      <input matInput formControlName="name" appTrimSpaces />
    </mat-form-field>
  </form>
  <div class="btn-box text-center">
    <button mat-flat-button (click)="cancel(false)" class="btn-outline-primary">
      {{'cancel' | translate}}
    </button>
    <button mat-flat-button type="submit" (click)="addUpdateTable()">
      {{action == 'Add' ? ('add_now'| translate):('update' | translate)}}
    </button>
  </div>
</mat-dialog-content>