<!-- Invoice Header Boxes -->
<div class="section-box mb-24 flex flex-justify-between flex-align-center gap-16px flex-wrap">
  <!-- flex flex-wrap  -->
  <div class="order-infowrapper flex-grow-1 flex-align-start">
    <div class="order-infobox radius-12 innergap-10px">
      <span class="order-infoicon ">
        <img src="assets/images/icons/icon-money.svg" alt="Paid" />
      </span>
      <div class="fs-14 text-grey order-details">
        <h3 class="order-count text-white fw-bold">${{last30DayAmount}}</h3>
        <span>{{'paid_last_30_days' | translate}}</span>
      </div>
    </div>
    <div class="order-infobox radius-12 innergap-10px">
      <span class="order-infoicon">
        <img src="assets/images/icons/document.svg" alt="Draft" />
      </span>
      <div class="fs-14 text-grey order-details">
        <h3 class="order-count text-white fw-bold">${{draftAmount}}</h3>
        <span>{{'drafts' | translate}}</span>
      </div>
    </div>
  </div>
  <button *ngIf="invoicePermission?.add == 1" (click)="createEditInvoice('Create')" mat-flat-button
    class="btn-lg create-invoicebtn">
    {{'create_new_invoice' | translate}}
  </button>
</div>

<!-- Starts Filter Section -->
<div class="filter-boxwrapper section-box flex flex-justify-end flex-wrap mb-20 gap-20px">

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-date date-field">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="fromDate" [max]="currentDate" placeholder="{{'from' | translate}}"
        [(ngModel)]="selectFromDate" (dateChange)="selectSearch(1, selectFromDate)">
      <mat-datepicker-toggle matIconSuffix [for]="fromDate" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #fromDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-date date-field">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="toDate" [max]="currentDate" [min]="selectFromDate"
        placeholder="{{'to' | translate}}" (click)="toDate.open()" [disabled]="!selectFromDate"
        [(ngModel)]="selectToDate" (dateChange)="selectSearch(2, selectToDate)" readonly>
      <mat-datepicker-toggle matIconSuffix [for]="toDate" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #toDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-day">
    <mat-select placeholder="{{'product' | translate}}" [(ngModel)]="selectProduct">
      <mat-option value="" (click)="selectSearch(3, 0)">{{'product' | translate}} </mat-option>
      <mat-option *ngFor="let product of productList;" [value]="product.id"
        (click)="selectSearch(3, product.id)">{{product.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-paymenttype">
    <mat-select placeholder="{{'vendor' | translate}}" [(ngModel)]="selectVendorId">
      <mat-option value="" (click)="selectSearch(4, 0)">{{'vendor' | translate}}</mat-option>
      <mat-option *ngFor="let vendor of vendorList;" (click)="selectSearch(4, vendor.user_id)"
        [value]="vendor.user_id">{{vendor.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-paymenttype">
    <mat-select placeholder="{{'branch_location' | translate}}" [(ngModel)]="selectBranch">
      <mat-option value="" (click)="selectSearch(5, 0)">{{'branch_location' | translate}}</mat-option>
      <mat-option *ngFor="let branch of branchList" [value]="branch.business_location_id"
        (click)="selectSearch(5, branch.business_location_id)">{{branch.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-paymenttype">
    <mat-select placeholder="{{'sort_by' | translate}}" [(ngModel)]="selectSortBy">
      <mat-option value="" (click)="selectSearch(6, 0)">{{'sort_by' | translate}}</mat-option>
      <mat-option *ngFor="let option of sortOptions" [value]="option.value" (click)="selectSearch(6, option.value)">
        {{ option.transalteLabel | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button class="btn-outline-primary mobile-filterbtn" (click)="resetFilter()"
    [disabled]="isResetButtonDisabled">
    {{'reset' | translate}}
  </button>

</div>
<!-- Ends Filter Section -->

<div class="table-sectionbox">
  <!-- Header , Search and Filter -->
  <div
    class="heading-box flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx tablet-fullgrid">
    <h1 class="mb-0 mobile-full h3">{{'invoices' | translate}}</h1>
    <div class="flex gap-28px flex-grow-1 flex-justify-end flex-align-center filterbox-wrap">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
        <div class="w-full relative input-rightspace">
          <input type="search" class="form-control" (keyup)="searchInvoices()" matInput
            placeholder="{{'search_invoice' | translate}}" [(ngModel)]="searchInvoice" />
          <mat-icon class="ic-input cursor-pointer">search</mat-icon>
        </div>
      </mat-form-field>
    </div>
  </div>

  <!-- Starts Table -->
  <div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="InvoiceListScrollDown()"
    [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
    <table mat-table [dataSource]="dataSourceInvoice" class="mat-elevation-z8">

      <!-- DATE Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="item-namecol">{{'date' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          <div [innerHTML]="element.created_at | date:'dd/MM/YYYY'" class="item-infobox"></div>
        </td>
      </ng-container>

      <!-- CUSTOMER NAME Column -->
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>{{'customer_name' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.customer_name}} </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#{{'category_columns.category_id' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.invoice_id}} </td>
      </ng-container>

      <!-- TITLE Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>{{'title' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          <div class="text-truncate title-text">{{element.invoice_title}}</div>
        </td>
      </ng-container>

      <!-- STATUS Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{'status' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <span class="badge badge-light-success badge-small"
            [innerHTML]="element.invoice_status==1?('send' | translate):('draft' | translate)"></span>
        </td>
      </ng-container>

      <!-- AMOUNT Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{'invoice_amount' | translate}}</th>
        <td mat-cell *matCellDef="let element">${{element.total_amount}} </td>
      </ng-container>

      <!-- CUSTOMIZE INVOICE Column -->
      <!-- <ng-container matColumnDef="customerInvoice">
        <th mat-header-cell *matHeaderCellDef>CUSTOMIZE INVOICE</th>
        <td mat-cell *matCellDef="let element">-</td>
      </ng-container> -->

      <!-- ACTION Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="action-col">{{'action' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex table-actionbtn flex-justify-center">
            <div class="flex table-actionbtn">
              <button mat-icon-button aria-label="Download PDF" matTooltip="{{'download_pdf' | translate}}"
                (click)="downloadInvoicePdf(element)">
                <img src="assets/images/icons/download_pdf.svg" alt="{{'download_pdf' | translate}}" />
              </button>
              <button *ngIf="element.invoice_status != 1 && invoicePermission?.update == 1" mat-icon-button
                aria-label="Edit" matTooltip="{{ 'edit' | translate }}" (click)="createEditInvoice('Edit',element.id)">
                <span class="icon-development"> </span>
              </button>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- <div *ngIf="totalRecord == 0 && !invoiceProgressLoader"
      class="no-records flex flex-align-center flex-justify-center flex-column">
      <img src="assets/images/empty-state.png" alt="No Record Found" class="mb-24" />
      <div class="text-primary fw-medium">{{'no_records' | translate}}</div>
    </div> -->

    <ng-container *ngIf="totalRecord == 0 && !invoiceProgressLoader">
      <app-no-records-available></app-no-records-available>
    </ng-container>
  </div>
</div>