<div class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px header-box">
  <h1 class="text-white mb-0 mobile-full text-truncate">{{'reservation_management' | translate}}</h1>
  <div class="grid flex-wrap filter-boxwrapper flex-justify-end mt-md-15">
    <mat-form-field subscriptSizing="dynamic" appearance="fill" class="w">

      <mat-select class="min-180" [(ngModel)]="selectTable" placeholder="{{ 'select_table' | translate }}">
        <mat-option value="" (click)="selectSearch(1, 0)">
          {{ "select_table" | translate }}
        </mat-option>
        <mat-option *ngFor="let table of tableListData;" (click)="selectSearch(1, table.id)" [value]="table.id">
          {{ table.name }}
        </mat-option>
      </mat-select>

    </mat-form-field>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="">
      <div class="w-full relative input-rightspace">
        <input type="search" class="form-control" matInput (keyup)="searchCustomers()" [(ngModel)]="serachCustomer"
          placeholder="{{'search_by_customer_name' | translate}}" />
        <mat-icon class="ic-input cursor-pointer">search</mat-icon>
      </div>
    </mat-form-field>

    <button mat-flat-button class="btn-outline-primary btn-lg btn-half" [disabled]="isResetButtonDisabled"
      (click)="resetFilter()"> {{ "reset" | translate }}</button>
    <a mat-flat-button *ngIf="reservationPermission?.add == 1" class=" btn-lg btn-lg btn-half"
      (click)="addEditReservation('Add')">
      {{'add_reservation' | translate}}
    </a>
  </div>
</div>

<div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="reservationListScrollDown()"
  [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">

  <table mat-table [dataSource]="dataSourceReservation" class="mat-elevation-z8"
    (matSortChange)="reservationSort($event)" [matSortActive]="reservationPayload.sortField"
    [matSortDirection]="reservationPayload.sortBy" matSort [matSortDisabled]="totalRecords === 0">

    <!-- Position Column -->
    <ng-container matColumnDef="customer_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{'reservation_columns.reservation_customer_name' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="nowrap"> {{element.customer_name}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="phone_number">
      <th mat-header-cell *matHeaderCellDef>{{'reservation_columns.mobile_number' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="nowrap"> {{element.phone_number}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="number_of_guest">
      <th mat-header-cell *matHeaderCellDef class="text-center">{{'reservation_columns.no_of_guests' | translate}}</th>
      <td mat-cell *matCellDef="let element" class="text-center"> {{element.number_of_guest}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="reservation_date_time">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'reservation_columns.reservation_date_time' | translate}}
      </th>
      <td mat-cell *matCellDef="let element" class="nowrap"> {{element.reservation_date_time}} </td>
    </ng-container>

    <ng-container matColumnDef="reserved_table">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{'reservation_columns.reservation_reserved_table' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        {{element.reserved_table}}
      </td>
    </ng-container>

    <ng-container matColumnDef="event_name">
      <th mat-header-cell *matHeaderCellDef>{{'reservation_columns.reservation_event' | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element.event_name || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="action-col">{{'reservation_columns.action' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex table-actionbtn">
          <button *ngIf="reservationPermission?.update == 1" mat-icon-button aria-label="Edit"
            matTooltip="{{'edit' | translate}}" (click)="addEditReservation('Edit',element.id)">
            <span class="icon-edit-pencil"></span>
          </button>
          <button *ngIf="reservationPermission?.delete == 1" (click)="deleteReservationDialog(element.id)"
            mat-icon-button aria-label="Delete" matTooltip="{{'delete' | translate}}">
            <span class="icon-ic-trash"></span>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <ng-container *ngIf="totalRecords == 0 && !reservationProgressLoader">
    <app-no-records-available></app-no-records-available>
  </ng-container>

</div>