import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { HeaderComponent } from '../../shared/components/common/header/header.component';
import { SidebarComponent } from '../../shared/components/common/sidebar/sidebar.component';
import { EncryptionService } from '../../shared/services/encryption.service';
import { CommonModule, CurrencyPipe, TitleCasePipe } from '@angular/common';
import { DashboardService } from './services/dashboard.service';
import { Chart, ChartConfiguration, ChartItem, ChartType, registerables } from 'chart.js';
Chart.register(...registerables);
import { BehaviorSubject, subscribeOn, switchMap, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { ConstantService } from '../../shared/services/constant.service';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    CurrencyPipe,
    // HeaderComponent,
    MatButtonModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    RouterModule,
    // SidebarComponent,
    TitleCasePipe,
    TranslateModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [EncryptionService],
})
export class DashboardComponent implements OnInit {
  private encDecObj = inject(EncryptionService);
  private dashboardService = inject(DashboardService);
  private constantService = inject(ConstantService);
  private router = inject(Router);
  public userDetail: any = {};
  public businessType: any = 1;
  public graphData: any = {};
  public months: any = this.constantService.getPrevious12Months();
  public selectedMonth: any = '';
  public selectedPrevMonth: any = '';
  public greeting: any = '';
  public profileCompletePer: number = 0;

  private labels: string[] = this.constantService.getChartMonthLabels();
  private _data$ = new BehaviorSubject<void>(undefined);
  data$ = this._data$.asObservable().pipe(
    switchMap(() =>
      this.dashboardService.getHomeDetails(
        this.selectedMonth,
        this.selectedPrevMonth
      )
    ),
    tap((res) => {
      // Refresh graph data - Destroy current graph if exist
      if (this.graphData.grossSalesCanvas) {
        this.graphData.grossSalesCanvas.destroy();
        this.graphData.returnsCanvas.destroy();
        this.graphData.transactionCanvas.destroy();
        this.graphData.netSalesCanvas.destroy();
      }

      this.graphData = res.data;
      this.profileCompletePer = this.graphData.profile_complete_progress;
      this.chartReady();
    })
  );

  ngOnInit(): void {
    this.constantService.updateName.subscribe(res => {
      if (res) this.userDetail = this.constantService.getUserDetail();
    });
    this.constantService.langChangeSub.subscribe(res => {
      if (res) {
        this.months = this.constantService.getPrevious12Months();
        this.labels = this.constantService.getChartMonthLabels();
        this.updateChartLabels();
      }
    });
    this.constantService.progressLoader.next(true);
    this.userDetail = this.constantService.getUserDetail();
    this.businessType = this.userDetail.business_type;
    this.getGreeting();
    this.refreshHomeData();
  }


  refreshHomeData() {
    this._data$.next();
  }

  changeSelectedMonth(val: any) {
    this.selectedMonth = val;
    if (val === '') {
      this.selectedPrevMonth = val;
    }
    this.refreshHomeData();
  }

  changeSelectedPrevMonth(val: any) {
    this.selectedPrevMonth = val;
    this.refreshHomeData();
  }

  chartReady() {
    let chartModule: { data: { label: string; data: Array<string>; }[]; canvasId: ChartItem; }[] | ({ canvasId: string; name: string; data: any; } | { canvasId: string; data: any; name?: undefined; })[] = [];
    // 1=>Retail,2=>Restaurant
    if (this.businessType == 1) {
      chartModule = [
        {
          name: 'Gross Sales',
          canvasId: 'grossSalesCanvas',
          data: this.graphData.gross_sales_module.content,
        },
        {
          name: 'Returns',
          canvasId: 'returnsCanvas',
          data: this.graphData.returns_module.content,
        },
        {
          name: 'Transection',
          canvasId: 'transactionCanvas',
          data: this.graphData.transaction_module.content,
        },
        {
          name: 'Average Net Sales',
          canvasId: 'netSalesCanvas',
          data: this.graphData.average_net_sales_module.content,
        },
      ];
    } else if (this.businessType == 2) {
      chartModule = [
        {
          name: 'Revenue',
          canvasId: 'grossSalesCanvas',
          data: this.graphData.revenue_module.content,
        },
        {
          name: 'Net profit',
          canvasId: 'returnsCanvas',
          data: this.graphData.net_profit_module.content,
        },
        {
          name: 'Transection',
          canvasId: 'transactionCanvas',
          data: this.graphData.transaction_module.content,
        },
        {
          name: 'Average Net Sales',
          canvasId: 'netSalesCanvas',
          data: this.graphData.average_net_sales_module.content,
        },
      ];
    }

    chartModule.forEach((module: { data: { label: string; data: Array<string>; }[]; canvasId: ChartItem; }) => {
      const config = this.prepareChartDataset(this.labels, module.data);
      this.graphData[`${module.canvasId}`] = new Chart(module.canvasId, config);
    });
    this.constantService.progressLoader.next(false);
  }

  updateChartLabels(): void {
    const chartKeys = [
      'grossSalesCanvas',
      'returnsCanvas',
      'transactionCanvas',
      'netSalesCanvas',
    ];
    chartKeys.forEach((chartKey) => {
      if (this.graphData[chartKey]) {
        this.graphData[chartKey].data.labels = this.labels;
        this.graphData[chartKey].update();
      }
    });
  }

  prepareChartDataset(
    labels: Array<string>,
    value: Array<{ label: string; data: Array<string> }>
  ): ChartConfiguration {
    const dataset = value.map((info, key) => {
      const backgroundColor = key % 2 === 0 ? '#52ce5e' : '#0787c4';
      return {
        ...info,
        backgroundColor,
        borderWidth: 1,
        borderRadius: 10,
      };
    });

    return this.getChartConfiguration(labels, dataset);
  }

  getChartConfiguration(
    labels: Array<string>,
    datasets: any
  ): ChartConfiguration {
    return {
      type: 'bar' as ChartType,
      data: { labels, datasets },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: { grid: { display: false }, beginAtZero: true },
          y: { grid: { display: false }, beginAtZero: true },
        },
      },
    };
  }
  getGreeting() {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      this.greeting = 'good_morning';
    } else if (currentHour < 18) {
      this.greeting = 'good_afternoon';
    } else {
      this.greeting = 'good_evening';
    }
  }

  redirectToModule() {
    let res = {
      success: true,
      title: 'settings'
    }
    this.constantService.selectedSidebarMenu.next(res);
    this.router.navigate(['/settings'])
  }

}
