<div class="authentication-page flex flex-align-center flex-justify-center" [ngClass]="{'pointer-none': loader}">
  <!-- Starts Signup Step 1 -->
  <button mat-flat-button class="btn-dark lang-btn" (click)="changeLanguage()">
    {{ language | languageName }}
</button>
  <div class="authentication-box" *ngIf="currentStep == 1">
    <form [formGroup]="signupForm" appPasswordMatchAndValidator>
      <div class="auth-headerwrapper flex">
        <div class="authlogo-box">
          <div class="auth-box flex flex-align-center flex-justify-center">
            <img src="./assets/images/logo-small.svg" alt="MuyBi" />
          </div>
        </div>
        <div class="auth-header">
          <h1>{{ "sign_step_one.title" | translate }}</h1>
          <p class="text-grey">{{ "sign_step_one.sub_title" | translate }}</p>
        </div>
      </div>
      <div class="auth-innerbox">
        <div class="mb-20">
          <label id="example-radio-group-label" class="text-grey-medium block mb-10">
            {{ "sign_step_one.sign_up_via" | translate }}
          </label>
          <mat-radio-group>
            <mat-radio-button [value]="true" [checked]="isEmail" (change)="onInputChange(true)">
              {{ "email" | translate }}
            </mat-radio-button>
            <mat-radio-button [value]="false" [checked]="!isEmail" (change)="onInputChange(false)">
              {{ "phone" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <mat-form-field appearance="outline" subscriptSizing="dynamic">

          <mat-label *ngIf="isEmail">{{ "sign_step_one.enter_your_email" | translate }}</mat-label>

          <mat-label *ngIf="!isEmail"> {{ "sign_step_one.enter_your_phone" | translate }}* </mat-label>

          <div class="flex w-full inputwith-btn flex-align-end mb-16">

            <ng-container *ngIf="isEmail">
              <input matInput formControlName="userName" (input)="resetUserEmailPhone()" />
            </ng-container>

            <ng-container *ngIf="!isEmail">
              <ngx-mat-intl-tel-input formControlName="phone" class="w-full" [preferredCountries]="preferredCountries"
                [enablePlaceholder]="true" [enableSearch]="true" name="userNamePhone" #userNamePhone
                (input)="resetUserEmailPhone()">
              </ngx-mat-intl-tel-input>
            </ng-container>

            <a mat-stroked-button (click)="sendOTP()" [disabled]="sendOTPDisabled" class="flex-shrink-0 sendotp-btn">
              {{ "send_otp" | translate }}
            </a>

          </div>
        </mat-form-field>

        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
          <mat-label>
            {{ "sign_step_one.enter_vendor_name" | translate }}
          </mat-label>
          <input matInput matNativeControl formControlName="vendorName" />
        </mat-form-field>
        <div class="grid-col2">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16 max-h-70">
            <mat-label>{{
              "sign_step_one.create_password" | translate
              }}</mat-label>
            <div class="password-field w-full" [class.active]="hidePass">
              <input matInput matNativeControl [type]="hidePass ? 'text' : 'password'" formControlName="password"
                required />
              <span class="eye-icon" (click)="hidePass = !hidePass"></span>
            </div>
            <!-- Error messages for password field -->
            <!-- <mat-error *ngIf="resetPasswordForm.get('password')?.hasError('required')">
                    {{ 'Password is required' | translate }}
                  </mat-error> -->
            <mat-error *ngIf="signupForm.get('password')?.hasError('minLength')">
              {{ 'password_atleast_6_char_long' | translate }}
            </mat-error>
            <mat-error
              *ngIf="signupForm.get('password')?.hasError('passwordComplexity') && !signupForm.get('password')?.hasError('minLength')">
              {{ 'string_password_warn' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16 max-h-70">
            <mat-label>{{
              "sign_step_one.confirm_password" | translate
              }}</mat-label>
            <div class="password-field w-full" [class.active]="hideCPass">
              <input matInput matNativeControl [type]="hideCPass ? 'text' : 'password'"
                formControlName="confirmPassword" required />
              <span class="eye-icon" (click)="hideCPass = !hideCPass"></span>
            </div>
            <!-- Error messages for confirm password field -->
            <mat-error *ngIf="signupForm.get('confirmPassword')?.hasError('passwordMismatch')">
              {{ 'password_not_match' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-24">
          <mat-label>{{ "sign_step_one.location" | translate }}</mat-label>
          <div class="w-full relative input-rightspace">
            <input matInput matNativeControl formControlName="location" #placesRef="ngx-places" ngx-gp-autocomplete
              #location (onAddressChange)="handleAddressChange($event)" />
            <span class="icon-ic-target ic-input cursor-pointer"></span>
          </div>
        </mat-form-field>
        <mat-checkbox class="mb-24 terms-check" formControlName="acceptTerm" required>
          {{ "sign_step_one.terms_1" | translate }}
          <a mat-button class="text-link fw-medium" routerLink="/terms-and-conditions" target="_blank">
            {{ "sign_step_one.terms_2" | translate }}
          </a>
          {{ "sign_step_one.terms_3" | translate }}
        </mat-checkbox>
        <!-- <button mat-flat-button class="w-full mb-24" type="submit" [disabled]="submitDisabled"
          (click)="formSubmit(signupForm.value)">
          {{ "continue" | translate }}
        </button> -->
        <button mat-flat-button type="submit" class="w-full mb-24" [disabled]="submitDisabled && !loader"
          (click)="formSubmit(signupForm.value)">
          <mat-spinner *ngIf="loader" [diameter]="spinnerDiameter"
            color="var(--mdc-filled-button-label-text-color)"></mat-spinner>
          <ng-container *ngIf="!loader">
            {{ "continue" | translate }}
          </ng-container>
        </button>
        <div class="text-center text-grey">
          {{ "already_have_account" | translate }}
          <a mat-button routerLink="/login" class="text-link">{{
            "sign_in" | translate
            }}</a>
        </div>
      </div>
    </form>
  </div>

  <!-- Ends Signup Step 1 -->

  <!-- Starts Signup Step 2 -->
  <button mat-flat-button class="btn-dark skip-btn" (click)="redirectToDashboard()"
    *ngIf="currentStep != 1 && currentStep != 2">
    {{ "skip" | translate }}
  </button>


  <ng-container *ngIf="currentStep === 2">
    <app-signup-business-details [businessDetailFormData]="businessDetailFormData"
      (submittedBusinessDetails)="submittedBusinessDetails($event)"></app-signup-business-details>
  </ng-container>
  <!-- Ends Signup Step 2 -->

  <ng-container *ngIf="currentStep === 3">
    <app-account-protection [preferredCountries]="preferredCountries" [phone]="businessDetailFormData?.phone"
      (backButtonPressed)="setCurrentStep(2)" (submittedAccountProtection)="setCurrentStep(4)"></app-account-protection>
  </ng-container>

  <ng-container *ngIf="currentStep === 4">
    <app-primary-setup> </app-primary-setup>
  </ng-container>

</div>