import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TableManagementService {

  constructor(private http: HttpClient) { }

  getTableList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    // params = params.append('page', data.page);
    // params = params.append('per_page', data.per_page);
    // params = params.append('table_type', data.table_type);
    data.business_location_id ? (params = params.append('business_location_id', data.business_location_id)) : '';
    return this.http.get<ResponseMessage>('get-tablelist', { params });
  }

  addTable(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('add-table', data);
  }

  getTableDetail(id: any): Observable<ResponseMessage> {
    return this.http.get<ResponseMessage>(`get-table-details/${id}`);
  }

  updateTable(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('update-table', data);
  }

  updateSequence(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('table-sequence-update', data);
  }

  deleteTable(id: any): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`delete-table/${id}`);
  }

}
