import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAlphaWithSpaces]',
  standalone: true,
})
export class AlphaWithSpacesDirective {
  private regex = /^[a-zA-Z]+( [a-zA-Z]+)*$/; // Allows words separated by single spaces, no special characters.

  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value;

    // Sanitize input: Remove invalid characters
    const sanitizedValue = inputValue
      .replace(/[^a-zA-Z ]/g, '') // Remove non-alphabetic characters and special symbols
      .trimStart()
      .replace(/\s+/g, ' '); // Ensure single spaces between words

    // Update the input field value
    inputElement.value = sanitizedValue;

    // Update the form control value if available
    if (this.ngControl?.control) {
      this.ngControl.control.setValue(sanitizedValue, { emitEvent: false });
    }
  }

  @HostListener('blur') onBlur(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;

    // Trim spaces at the start and end on blur
    const trimmedValue = inputElement.value.trim();

    // Update the form control value if available
    if (this.ngControl?.control) {
      this.ngControl.control.setValue(trimmedValue, { emitEvent: true });
    }
  }
}
