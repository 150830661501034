import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterModule } from '@angular/router';
import { SuccessMessageComponent } from '../success-message/success-message.component';
import { UpdateListComponent } from '../update-list/update-list.component';
import { WaitingListService } from '../service/waiting-list.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ConstantService } from '../../../shared/services/constant.service';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ConfirmationComponent } from '../../../shared/components/dialog/confirmation/confirmation.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NoRecordsAvailableComponent } from 'src/app/shared/components/common/no-records-available/no-records-available.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EncryptionService } from 'src/app/shared/services/encryption.service';

@Component({
  selector: 'app-waiting-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    RouterModule,
    InfiniteScrollModule,
    TranslateModule,
    MatTooltipModule,
    NoRecordsAvailableComponent,
  ],
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.scss']
})
export class WaitingListComponent {
  private waitingListService = inject(WaitingListService);
  readonly dialog = inject(MatDialog);
  private constantService = inject(ConstantService);
  private encDecObj = inject(EncryptionService);
  private toastr = inject(ToastrService);
  private route = inject(Router);
  displayedColumns: string[] = ['customer_name', 'queue_number', 'number_of_people', 'phone_number', 'action'];
  dataSourceWaiting: any;
  waitingList: any = [];
  totalRecords = 0;
  waitingListPayload: {
    pageNumber: number;
    perPages: number;
    business_location_id: number
  } = {
      pageNumber: 1,
      perPages: 25,
      business_location_id: 0,
    };
  subscribeDeleteAPI!: Subscription;
  subscribeAPI!: Subscription;
  subscribeAlertAPI!: Subscription;
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;

  waitingProgressLoader = false;
  waitingPermission: any;

  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.waitingProgressLoader = state;
    });
  }

  ngOnInit(): void {
    // this.constantService.progressLoader.next(true);
    const permission = this.constantService.getModuleWiseRole('waiting_list_management');

    // if (permission?.update === 0 && permission?.delete === 0) {
    //   this.displayedColumns = this.displayedColumns.filter(column => column !== 'action');
    // }
    this.waitingPermission = permission;
    this.getWaitingList();
  }


  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
      this.waitingListPayload.business_location_id = this.selectedBranchLocationId;
    }
  }

  sendWaitingAlert(id: number) {
    if (this.subscribeAlertAPI) this.subscribeAlertAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeAlertAPI = this.waitingListService.sendWaitingAlert(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        this.waitingAlertDialog();
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  waitingAlertDialog() {
    const dialogRef = this.dialog.open(SuccessMessageComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      scrollStrategy: new NoopScrollStrategy()
    });
  }

  addEditWaitingListDialog(action: string, id: any = ''): void {
    const dialogRef = this.dialog.open(UpdateListComponent, {
      width: '564px',
      panelClass: 'modal-wrapper',
      scrollStrategy: new NoopScrollStrategy(),
      data: { action, id }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.getWaitingList();
      }
    });
  }


  getWaitingList() {
    this.constantService.progressLoader.next(true);
    this.getBusinesslocation();
    this.waitingListPayload.pageNumber = 1;
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
    this.subscribeAPI = this.waitingListService.getWaitingList(this.waitingListPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.waitingList = res.data.data;
          this.totalRecords = res.data.pagination.total;
          this.dataSourceWaiting = new MatTableDataSource(this.waitingList)
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  waitingListScrollDown() {
    this.constantService.progressLoader.next(true);
    this.getBusinesslocation();
    this.waitingListPayload.pageNumber += 1;
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
    this.subscribeAPI = this.waitingListService.getWaitingList(this.waitingListPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.waitingList = [...this.waitingList, ...res.data.data];
          this.dataSourceWaiting = new MatTableDataSource(this.waitingList);
          this.totalRecords = res.data.pagination.total;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  deleteWaitingDialog(id: number) {
    let data = {
      type: 3,
      title: 'delete_waiting',
      message: 'delete_waiting_message',
      buttonName: { cancel: 'cancel', yes: 'yes_confirm', },
      id: id
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.deleteWaiting(id);
      }
    })
  }

  deleteWaiting(id: any) {
    this.constantService.progressLoader.next(true);
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
    this.subscribeDeleteAPI = this.waitingListService.deleteWaiting(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          this.getWaitingList();
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  redirectToTable() {
    this.route.navigate(['/waiting-list/tables'])
  }

  ngOnDestroy(): void {
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
  }
}
