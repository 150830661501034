import { Component, Inject, inject, Optional } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { WaitingListService } from '../service/waiting-list.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ConstantService } from '../../../shared/services/constant.service';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { ToastrService } from 'ngx-toastr';
import { NumberOnlyDirective } from '../../../shared/directives/number-only.directive';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AlphaWithSpacesDirective } from 'src/app/shared/directives/alpha-with-spaces.directive';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { AuthService } from '../../auth/auth.service';
import { EncryptionService } from 'src/app/shared/services/encryption.service';

@Component({
  selector: 'app-update-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NumberOnlyDirective,
    AlphaWithSpacesDirective,
    NgxMatIntlTelInputComponent,
  ],
  templateUrl: './update-list.component.html',
  styleUrls: ['./update-list.component.scss']
})
export class UpdateListComponent {
  private waitingService = inject(WaitingListService);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  private dialogRef = inject(MatDialogRef<UpdateListComponent>)
  private constantService = inject(ConstantService);
  private encDecObj = inject(EncryptionService);
  waitingListForm!: FormGroup;
  subscribeListAPI!: Subscription;
  subscribeAPI!: Subscription;
  localData: any;
  waitingDetail: any;
  action = 'Add';
  preferredCountries: any = [];
  waitingProgressLoader = false;
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) data: UpdateListComponent) {
    this.localData = { ...data };
    this.action = this.localData.action;
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.waitingProgressLoader = state;
    });
  }

  initialWaitingForm() {
    this.waitingListForm = this.fb.group({
      id: [""],
      customer_name: ['', Validators.required],
      queue_number: [{ value: '', disabled: true }, Validators.required],
      number_of_people: ['', Validators.required],
      phone_number: ['', Validators.required],
      business_location_id: [''],
    });

  }
  ngOnInit() {
    this.initialWaitingForm();
    this.preferredCountries = this.constantService.getPrefferedCountry();
    if (this.action == 'Edit' && this.localData.id) {
      this.getWaitingDetail(this.localData.id);
    } else {
      this.constantService.progressLoader.next(true);
      this.getBusinesslocation();
      this.getQueueNumber();
    }
  }

  getQueueNumber() {
    const obj = {
      business_location_id: this.selectedBranchLocationId
    }
    this.waitingService.getQueueNumber(obj).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        const queueNumber = res.data.queue_number;
        this.waitingListForm.controls.queue_number.setValue(queueNumber)
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  getWaitingDetail(id: number) {
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.waitingService.getWaitingDetail(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        this.waitingDetail = res.data;
        this.setFormData(this.waitingDetail);
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  setFormData(data: any) {
    this.waitingListForm.patchValue({
      id: data.id,
      customer_name: data.customer_name,
      queue_number: data.queue_number,
      phone_number: data.phone_number,
      number_of_people: data.number_of_people,
      business_location_id: data.business_location_id,
    })
  }


  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
      this.waitingListForm.controls.business_location_id.setValue(this.selectedBranchLocationId);
    }
  }

  addUpdateWaiting() {
    this.waitingListForm.markAllAsTouched();
    if (this.waitingListForm.invalid) {
      return;
    }
    // this.waitingListForm.getRawValue().queueNumber
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
    this.constantService.progressLoader.next(true);

    const waitingObj = {
      id: this.waitingListForm.value.id || '',
      customer_name: this.waitingListForm.value.customer_name,
      queue_number: this.waitingListForm.getRawValue().queue_number,
      number_of_people: this.waitingListForm.value.number_of_people,
      phone_number: this.waitingListForm.value.phone_number,
      business_location_id: this.waitingListForm.value.business_location_id,
    }

    if (this.action == 'Add') {
      this.getBusinesslocation();
      this.subscribeAPI = this.waitingService.addWaiting(waitingObj).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((err) => {
          return throwError(() => err.error.message)
        })
      ).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.toastr.success(res.message);
          this.close(true);
        } else {
          this.toastr.error(res.message);
        }
      })
    } else if (this.action == 'Edit') {
      this.subscribeAPI = this.waitingService.updateWaiting(waitingObj).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((err) => {
          return throwError(() => err.error.message)
        })
      ).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.toastr.success(res.message);
          this.close(true);
        } else {
          this.toastr.error(res.message);
        }
      })
    }
  }

  close(status: boolean) {
    this.dialogRef.close({
      success: status,
      action: this.action,
    })
  }

}
