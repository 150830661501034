<div class="section-box mb-24 flex flex-wrap order-infowrapper gap-10px">
  <div class="order-infobox radius-12 innergap-10px">
    <span class="order-infoicon">
      <img src="assets/images/icons/ic-transaction.svg" alt="{{'number_of_transaction' | translate}}" />
    </span>
    <div class="fs-14 text-grey ">
      <h3 class="order-count text-white fw-bold">{{trasactionTotalCount?.transaction_count}}</h3>
      <span>{{'number_of_transaction' | translate}}</span>
    </div>
  </div>
  <div class="order-infobox radius-12 innergap-10px">
    <span class="order-infoicon">
      <img src="assets/images/icons/ic-money.svg" alt="{{'total_collection_before_fees' | translate}}" />
    </span>
    <div class="fs-14 text-grey ">
      <h3 class="order-count text-white fw-bold">${{trasactionTotalCount?.amount_before_fees.toFixed(2)}}</h3>
      <span>{{'total_collection_before_fees' | translate}}</span>
    </div>
  </div>
  <div class="order-infobox radius-12 innergap-10px">
    <span class="order-infoicon">
      <img src="assets/images/icons/graph.svg" alt="{{'total_net_sales_after_fees' | translate}}" />
    </span>
    <div class="fs-14 text-grey ">
      <h3 class="order-count text-white fw-bold">${{trasactionTotalCount?.amount_after_fees.toFixed(2)}}</h3>
      <span>{{'total_net_sales_after_fees' | translate}}</span>
    </div>
  </div>
</div>

<!-- Starts Filter Section -->
<div class="filter-boxwrapper section-box flex flex-justify-end flex-wrap mb-20">
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-date date-field">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="fromDate" [max]="currentDate" placeholder="{{'from' | translate}}"
        (click)="fromDate.open()" [(ngModel)]="selectFromDate" readonly (dateChange)="selectSearch(1, selectFromDate)">
      <mat-datepicker-toggle matIconSuffix [for]="fromDate" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #fromDate></mat-datepicker>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-date date-field">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="toDate" [max]="currentDate" [min]="selectFromDate"
        placeholder="{{'to' | translate}}" (click)="toDate.open()" [disabled]="!selectFromDate"
        [(ngModel)]="selectToDate" (dateChange)="selectSearch(2, selectToDate)" readonly>
      <mat-datepicker-toggle matIconSuffix [for]="toDate" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #toDate></mat-datepicker>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-day">
    <mat-select placeholder="{{'day' | translate}}" [(ngModel)]="selectDay">
      <mat-option value="" (click)="selectSearch(3, 0)">
        {{'day' | translate}}
      </mat-option>

      <mat-option *ngFor="let day of dayLists;" (click)="selectSearch(3, day.id)" [value]="day.id">
        {{ day.translateLabel | translate }}
      </mat-option>

    </mat-select>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-paymenttype">
    <mat-select placeholder="{{'transaction_payment_method' | translate}}" [(ngModel)]="selectPaymentMenthod">
      <mat-option value="" (click)="selectSearch(4, 0)">
        {{'transaction_payment_method' | translate}}
      </mat-option>

      <mat-option *ngFor="let pay of paymentMethods;" (click)="selectSearch(4, pay.id)" [value]="pay.id">
        {{ pay.translateLabel | translate }}
      </mat-option>

    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-paymenttype">
    <mat-select placeholder="{{'payment_type' | translate}}" [(ngModel)]="selectPaymentType">
      <mat-option value="" (click)="selectSearch(5, 0)">
        {{'payment_type' | translate}}
      </mat-option>

      <mat-option *ngFor="let type of paymentTypes;" (click)="selectSearch(5, type.id)" [value]="type.id">
        {{ type.translateLabel | translate }}
      </mat-option>

    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-location">
    <mat-select placeholder="{{'locations' | translate}}" [(ngModel)]="selectBranch">
      <mat-option value="" (click)="selectSearch(6, 0)">
        {{'locations' | translate}}
      </mat-option>
      <mat-option *ngFor="let branch of branchList;" (click)="selectSearch(6, branch.business_location_id)"
        [value]="branch.business_location_id">
        {{ branch.name }}
      </mat-option>

    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-item product-dropdown">
    <mat-select placeholder="{{'items' | translate}}" [(ngModel)]="selectProduct">
      <mat-option value="" (click)="selectSearch(7, 0)">
        {{'items' | translate}}
      </mat-option>
      <mat-option *ngFor="let item of productList;" (click)="selectSearch(7, item.id)" [value]="item.id">
        {{ item.name }}
      </mat-option>

    </mat-select>
  </mat-form-field>
  <button mat-flat-button class="btn-outline-primary mobile-filterbtn export-btn" [disabled]="isResetButtonDisabled"
    (click)="resetFilter()">{{'reset' | translate}}</button>
</div>
<!-- Ends Filter Section -->

<div class="table-sectionbox">
  <div class=" heading-box flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx">
    <h1 class="mb-0 mobile-full h3">{{'transaction' | translate}}</h1>
    <div class="flex gap-16px flex-grow-1 flex-justify-end searchbox-child inputwidth-auto ">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx search-inputbx">
        <div class="w-full relative input-rightspace">
          <input type="search" class="form-control" matInput (keyup)="serachTransactions()"
            placeholder="{{'search_transction' | translate}}" [(ngModel)]="serachTransaction" />
          <mat-icon class="ic-input cursor-pointer">search</mat-icon>
        </div>
      </mat-form-field>
      <button mat-flat-button class="flex-shrink-0 export-btn" [disabled]="totalRecord == 0"
        (click)="exportTransaction()">{{'export_transction' | translate}}</button>
    </div>
  </div>
  <div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="transactionListScrollDown()"
    [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
    <table mat-table [dataSource]="dataSourceTransactions" class="mat-elevation-z8">

      <!-- Order ID Column -->
      <ng-container matColumnDef="order_id">
        <th mat-header-cell *matHeaderCellDef>#{{'transaction_columns.order_id' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.order_id}} </td>
      </ng-container>

      <!-- AMOUNT Column -->
      <ng-container matColumnDef="sub_total">
        <th mat-header-cell *matHeaderCellDef>{{'transaction_columns.amount' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          ${{element.order_total_amount?element.order_total_amount?.toFixed(2):0}} </td>
      </ng-container>

      <!-- Date and Time Column -->
      <ng-container matColumnDef="transaction_date_time">
        <th mat-header-cell *matHeaderCellDef>{{'transaction_columns.trans_date_time' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          {{(element?.transaction_date_time | dateTime24Hr : true)}}
        </td>
      </ng-container>

      <!-- Colletcted From Column -->
      <ng-container matColumnDef="collected_from">
        <th mat-header-cell *matHeaderCellDef>{{'transaction_columns.collected_from' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.collected_from ? element.collected_from : '-'}} </td>
      </ng-container>

      <!-- Payment Methods Column -->
      <ng-container matColumnDef="payment_method">
        <th mat-header-cell *matHeaderCellDef>{{'transaction_columns.payment_method' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{ (element.payment_method | paymentMethodType |
          translate) }} </td>
      </ng-container>

      <!-- Cashier Name Column -->
      <ng-container matColumnDef="cashier_name">
        <th mat-header-cell *matHeaderCellDef>{{'transaction_columns.cashier_name' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.cashier_name}}</td>
      </ng-container>

      <ng-container matColumnDef="total_amount">
        <th mat-header-cell *matHeaderCellDef>{{'transaction_columns.total_amount' | translate}}</th>
        <td mat-cell *matCellDef="let element">${{element.total_amount.toFixed(2)}} </td>
      </ng-container>

      <ng-container matColumnDef="viewReceipt">
        <th mat-header-cell *matHeaderCellDef class="action-col">{{'transaction_columns.view_receipt' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex table-actionbtn flex-justify-center">
            <button mat-icon-button aria-label="View" matTooltip="View"
              (click)="viewReceipt(element.order_id,element.id)">
              <span class="icon-eye"></span>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- <div *ngIf="totalRecord == 0 && !transactionProgressLoader"
      class="no-records flex flex-align-center flex-justify-center flex-column">
      <img src="assets/images/empty-state.png" alt="No Record Found" class="mb-24" />
      <div class="text-primary fw-medium">{{'no_records' | translate}}</div>
    </div> -->

    <ng-container *ngIf="totalRecord == 0 && !transactionProgressLoader">
      <app-no-records-available></app-no-records-available>
    </ng-container>

  </div>
</div>