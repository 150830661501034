<header class="flex header-wrapper">
  <a (click)="redirectToModule(1)" class="header-logo flex-align-center flex flex-justify-center">
    <img src="assets/images/logo-main.svg" alt="MuyBi" class="logo-dark" />
    <img src="assets/images/logo-main-light.svg" alt="MuyBi" class="logo-light" />
  </a>
  <div class="nav-wrapper flex flex-align-center flex-justify-between flex-grow-1">
    <div class="flex navbarleft-wrap hide-mobile">
      <button mat-flat-button *ngIf="productPermission?.add == 1" (click)="redirectToModule(2)"
        class="flex-shrink-0 createorder-btn">
        {{ 'add_new_item'|translate }}
      </button>
    </div>
    <div class="flex flex-align-center navbarright-wrap">
      <div class="theme-buttons flex">
        <span class="icon-sun cursor-pointer" [ngClass]="{'active': themeType == 'light'}"
          (click)="changeTheme('light')"></span>
        <span (click)="changeTheme('dark')" [ngClass]="{'active': themeType == 'dark'}"
          class="icon-dark-mode cursor-pointer text-grey-light active"></span>
      </div>

      <div class="flex" *ngIf="userDetails?.user_type == 2">
        <button mat-button class="btn-outline-primary take-paymentbtn switchvendor" (click)="swicthToVendorAdmin()"
          [matTooltip]="'switch_to_vendor_admin' | translate">
          <span>{{'switch_to_vendor_admin' | translate}}</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="#03a7f6" viewBox="0 0 64 64" width="24" height="24">
            <path
              d="M 40 10 C 38.896 10 38 10.896 38 12 C 38 13.104 38.896 14 40 14 L 47.171875 14 L 30.585938 30.585938 C 29.804938 31.366938 29.804938 32.633063 30.585938 33.414062 C 30.976938 33.805063 31.488 34 32 34 C 32.512 34 33.023063 33.805062 33.414062 33.414062 L 50 16.828125 L 50 24 C 50 25.104 50.896 26 52 26 C 53.104 26 54 25.104 54 24 L 54 12 C 54 10.896 53.104 10 52 10 L 40 10 z M 18 12 C 14.691 12 12 14.691 12 18 L 12 46 C 12 49.309 14.691 52 18 52 L 46 52 C 49.309 52 52 49.309 52 46 L 52 34 C 52 32.896 51.104 32 50 32 C 48.896 32 48 32.896 48 34 L 48 46 C 48 47.103 47.103 48 46 48 L 18 48 C 16.897 48 16 47.103 16 46 L 16 18 C 16 16.897 16.897 16 18 16 L 30 16 C 31.104 16 32 15.104 32 14 C 32 12.896 31.104 12 30 12 L 18 12 z" />
          </svg>
        </button>
      </div>

      <button mat-flat-button class="btn-outline-primary" (click)="changeLanguage()">
        <span>{{selectedLanguage | languageName}}</span>
      </button>

      <!-- <mat-form-field appearance="outline" subscriptSizing="dynamic" class="header-selection lang-selection">
        <mat-select [(ngModel)]="selectedLanguage" panelClass="lang-menu" disableOptionCentering>
          <mat-option *ngFor="let lang of languages;" (click)="changeLanguage(lang.prefix)"
            [value]="lang.prefix">{{lang.name}}</mat-option>
        </mat-select>
      </mat-form-field> -->
      <!-- <mat-form-field appearance="outline" subscriptSizing="dynamic"
        class="hide-mobile header-selection location-selection">
        <mat-select placeholder="{{'branch_location' | translate}}" [(ngModel)]="selectedBranchLocationId">
          @for (branch of branchListData;) {
          <mat-option (click)="selectLocaiton(branch.business_location_id)"
            [value]="branch.business_location_id">{{branch.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field> -->
      <div class="hide-mobile header-selection location-selection" *ngIf="deviceType == 1">
        <app-branch-location></app-branch-location>
      </div>
      <button mat-flat-button class="btn-outline-primary hide-mobile take-paymentbtn">
        {{'take_payment' | translate}}
      </button>
      <button mat-mini-fab aria-label="Notification" class="text-theme">
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.01393 8.38943C4.01393 7.73465 4.1429 7.08627 4.39347 6.48133C4.64405 5.87639 5.01132 5.32672 5.47432 4.86372C5.93733 4.40072 6.48699 4.03344 7.09193 3.78287C7.69688 3.53229 8.34525 3.40332 9.00004 3.40332C9.65482 3.40332 10.3032 3.53229 10.9081 3.78287C11.5131 4.03344 12.0627 4.40072 12.5257 4.86372C12.9888 5.32672 13.356 5.87639 13.6066 6.48133C13.8572 7.08627 13.9861 7.73465 13.9861 8.38943V8.38943C13.9861 10.8755 14.507 12.32 14.9653 13.1117C15.014 13.196 15.0397 13.2916 15.0397 13.3889C15.0398 13.4863 15.0143 13.582 14.9658 13.6664C14.9173 13.7508 14.8474 13.8209 14.7632 13.8698C14.6791 13.9187 14.5835 13.9446 14.4861 13.945H3.51393C3.41657 13.9446 3.32102 13.9187 3.23683 13.8698C3.15265 13.8209 3.0828 13.7508 3.03427 13.6664C2.98574 13.582 2.96024 13.4863 2.96033 13.3889C2.96041 13.2916 2.98608 13.196 3.03476 13.1117C3.49309 12.32 4.01393 10.8755 4.01393 8.38943Z"
            stroke="currentColor" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M6.77771 13.9443V14.4999C6.77771 15.0893 7.01184 15.6545 7.42858 16.0712C7.84533 16.488 8.41056 16.7221 8.99993 16.7221C9.5893 16.7221 10.1545 16.488 10.5713 16.0712C10.988 15.6545 11.2222 15.0893 11.2222 14.4999V13.9443"
            stroke="currentColor" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12.8472 2.27832C13.9783 2.99236 14.8912 4.00379 15.4861 5.20193" stroke="currentColor"
            stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M2.51379 5.20193C3.1086 4.00379 4.02154 2.99236 5.15268 2.27832" stroke="currentColor"
            stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
      <button id="mobileMenu" (click)="toggleMenuClicked()" class="menu-btn" mat-mini-fab aria-label="Menu">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px" fill="currentColor"
          stroke="currentColor">
          <path
            d="M 5 8 A 2.0002 2.0002 0 1 0 5 12 L 45 12 A 2.0002 2.0002 0 1 0 45 8 L 5 8 z M 5 23 A 2.0002 2.0002 0 1 0 5 27 L 45 27 A 2.0002 2.0002 0 1 0 45 23 L 5 23 z M 5 38 A 2.0002 2.0002 0 1 0 5 42 L 45 42 A 2.0002 2.0002 0 1 0 45 38 L 5 38 z" />
        </svg>
      </button>
    </div>
  </div>
</header>