<div class="authentication-page flex flex-align-center flex-justify-center">

    <ng-container *ngIf="!checkRouteExist">
        <button mat-flat-button class="btn-dark skip-btn" (click)="redirectToDashboard()">
            {{ "skip" | translate }}
        </button>
        <button mat-flat-button class="btn-dark lang-btn" (click)="changeLanguage()">
            {{ language | languageName }}
        </button>
    </ng-container>
    <ng-container *ngIf="step == 1">
        <div class="authentication-box">
            <!-- <ng-container *ngIf="profileCompleteStatus$ | async as profileStatus"> -->
            <ng-container >
                <div class="auth-headerwrapper flex">
                    <div class="authlogo-box">
                        <div class="auth-box flex flex-align-center flex-justify-center">
                            <img src="./assets/images/logo-small.svg" alt="MuyBi" />
                        </div>
                    </div>
                    <div class="auth-header">
                        <h1>{{ "sign_step_four.title" | translate }}</h1>
                        <p class="text-grey">
                            {{ "sign_step_four.sub_title" | translate }}
                        </p>
                    </div>
                </div>
                <div class="auth-innerbox">
                    <div class="primary-setupboxes">
                        <div class="setup-box flex-align-center">
                            <span class="flex-shrink-0 setup-ic">
                                <img src="./assets/images/icons/ic-identity.svg" alt="Verify your identity" />
                            </span>
                            <div class="flex-grow-1 block text-grey-medium fs-14 setup-textwrap">
                                <h3 class="text-grey-light mb-6">
                                    {{ "sign_step_four.verify_your_identity" | translate }}
                                </h3>
                                <p>
                                    {{ "sign_step_four.verify_your_identity_desc" | translate }}
                                </p>
                            </div>
                            <div class="flex-shrink-0 buttoninfo-box text-end">
                                <a mat-button class="link-btn" (click)="redirectToSecondStep()">{{
                                    "edit" | translate
                                    }}</a>
                            </div>
                        </div>
                        <div class="setup-box flex-align-center">
                            <span class="flex-shrink-0 setup-ic">
                                <img src="./assets/images/icons/ic-bank.svg" alt="Link your bank accout" />
                            </span>
                            <div class="flex-grow-1 block text-grey-medium fs-14 setup-textwrap">
                                <h3 class="text-grey-light mb-6">
                                    {{ "sign_step_four.link_your_bank_account" | translate }}
                                </h3>
                                <p>
                                    {{ "sign_step_four.link_your_bank_account_desc" | translate }}
                                </p>
                            </div>
                            <div class="flex-shrink-0 buttoninfo-box text-end">
                                <a mat-button class="link-btn" (click)="redirectToSecondStep()">{{
                                    "edit" | translate
                                    }}</a>
                            </div>
                        </div>
                        <div class="setup-box flex-align-center">
                            <span class="flex-shrink-0 setup-ic">
                                <img src="./assets/images/icons/ic-menu.svg" alt="Create Menu" />
                            </span>
                            <div class="flex-grow-1 block text-grey-medium fs-14 setup-textwrap">
                                <h3 class="text-grey-light mb-6">
                                    {{ "sign_step_four.create_menu" | translate }}
                                </h3>
                                <p>{{ "sign_step_four.create_menu_desc" | translate }}</p>
                            </div>
                            <div class="flex-shrink-0 buttoninfo-box text-end">
                                <a mat-button class="link-btn" (click)="redirectToMenu()">{{
                                    "edit" | translate
                                    }}</a>
                            </div>
                        </div>
                        <div class="setup-box flex-align-center">
                            <span class="flex-shrink-0 setup-ic">
                                <img src="./assets/images/icons/ic-workstation.svg" alt="Order Hardware" />
                            </span>
                            <div class="flex-grow-1 block text-grey-medium fs-14 setup-textwrap">
                                <h3 class="text-grey-light mb-6">
                                    {{ "sign_step_four.order_hardware" | translate }}
                                </h3>
                                <p>{{ "sign_step_four.order_hardware_desc" | translate }}</p>
                            </div>
                            <div class="flex-shrink-0 buttoninfo-box text-end">
                                <a mat-button class="link-btn pointer-none"
                                    (click)="checkRouteExist?redirectToSetting():redirectToDashboard()">
                                    {{ "edit" | translate }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid2-col flex-justify-between gap-8px" *ngIf="checkRouteExist">
                        <button mat-flat-button class="w-full" (click)="redirectToSetting()">
                            {{ "back" | translate }}
                        </button>
                        <button mat-flat-button class="w-full" (click)="redirectToSetting()">
                            {{ "complete" | translate }}
                        </button>
                    </div>
                    <button mat-flat-button class="w-full" (click)="redirectToDashboard()" *ngIf="!checkRouteExist">
                        {{ "skip" | translate }}
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="step != 1">
        <div class="authentication-box">
            <form [formGroup]="bankAccountForm">
                <div class="auth-headerwrapper flex">
                    <div class="authlogo-box">
                        <div class="auth-box flex flex-align-center flex-justify-center">
                            <img src="./assets/images/logo-small.svg" alt="MuyBi" />
                        </div>
                    </div>
                    <div class="auth-header">
                        <h1>{{ "sign_step_five.title" | translate }}</h1>
                        <p class="text-grey">
                            {{ "sign_step_five.sub_title" | translate }}
                        </p>
                    </div>
                </div>
                <div class="auth-innerbox">
                    <div class="mb-20" [ngClass]="{ 'mat-form-field-invalid': fileTypeError }">
                        <label for="upload-image" class="form-label">
                            {{ "sign_step_five.upload_your_id_proof" | translate }}
                        </label>
                        <label for="upload-image"
                            class="form-control w-full flex flex-align-center cursor-pointer relative">
                            <span>{{ imageFileName }}</span>
                            <input type="file" class="form-control" id="upload-image" (change)="uploadImage($event)"
                                accept=".png, .jpg, .jpeg" formControlName="idProof" #fileInput hidden
                                (click)="imageCheck()" />

                            <span class="text-white file-text" *ngIf="!imageFileName">
                                {{ "browse" | translate }}
                            </span>
                            <span class="icon-image-plus ic-input"></span>
                        </label>
                        <span class="mat-mdc-form-field-error">
                            {{ fileTypeError | translate }}
                        </span>
                    </div>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                        <mat-label>
                            {{ "sign_step_five.bank_name" | translate }}
                        </mat-label>
                        <input matInput matNativeControl formControlName="bankName" appAlphaWithSpaces />
                    </mat-form-field>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                        <mat-label>
                            {{ "sign_step_five.routing_number" | translate }}
                        </mat-label>
                        <input matInput matNativeControl formControlName="routingNumber"  allowNumberOnly />
                    </mat-form-field>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                        <mat-label>
                            {{ "sign_step_five.account_number" | translate }}
                        </mat-label>
                        <input matInput matNativeControl formControlName="accountNumber" allowNumberOnly />
                    </mat-form-field>
                    <div class="grid-col2 gap-16px">
                        <mat-form-field subscriptSizing="dynamic" appearance="outline"
                            class="filter-location filterbox select-bgwrap small-select">
                            <mat-label>
                                {{ "sign_step_five.account_type" | translate }}
                            </mat-label>
                            <mat-select formControlName="accountType">
                                <ng-container *ngFor="let type of bankAccountType">
                                    <mat-option value="{{ type.value }}">
                                        {{ type.translateLabel | translate }}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-16">
                            <mat-label>
                                {{ "sign_step_five.account_holder_name" | translate }}
                            </mat-label>
                            <input matInput matNativeControl formControlName="accountHolderName" appAlphaWithSpaces />
                        </mat-form-field>
                    </div>
                    <div class="grid grid2-col flex-justify-between gap-8px">
                        <button mat-flat-button class="w-full  mb-24" (click)="step=1;">
                            {{ "back" | translate }}
                        </button>
                        <!-- <button mat-flat-button class="w-full mb-24" type="submit"
                            [disabled]="submitBankAccountDisabled"
                            (click)="bankAccountFormSubmit(bankAccountForm.value)">
                            {{ "submit" | translate }}
                        </button> -->
                        <button mat-flat-button type="submit" class="w-full mb-24"
                            (click)="bankAccountFormSubmit(bankAccountForm.value)">
                            <mat-spinner *ngIf="loader" [diameter]="spinnerDiameter"
                                color="var(--mdc-filled-button-label-text-color)"></mat-spinner>
                            <ng-container *ngIf="!loader">
                                {{ "submit" | translate }}
                            </ng-container>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>

</div>