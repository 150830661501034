<div class="heading-box flex-justify-between flex-wrap gap-8px mb-16 flex-align-center section-box managecat-card">
  <h1 class="text-grey-light mb-0 mobile-full">{{'manage_category' | translate}}</h1>
  <div class="gap-16px flex-grow-1 flex-justify-end searchbox-child">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
      <div class="w-full relative input-rightspace">
        <input type="text" class="form-control" (keyup)="serachCategories()" [(ngModel)]="serachCategory" matInput
          placeholder="{{'search_category' | translate}}" />
        <mat-icon class="ic-input cursor-pointer">{{'serach' | translate}}</mat-icon>
      </div>
    </mat-form-field>
    <button mat-flat-button class="flex-shrink-0" (click)="addCategory()"
      *ngIf="categoryPermission.add == 1">{{'add_new_category' | translate}}</button>
  </div>
</div>
<div class="relative tablebox-wrapper">
  <div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="categoryListScrollDown()"
    [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
    <table mat-table [dataSource]="dataSourceCategories" class="mat-elevation-z8">

      <!-- ID -->
      <ng-container matColumnDef="category_id">
        <th mat-header-cell *matHeaderCellDef class="category-id">#{{'category_columns.category_id' | translate }}</th>
        <td mat-cell *matCellDef="let element;let ind = index;"> {{ind+1}}</td>
      </ng-container>

      <!-- Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{'category_columns.category_name' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.name}} </td>
      </ng-container>

      <!-- IMAGE -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef class="text-center">{{'category_columns.category_image' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <div class="item-infobox single-image flex-justify-center">
            <img class="item-image img-fit" src="{{element.image}}" alt="">
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="action-col">{{'category_columns.action' | translate }}</th>
        <td mat-cell *matCellDef="let element;let ind = index;">
          <div class="flex table-actionbtn">
            <button mat-icon-button aria-label="Edit" matTooltip="{{'edit'  | translate}}"
              *ngIf="categoryPermission.update == 1" (click)="editCategory(element)"><span
                class="icon-edit-pencil"></span></button>
            <button mat-icon-button aria-label="Edit" matTooltip="{{ 'delete'| translate}}"
              *ngIf="categoryPermission.delete == 1" (click)="deleteCategoryDialog(element?.category_id,(ind+1))"><span
                class="icon-ic-trash"></span></button>
            <!-- element?.category_id -->
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <!-- <div class="no-records flex flex-align-center flex-justify-center flex-column"
      *ngIf="totalRecord == 0 && !categoryProgressLoader">
      <img src="assets/images/empty-state.png" alt="No Record Found" class="mb-24" />
      <div class="text-primary fw-medium">{{'no_records' | translate}}</div>
    </div> -->

    <ng-container *ngIf="totalRecord == 0 && !categoryProgressLoader">
      <app-no-records-available></app-no-records-available>
    </ng-container>

  </div>
</div>