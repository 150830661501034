<div  [ngClass]="{'pointer-none': itemProgressLoader}"
  class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px backbtn-wrap">
  <h1 class="text-grey-light mb-0 ">
    {{action | translate}} {{"item" | translate}}
  </h1>

  <a mat-flat-button class="btn-outline-secondary backbtn" (click)="backToProductList()">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    {{"back" | translate}}
  </a>

</div>


<div class="section-box block edit-formbox card-boxblock" [ngClass]="{'pointer-none': itemProgressLoader}">
  <form [formGroup]="productFormGroup">

    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
      <mat-label>{{'item_name' | translate}}</mat-label>
      <input matInput formControlName="productName">
    </mat-form-field>

    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="mb-20">
      <mat-label> {{'item_description' | translate}}</mat-label>
      <textarea matInput rows="3" formControlName="productDescription"></textarea>
    </mat-form-field>


    <div class="mb-20  block"> <!-- mat-form-field-invalid -->
      <span class="form-label"> {{'upload_image' | translate}}</span>
      <ng-container *ngIf="selectedImageList.length > 0">
        <div *ngFor="let imageData of selectedImageList" class="grid menu-uploadwrap flex-align-center">
          <label class="small-uploadimg flex flex-align-center flex-justify-center flex-shrink-0">
            <!-- <input type="file" class="form-control" id="upload-image" hidden /> -->
            <img [src]="imageData.url" alt="Menu" class="img-fit" />
          </label>
            <span class="imput-text-small">{{imageData?.image_name}}</span>
            <!-- <img src="assets/images/no-product.png" alt="Image Upload" /> -->
            <label for="upload-image" class="icon-image-plus ic-input cursor-pointer"></label>
          <span class="cursor-pointer text-danger flex-shrink-0 fs-14 addremove-btnbx"
            (click)="removeSelectedImage(imageData.media_id)">
            x {{'remove' | translate}}
          </span>
        </div>
      </ng-container>



      <div class="grid menu-uploadwrap flex-align-center cursor-pointer"
        [ngClass]="{'mat-form-field-invalid': !isImageSelcted}"
        (click)="selectedImageList.length < 5 ? openImageLibrary():''">
        <label class="small-uploadimg flex flex-align-center flex-justify-center flex-shrink-0">
          <img src="assets/images/no-product.png" alt="Image Upload" />
        </label>
        <div class="w-full flex flex-align-center w-full">
          <div class="w-full relative input-rightspace">
            <div class="form-control"></div>
            <label class="icon-image-plus ic-input cursor-pointer"></label>
          </div>
        </div>
        <span class="cursor-pointer text-gredient flex-shrink-0 fs-14 addremove-btnbx"
          [matTooltip]="selectedImageList.length == 5?('max_image' | translate):''">
          + {{'add_More' | translate}}
        </span>
      </div>
    </div>

    <div class="grid-5 mb-20">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{"price" | translate}}</mat-label>
        <div class="w-full relative input-rightspace">
          <input matInput formControlName="productPrice" allowFlotNumberOnly>
          <span class="icon-ic-price ic-input cursor-pointer"></span>
        </div>
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
        <mat-label>{{"item_unit" | translate}}</mat-label>
        <mat-select formControlName="productUnits">
          <mat-option *ngFor="let unit of unitListData;" [value]="unit.id">{{unit.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{"available_stock" | translate}}</mat-label>
        <input matInput formControlName="productStock" max="9999999999" [maxlength]="10" allowNumberOnly>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
        <mat-label>{{"menu_list" | translate}}</mat-label>

        <mat-select formControlName="productMenu">
          <mat-option *ngFor="let menu of menuListData;" [value]="menu.menu_id">{{menu.title}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
        <mat-label>{{"add_categories" | translate}}</mat-label>
        <mat-select formControlName="productCategory">

          <mat-option [value]="category.category_id"
            *ngFor="let category of categoryListData; ">{{category.name}}</mat-option>

        </mat-select>
      </mat-form-field>

      <!-- Display only restaurant user -->
      <!-- <ng-container *ngIf="userDetail.business_type === 2">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-select">
          <mat-label>{{ 'low_stock_alert_warn' | translate }}</mat-label>
          <input matInput formControlName="productLowStockAlert" max="9999999999" [maxlength]="10" allowNumberOnly />
          <mat-error *ngIf="productFormGroup.get('productLowStockAlert')?.hasError('exceedsAvailableStock')">
            {{ 'low_stock_alert_error' | translate }}
          </mat-error>
        </mat-form-field>
      </ng-container> -->
      <div>
        <label id="example-radio-group-label" class="text-grey-medium d-block mb-10">
          {{"available_to_sell" | translate}}*
        </label>

        <mat-radio-group aria-label="Select an option" formControlName="productAvailableToSell">
          <mat-radio-button *ngFor="let available of availableToSell;" [value]="available.id">
            {{available.translateLabel | translate}}
          </mat-radio-button>

        </mat-radio-group>
      </div>
    </div>
  </form>
  <div class="text-end">
    <button mat-flat-button class="btn-lg mobile-full" (click)="addUpdateProduct()">
      {{action == 'Add' ? ('save'| translate):('update' | translate)}}
    </button>
  </div>
</div>