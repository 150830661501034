import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, catchError, finalize, from, Observable, of, Subject, switchMap } from 'rxjs';
import { ResponseMessage } from '../../model/interfaces/req.res.interface';
import { Title } from '@angular/platform-browser';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { LangType, ThemeType } from '../../model/types/GlobalTypes';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { EncryptionService } from './encryption.service';
import { DateAdapter } from '@angular/material/core';
import { defaultLanguage, IPurl, spanishCountryList } from 'src/app/model/const/common.const';
import { LocationService } from './location.service';


@Injectable({
  providedIn: 'root',
})
export class ConstantService {
  private httpClient = inject(HttpClient);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private title = inject(Title);
  private translate = inject(TranslateService);
  private platformLocation = inject(PlatformLocation);
  private cookieService = inject(CookieService);
  private encDecObj = inject(EncryptionService);
  private locationService = inject(LocationService);
  private dateAdapter = inject(DateAdapter<Date>);
  langChangeSub = new Subject();
  progressLoader = new BehaviorSubject<boolean>(false);
  updateName = new BehaviorSubject<boolean>(false);
  updateLangLocationBase = new BehaviorSubject<boolean>(false);

  selectedSidebarMenu = new BehaviorSubject<any>({});
  private preferredCountriesKey = 'preferredCountries';

  constructor(@Inject(DOCUMENT) private document: Document) { }

  themeType = 'light';
  langType = 'en'; // Default es but temp eng
  language = 'selected_language';
  theme = 'selected_theme';
  selectedBranchLocation = 'select_business_location';

  translateData(language: string) {
    this.translate.use(language);
    this.dateAdapter.setLocale(language);
    localStorage.setItem(this.language, language);
  }
  /**
   * Change theme mode
   * @param theme - 'light' | 'dark'
   */
  setTheme(theme: ThemeType): void {
    const bodyClassList = this.document.body.classList;
    const currentTheme = this.themeType;

    if (bodyClassList.contains(currentTheme)) {
      bodyClassList.replace(currentTheme, theme);
    } else {
      bodyClassList.add(theme);
    }

    if (theme !== localStorage.getItem(this.theme)) {
      localStorage.setItem(this.theme, theme);
    }

    this.themeType = theme;
  }

  getTheme(): ThemeType {
    return (localStorage.getItem(this.theme) as ThemeType) ?? 'light';
  }

  getLanguage(): LangType {
    const storedLanguage = localStorage.getItem(this.language) as LangType;
    if (storedLanguage) {
      return storedLanguage;
    } else {

      //#region Start set language base on location
      this.locationService.getCurrentLocation().then(
        (location) => {
          const { latitude, longitude } = location;
          this.setLanguageBasedOnLocation(latitude, longitude);
        },
        (error) => {
          console.error('Error getting location:', error);
          this.setLanguageBasedOnLocation(0, 0); // Fallback to default if location can't be determined
        }
      );
      //#endregion End set language base on location

      //#region Start set language case on Device language
      // const fullLanguage = navigator.language || defaultLanguage;
      // const language = fullLanguage.substring(0, 2); // Extracts "en" or similar

      // this.translateData(language);
      // this.updateLangLocationBase.next(true);
      // alert(language)
      //#endregion End set language base on Device language

    }
    return defaultLanguage;
    // return (localStorage.getItem(this.language) as LangType) ?? defaultLanguage;
  }

  setLanguageBasedOnLocation(lat: number, lon: number) {
    this.locationService.getCountryCodeFromCoordinates(lat, lon).then(
      (countryCode) => {
        let language = defaultLanguage; // Default language
        const countryList = spanishCountryList;

        // Set language based on country code
        const country = countryList.find(c => c.countryCode === countryCode);
        if (country) {
          language = country.localLanguage === 'Spanish' ? 'es' : defaultLanguage;
        }
        this.translateData(language);
        this.updateLangLocationBase.next(true);
      },
      (error) => {
        console.error('Error fetching country code:', error);
        this.translateData(defaultLanguage); // Fallback to default language if there's an error
      }
    );
  }


  changeLanguages(lang: LangType) {
    const lan = lang.toLowerCase() === 'en' ? 'es' : 'en';
    this.translateData(lan);
    this.langChangeSub.next(lan);
  }

  /**
   * Change language
   * @param lang - 'es' | 'en'
   */
  setLang(lang: LangType): void {
    if (lang !== localStorage.getItem(this.language)) {
      localStorage.setItem(this.language, lang);
    }
    this.langType = lang;
    this.dateAdapter.setLocale(lang);
  }

  showSuccess(messageKey: string, titleKey?: string) {
    this.translate.get([messageKey, titleKey || '']).subscribe((translations: any) => {
      const message = translations[messageKey];
      const title = titleKey ? translations[titleKey] : undefined;
      this.toastr.success(message, title);
    });
  }


  showError(messageKey: string, titleKey?: string) {
    this.translate.get([messageKey, titleKey || '']).subscribe((translations: any) => {
      const message = translations[messageKey];
      const title = titleKey ? translations[titleKey] : undefined;
      this.toastr.error(message, title);
    });
  }

  /**
   * Get state
   */
  getState(countryId: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(
      `get-state?country_id=${countryId}`
    );
  }

  /**
   * Get city
   */
  getCity(stateId: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`get-city?state_id=${stateId}`);
  }

  /**
   * Set Module Title
   */
  // setModuleTitle(name: string) {
  //   this.title.setTitle(name + ' | MuyBi');
  // }

  setModuleTitle(nameKey: string) {
    this.translate.get(nameKey).subscribe((translatedName: string) => {
      this.title.setTitle(`${translatedName} | MuyBi`);
    });
  }

  /**
   * Set Language API
   */
  // setLanguageAPI(data: any): Observable<ResponseMessage> {
  //   return this.httpClient.post<ResponseMessage>('change-language', data);
  // }

  /**
   * Get Configuration 
   */
  getConfiguration(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('get-business-configuration');
  }


  getConfigurationDetail() {
    if (localStorage.getItem('business_configuration')) {
      const detail = JSON.parse(this.encDecObj.getDecrypt(localStorage.getItem('business_configuration')));
      return detail;
    }
  }

  /**
  * Get User Module Permission 
  */
  getUserModulePermission(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('get-user-permissions');
  }

  getUserDetail() {
    if (localStorage.getItem('userDetail')) {
      const userDetail = JSON.parse(
        this.encDecObj.getDecrypt(localStorage.getItem('userDetail'))
      );
      return userDetail;
    }
  }

  getRoleData() {
    if (localStorage.getItem('module_permission')) {
      const permissionModules = JSON.parse(
        this.encDecObj.getDecrypt(localStorage.getItem('module_permission'))
      );
      return permissionModules;
    }
  }

  getModuleWiseRole(slug: string) {
    const role = this.getRoleData();

    // Proceed only if permission exists
    if (role) {
      const permission = role.find(
        (item: { slug: string }) => item.slug === slug
      );
      return permission;
    }
  }

  /**
   * Get Branch List
   */
  getBranchList(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('view-branch');
  }

  selectLocation(id: number) {
    const temp = this.encDecObj.setEncrypt(id.toString());
    localStorage.setItem(this.selectedBranchLocation, temp);
  }

  /**
   * Get Policy And Term & Conditions
   */
  getPolicyAndTermConditions(): Observable<ResponseMessage> {
    return of({
      message: 'string',
      success: true,
      data: {
        policy: 'Privacy Policy',
        terms: 'Terms and Conditions',
      },
      code: 200,
    });
    // return this.httpClient.get<ResponseMessage>('terms-and-conditions');
  }

  /**
   * Get previous 12 months dropdown options
   */
  getPrevious12Months(): { name: string; value: string }[] {
    const locale = this.getLanguage();
    const months = [];
    const now = new Date();
    for (let i = 0; i < 12; i++) {
      const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const monthName = date.toLocaleString(locale, {
        month: 'long',
        year: 'numeric',
      });
      const year = date.getFullYear();
      const value = `${year}-${('0' + (date.getMonth() + 1)).slice(-2)}`; // Format YYYY-MM
      months.push({ name: monthName, value });
    }
    return months;
  }



  getCountryName(): Observable<any> {
    const url = IPurl;
    return this.httpClient.get(url);
  }

  setPreferredCountry(countries: Array<string>) {
    localStorage.setItem(this.preferredCountriesKey, JSON.stringify(countries));
  }

  getPrefferedCountry() {
    const country = localStorage.getItem(this.preferredCountriesKey);
  
    if (country) {
      try {
        const parsedCountry = JSON.parse(country);
        return Array.isArray(parsedCountry) ? parsedCountry : [parsedCountry];
      } catch (e) {
        return [country];
      }
    }
    return ['us'];
  }


  getChartMonthLabels() {
    const locale = this.getLanguage();
    const month = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(0, i); // Create a date for the ith month of the year
      return date.toLocaleString(locale, { month: 'short' }); // Get the abbreviated month name
    });
    return month;
  }


  replaceStateUrl() {
    const path = window.location.pathname ? window.location.pathname : '';
    window.history.replaceState({}, document.title, window.location.origin + path);
    // window.history.replaceState({}, document.title, window.location.origin + path + `#/${route}`);
  }

  logOutUser() {
    const storedTheme = this.getTheme();
    const storedLanguage = this.getLanguage();
    this.router.navigate(['/login']);
    localStorage.clear();
    this.setTheme(storedTheme);
    this.setLang(storedLanguage);
  }

  getDeviceType() {
    // 1 = Desktop , 2 Mobile
    let type = 1;
    if (window.innerWidth >= 992 && window.innerWidth <= 1024) {
      type = 1;
    } else if (window.innerWidth <= 991) {
      type = 2;
    }
    return type;
  }

  /**
  * Swich Login
  */
  swicthToVendorAdmin(data: any): Observable<ResponseMessage> {
    return this.httpClient.post<ResponseMessage>('change-language', data);
  }
  redirectToURL(url: string, newTab: string) {
    window.open(url, newTab);
  }

  downloadFile(url: string, name: string): void {
    const fileName = name;

    // Use RxJS to fetch the file
    from(fetch(url))
      .pipe(
        switchMap(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        }),
        finalize(() => {
          // Finalize logic (e.g., hide a loading indicator if added)
          this.progressLoader.next(false);
        }),
        catchError(error => {
          // Handle errors and rethrow if needed
          this.showError('file_failed_to_download');
          return of(null); // Return null or handle appropriately
        })
      )
      .subscribe(blob => {
        if (blob) {
          const link = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          link.href = objectUrl;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectUrl); // Clean up
          this.showSuccess('file_downloaded');
        }
      });
  }

  //#region Flutter APP

  getCookieDomain() {
    let url = (this.platformLocation as any).location.host;
    return url;
  }

  getFlutterAppPlatform() {
    const platfrom: string = this.cookieService.get('muybi_platform');
    return platfrom;
  }

  redirectToFultterURL(status: string, param?: any) {
    if (status === 'invoicePreview') {
      window.open(`https://www.muybi_invoice_preview?fileName=${param.name}&pdfUrl=${param.url}`, '_blank');
    }
    else if (status === 'invoiceDownload') {
      window.open(`https://www.muybi_invoice_download?fileName=${param.name}&pdfUrl=${param.url}`, '_blank');
    }

  }
  //#endregion Flutter APP
}
