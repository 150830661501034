<div [ngClass]="localData?.invoice?'productPopup':''">
  <div class="product-listing">
    <div class="section-box mb-24 category-wrapper" [ngClass]="localData?.invoice?'top_sticky':''">
      <div class="grid grid2-col flex-justify-between flex-wrap gap-8px pb-16 flex-align-center  mobilegrid-colfull">
        <h1 class="text-white mb-0 mobile-full">{{'categories' | translate}}</h1>
        <div class="flex gap-24px flex-grow-1 flex-justify-end flex-align-center innergap-10px">
          <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
            <div class="w-full relative input-rightspace ">
              <input type="search" class="form-control" matInput placeholder="{{'search_product' | translate}}"
                (keyup)="serachCheckOutMenus()" [(ngModel)]="serachCheckOutMenu" />
              <mat-icon class="ic-input cursor-pointer">{{'serach' | translate}}</mat-icon>
            </div>
          </mat-form-field>
          <!-- <button routerLink="/my-orders" mat-flat-button class="btn-outline-primary myorder-btn">My Orders</button> -->

          <div *ngIf="localData?.invoice" (click)="closePopup()" style="cursor: pointer;">
            <mat-icon>close</mat-icon>
          </div>

        </div>
      </div>
      <div class="category-filter flex innergap-16px custom-scroll">
        <ng-container *ngFor="let category of categoryList;">
          <button type="button" class="category-filterbtn flex flex-align-center flex-shrink-0"
            (click)="selectMenuCategory(category.category_id)" [class.active]="categoryId == category.category_id">
            <span class="product-icon">
              <img [src]="category.image" [alt]="category.name" />
            </span>
            {{ category.name }}
          </button>
        </ng-container>

      </div>
    </div>
    <div>
      <div [ngStyle]="{'display':localData?.invoice?'flex':'','justify-content':localData?.invoice?'space-between':''}">
        <h3 class="mb-20 h1">{{'menu' | translate}}</h3>
        <button mat-flat-btn *ngIf="localData?.invoice"
          class="flex-shrink-0 createorder-btn mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-unthemed mat-mdc-button-base"
          [disabled]="selectedProduct?.length==0" (click)="selectProducts()">Add</button>
      </div>

      <div class="menu-listwrap custom-scroll" infinite-scroll (scrolled)="checkOutMenuScrollDown()"
        [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
        <ng-container *ngFor="let menu of checkOutMenuList; let index = index">
          <a (click)="localData?.invoice || checkoutPermission?.add != 1 ?'':selectProductForOrder(menu)"
            class="menu-item text-decoration-none cursor-pointer" [ngClass]="{
              'disable-product': menu.available_stock == 0 || menu.available_to_sell == 2,
              'pointer-none': checkoutPermission?.add != 1
            }">

            <div class="image-box">
              <div class="flex product-imagewrap">
                <div class="product-img" [ngClass]="{'no-image': !menu.url }">

                  <ng-container *ngIf="localData?.invoice">
                    <mat-checkbox [id]="'image_'+index" (change)="changeProductCheckboxe($event,menu)" #checkbox
                      [checked]="isChecked(menu)">
                    </mat-checkbox>
                  </ng-container>

                  <img [src]="menu.url ? menu.url : muybiLogo" [alt]="menu.title" [ngClass]="{'img-fit': menu.url }"
                    (click)="localData?.invoice?toggleCheckbox(index,menu):''" />

                  <span *ngIf="menu.available_stock == 0 || menu.available_to_sell == 2"
                    class="out-stock">{{'out_of_stock' | translate}}</span>

                </div>
              </div>
              <div class="curve-wrapper">
                <div class="curve"></div>
              </div>
            </div>
            <div class="product-textwrap">
              <h5 class="mb-4 text-white fw-semibold">{{menu.name}}</h5>
              <span class="text-primary fs-18 fw-bold ">${{menu.price}}</span>
            </div>
            <div class="items-shape"></div>
          </a>
        </ng-container>


      </div>
    </div>
  </div>

  <div *ngIf="totalRecord == 0 && !itemProgressLoader">
    <app-no-records-available></app-no-records-available>
  </div>

  <app-create-order-retail *ngIf="!localData?.invoice" [orderItemsData]="orderItems">

  </app-create-order-retail>



  <!-- <app-create-order-restaurant></app-create-order-restaurant> -->
</div>
<svg style="visibility: hidden; position: absolute;" width="0" height="0" xmlns="http://www.w3.org/2000/svg"
  version="1.1">
  <defs>
    <filter id="goo">
      <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
      <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
      <feComposite in="SourceGraphic" in2="goo" operator="atop" />
    </filter>
  </defs>
</svg>