<div class="section-box mb-24 heading-box flex-wrap flex-align-center gap-16px header-bx mb-25 tablet-single">
  <h1 class="text-white mb-0">{{ "product_management" | translate }}</h1>
  <div class=" filter-wrapper flex-align-center flex-grow-1 flex-justify-end filter-section flex-wrap">
    <mat-form-field subscriptSizing="dynamic" class="select-category select-dark">
      <mat-select [(ngModel)]="selectCategory" placeholder="{{ 'select_category' | translate }}">
        <mat-option value="" (click)="selectSearch(1, 0)">
          {{ "select_category" | translate }}
        </mat-option>
        <mat-option *ngFor="let category of categoryListData;" (click)="selectSearch(1, category.category_id)"
          [value]="category.category_id">
          {{ category.name }}
        </mat-option>

      </mat-select>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" class="select-branchlocation select-dark">
      <mat-select placeholder="{{ 'branch_location' | translate }}" [(ngModel)]="selectBranch">
        <mat-option value="" (click)="selectSearch(2, 0)">
          {{ "branch_location" | translate }}
        </mat-option>
        <mat-option *ngFor="let branch of branchListData;" (click)="selectSearch(2, branch.business_location_id)"
          [value]="branch.business_location_id">
          {{ branch.name }}
        </mat-option>

      </mat-select>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" class="select-inventorystatus select-dark">
      <mat-select [(ngModel)]="selectedInventory" placeholder="{{ 'invetory_status' | translate }}">
        <mat-option value="" (click)="selectSearch(3, 0)">
          {{ "invetory_status" | translate }}
        </mat-option>
        <mat-option *ngFor="let status of inventoryStatus;" (click)="selectSearch(3, status.id)" [value]="status.id">
          {{ status.translateLabel | translate }}
        </mat-option>

      </mat-select>
    </mat-form-field>
    <button mat-flat-button class="btn-outline-primary btn-lg" [disabled]="isResetButtonDisabled"
      (click)="resetFilter()"> {{ "reset" | translate }}</button>
  </div>
</div>

<div class="table-sectionbox">
  <div
    class="heading-box flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx tablet-fullgrid">
    <h1 class="mb-0 mobile-full h3">{{ "items" | translate }}</h1>
    <div class=" gap-16px flex-grow-1 flex-justify-end flex-wrap items-box  grid tablet-fullgrid">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx input-lg">
        <div class="w-full relative input-rightspace">
          <input type="search" class="form-control" matInput (keyup)="searchProducts()" [(ngModel)]="serachProduct"
            placeholder="{{ 'search_item' | translate }}" />
          <mat-icon class="ic-input cursor-pointer">serach</mat-icon>
        </div>
      </mat-form-field>
    </div>
  </div>
  <div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="productListScrollDown()"
    [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
    <table mat-table [dataSource]="dataSourceProducts" class="mat-elevation-z8">
      <!-- ITEM NAME Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="item-namecol">
          {{ "products_column.item_name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          <div class="item-infobox">
            <img [src]="element.url" [alt]="element?.image_name" class="img-fit item-image" />
            <span class="text-truncate max-w-300" [matTooltip]="element.name">{{ element.name }}</span>
          </div>
        </td>
      </ng-container>

      <!-- CATEGORY Column -->
      <ng-container matColumnDef="category_name">
        <th mat-header-cell *matHeaderCellDef>
          {{ "products_column.category" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="nowrap">{{ element.category_name }}</td>
      </ng-container>

      <!-- BRANCH LOCATION Column -->
      <ng-container matColumnDef="business_location_name">
        <th mat-header-cell *matHeaderCellDef>
          {{ "products_column.branch_location" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.business_location_name }}
        </td>
      </ng-container>

      <!-- STOCK Column -->
      <ng-container matColumnDef="available_stock">
        <th mat-header-cell *matHeaderCellDef>
          {{ "products_column.stock" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.available_stock !== '' ? element.available_stock : '-'}}
        </td>
      </ng-container>

      <!-- AVAILABLE TO SELL Column -->
      <ng-container matColumnDef="available_to_sell">
        <th mat-header-cell *matHeaderCellDef>
          {{ "products_column.available_to_sell" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div [ngClass]="{
              'text-grey-dark not-availabletxt': element?.available_to_sell != 1
            }">
            {{ element?.available_to_sell == 1 ? ('available' | translate) : ('not_available' | translate) }}
          </div>
        </td>
      </ng-container>

      <!-- PRICE Column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>
          {{ "products_column.price" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">${{ element.price }}</td>
      </ng-container>

      <!-- ACTION Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="action-col text-center">
          {{ "products_column.action" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="flex table-actionbtn flex-justify-center">
            <div class="flex table-actionbtn">
              <button mat-icon-button aria-label="View" matTooltip="{{ 'view' | translate }}" (click)="viewProduct(element.item_id)">
                <span class="icon-eye"></span>
              </button>
              <button mat-icon-button aria-label="Edit" matTooltip="{{ 'edit' | translate }}"
                *ngIf="productPermission.update == 1" (click)="editProduct(element.item_id)">
                <span class="icon-development"> </span>
              </button>
              <button mat-icon-button aria-label="Edit" matTooltip="{{ 'delete' | translate }}"
                *ngIf="productPermission.delete == 1" (click)="deleteProductDialog(element?.item_id)">
                <span class="icon-ic-trash"></span>
              </button>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <ng-container *ngIf="totalRecord == 0 && !itemProgressLoader">
      <app-no-records-available></app-no-records-available>
    </ng-container>

  </div>
</div>