
import { DOCUMENT } from '@angular/common';
import { Component, inject, Inject, } from '@angular/core';
import { ConstantService } from './shared/services/constant.service';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // standalone: true
})
export class AppComponent {

  private constantService = inject(ConstantService);

  themeType = 'light';
  langType = 'en'; // Default es but temp eng

  //#region Localstorage 
  language = 'selected_language';
  theme = 'selected_theme';
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.initializeTheme();
    this.initializeLang();
    this.setCountry();
  }

  /**
   * Initial theme settings
   */
  private initializeTheme(): void {
    const storedTheme = this.constantService.getTheme();
    this.constantService.setTheme(storedTheme);
  }

  /**
   * Initial language settings
   */
  private initializeLang(): void {
    const storedLang = this.constantService.getLanguage();
    this.constantService.translateData(storedLang);
    this.constantService.setLang(storedLang);
  }


  ngOnInit() {
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`
    );
    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty(
        '--app-height',
        `${window.innerHeight}px`
      );
    });
  }

  setCountry() {
    this.constantService.getCountryName().pipe(
      catchError((err) => {
        console.log(err.error.message);
        return throwError(() => err.error.message);
      })
    ).subscribe((response) => {
      const preferredCountries = response?.country ? [response.country.toLowerCase()] : ['US'];
      this.constantService.setPreferredCountry(preferredCountries);
    });
  }
}

