import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { MatDialogModule, } from '@angular/material/dialog';
import { ConstantService } from '../../../shared/services/constant.service';
import { PasswordMatchAndValidatorDirective } from 'src/app/shared/validators/password-match-and-validator.directive';


@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    CommonModule,
    TranslateModule,
    MatDialogModule,
    PasswordMatchAndValidatorDirective,
  ],
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  passwordFormGroup!: FormGroup;
  subscribeApi!: Subscription;
  hideOldPass: boolean = false;
  hidePass: boolean = false;
  hideCPass: boolean = false;
  constantService = inject(ConstantService);
  // private dialogRef!: MatDialogRef<ChangePasswordComponent>;
  constructor() { }

  initialPasswordForm() {
    this.passwordFormGroup = this.fb.group({
      oldPassword: ['', Validators.required],
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required],
    })
  }

  ngOnInit() {
    this.initialPasswordForm();
  }

  changePassword() {
    this.passwordFormGroup.markAllAsTouched(); // Highlight validation errors if form is invalid
    if (this.passwordFormGroup.invalid) return;

    const formData = new FormData();
    formData.append('old_password', this.passwordFormGroup.value.oldPassword);
    formData.append('password', this.passwordFormGroup.value.password);
    formData.append('password_confirmation', this.passwordFormGroup.value.confirmPassword);

    this.constantService.progressLoader.next(true);
    if (this.subscribeApi) this.subscribeApi.unsubscribe();

    this.subscribeApi = this.authService.changePassword(formData).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        this.toastr.success(res.message);
        this.passwordFormGroup.reset({
          oldPassword: '',
          password: '',
          confirmPassword: ''
        });
        Object.keys(this.passwordFormGroup.controls).forEach((key) => {
          const control = this.passwordFormGroup.get(key);
          if (control) control.setErrors(null);
        });
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  cancel() {
    // this.dialogRef.close();
    if (this.subscribeApi) this.subscribeApi.unsubscribe();
  }

}
