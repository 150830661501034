import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ReservationService } from '../services/reservation.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConstantService } from 'src/app/shared/services/constant.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ResponseMessage } from 'src/app/model/interfaces/req.res.interface';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EncryptionService } from 'src/app/shared/services/encryption.service';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { AuthService } from '../../auth/auth.service';
import { NumberOnlyDirective } from 'src/app/shared/directives/number-only.directive';
import { TrimSpacesDirective } from 'src/app/shared/directives/trim-spaces.directive';
@Component({
  selector: 'app-add-reservation',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    NgxMatIntlTelInputComponent,
    TrimSpacesDirective,
    NumberOnlyDirective,
  ],
  templateUrl: './add-reservation.component.html',
  styleUrls: ['./add-reservation.component.scss'],
  providers: [DatePipe]
})
export class AddReservationComponent {
  private reservationService = inject(ReservationService);
  private fb = inject(FormBuilder);
  private encDecObj = inject(EncryptionService);
  private route = inject(Router);
  private activateRoute = inject(ActivatedRoute);
  private constantService = inject(ConstantService);
  private toastr = inject(ToastrService);
  private authService = inject(AuthService);
  private datePipe = inject(DatePipe);
  currentDate: Date = new Date();
  currentTime: string = this.getRoundedTime(new Date());
  minTime: string = '00:00';
  reservationId = 0;
  reservationDetail: any;
  reservationForm!: FormGroup;
  tableListData: any;
  isTableNotAvailable: boolean = false;
  subscribeAPI!: Subscription;
  reservationProgressLoader = false;
  reservationPermission: any;
  preferredCountries: any = [];
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  constructor() {
    this.reservationId = this.activateRoute.snapshot.params['id'];
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.reservationProgressLoader = state;
    });

  }

  initialReservationForm() {
    this.reservationForm = this.fb.group({
      id: [''],
      customer_name: ['', Validators.required],
      mobile_number: ['', Validators.required],
      no_of_guests: ['', Validators.required],
      select_date: [this.currentDate, Validators.required],
      select_time: [this.currentTime, Validators.required],
      select_table: ['', Validators.required],
      event: ['']
    })
  }

  ngOnInit() {
    this.initialReservationForm();
    this.preferredCountries = this.constantService.getPrefferedCountry();
    this.constantService.progressLoader.next(true);
    if (this.reservationId) {
      this.getReservationDetail(this.reservationId);
    } else {
      this.selectDateTime();
    }
  }


  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }

  getTableList(data: any) {
    this.constantService.progressLoader.next(true);
    this.reservationService.getTableList(data).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.tableListData = res.data.data;
          if (!this.reservationForm.get('select_table')?.value) {
            this.reservationForm.patchValue({
              select_table: this.reservationDetail?.table_id || '',
            });
          }
          this.checkTableAvailability(this.reservationDetail?.table_id);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  checkTableAvailability(tableId: number): void {
    this.isTableNotAvailable = !this.tableListData.some((table: { id: number; }) => table.id === tableId);
  }

  getReservationDetail(id: number) {
    if (this.subscribeAPI) this.subscribeAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeAPI = this.reservationService.getReservationDetail(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        this.reservationDetail = res.data;
        this.setFormData(this.reservationDetail);
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  setFormData(data: any) {
    const formattedTime = this.roundToNearestMinutes(data.start_time, 5);


    // const apiDate = new Date(data.date);

    // // Compare the two dates
    // if (apiDate < this.currentDate) {
    //   this.currentDate = new Date(); 
    // } else {
    //   this.currentDate = apiDate; 
    // }

    this.reservationForm.patchValue({
      id: data.id,
      customer_name: data.customer_name,
      mobile_number: data.phone_number,
      no_of_guests: data.number_of_guest,
      select_date: data.date, // Use the resolved `currentDate`
      select_time: formattedTime,
      select_table: data.table_id,
      event: data.event_name
    });

    this.selectDateTime();
  }


  // Utility to round time to the nearest 5 minutes
  private roundToNearestMinutes(time: string, gap: number): string {
    if (!time) return ''; // Handle invalid input
    const [hours, minutes] = time.split(':').map(Number);

    // Round minutes to the nearest multiple of the gap
    const roundedMinutes = Math.round(minutes / gap) * gap;
    const adjustedHours = roundedMinutes === 60 ? hours + 1 : hours;
    const adjustedMinutes = roundedMinutes === 60 ? 0 : roundedMinutes;

    // Format and return the rounded time as 'HH:mm'
    return `${adjustedHours.toString().padStart(2, '0')}:${adjustedMinutes.toString().padStart(2, '0')}`;
  }
  addUpdateReservation() {
    this.reservationForm.markAllAsTouched();
    if (this.reservationForm.invalid) {
      return;
    }

    let resevationObject: any = {
      customer_name: this.reservationForm.value.customer_name,
      phone_number: this.reservationForm.value.mobile_number,
      number_of_guest: this.reservationForm.value.no_of_guests,
      date: this.datePipe.transform(this.reservationForm.value.select_date, 'yyyy-MM-dd'),
      start_time: this.reservationForm.value.select_time,
      table_id: this.reservationForm.value.select_table,
      event_name: this.reservationForm.value.event,
      business_location_id: this.selectedBranchLocationId,
    }

    this.constantService.progressLoader.next(true);
    if (this.reservationId) {
      resevationObject['id'] = this.reservationId;
      this.reservationService.updateReservation(resevationObject).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })).subscribe((res: any) => {
          if (res.code == 200) {
            this.toastr.success(res.message);
            this.backToReservationList();
          } else {
            this.toastr.error(res.message);
          }
        })
    } else {
      this.reservationService.addReservation(resevationObject).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message);
        })).subscribe((res: any) => {
          if (res.code == 200) {
            this.toastr.success(res.message);
            this.backToReservationList();
          } else {
            this.toastr.error(res.message);
          }
        })
    }
  }

  onTimeSet(selectedTime: string) {
    this.reservationForm.patchValue({ select_time: selectedTime });
    this.selectDateTime();
  }

  selectDateTime() {
    this.getBusinesslocation();

    // Format the selected date
    const selectedDate = this.datePipe.transform(this.reservationForm.value.select_date, 'yyyy-MM-dd');

    const currentTime = this.getRoundedTime(new Date());

    // Check if the selected date is today
    const isToday = selectedDate && this.isSameDate(new Date(selectedDate), new Date());

    // Get the selected time from the form
    let selectedTime = this.reservationForm.value.select_time;

    // If the selected date is today and the selected time is in the past, set the time to the current rounded time
    if (isToday && selectedTime && this.isPastTime(selectedTime, currentTime)) {
      selectedTime = currentTime;
      this.reservationForm.patchValue({ select_time: selectedTime });
    }

    // Set `minTime` dynamically based on whether the selected date is today
    this.minTime = isToday ? currentTime : '00:00';

    const obj = {
      select_date: selectedDate,
      select_time: selectedTime,
      business_location_id: this.selectedBranchLocationId,
    };

    this.getTableList(obj);
  }

  // Helper method to check if a time is in the past
  private isPastTime(selectedTime: string, currentTime: string): boolean {
    const [selectedHours, selectedMinutes] = selectedTime.split(':').map(Number);
    const [currentHours, currentMinutes] = currentTime.split(':').map(Number);

    if (selectedHours < currentHours) {
      return true;
    } else if (selectedHours === currentHours && selectedMinutes < currentMinutes) {
      return true;
    }
    return false;
  }


  // Utility function to get the next nearest 5-minute rounded time
  private getRoundedTime(date: Date): string {
    const minutes = date.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 5) * 5; // Round up to the nearest 5
    const hours = roundedMinutes === 60 ? date.getHours() + 1 : date.getHours();
    const adjustedMinutes = roundedMinutes === 60 ? 0 : roundedMinutes;

    // Format hours and minutes as "HH:mm"
    return `${hours.toString().padStart(2, '0')}:${adjustedMinutes.toString().padStart(2, '0')}`;
  }

  // Utility to check if two dates are the same day
  private isSameDate(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }



  backToReservationList() {
    this.route.navigate(['/reservation'])
  }
}

