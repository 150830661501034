import { CdkDragEnd, CdkDragEnter, CdkDragMove, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddTableComponent } from '../add-table/add-table.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { typeOfTableReservation } from '../../../model/const/common.const';
import { CommonModule } from '@angular/common';
import { TableManagementService } from '../services/table-management.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ConstantService } from '../../../shared/services/constant.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationComponent } from '../../../shared/components/dialog/confirmation/confirmation.component';
import { NoRecordsAvailableComponent } from 'src/app/shared/components/common/no-records-available/no-records-available.component';
import { EncryptionService } from 'src/app/shared/services/encryption.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TableDynamicClassPipe } from 'src/app/shared/pipes/table-dynamic-class.pipe';
@Component({
  selector: 'app-table-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    DragDropModule,
    MatCardModule,
    MatTooltipModule,
    MatRadioModule,
    TranslateModule,
    InfiniteScrollModule,
    NoRecordsAvailableComponent,
    TableDynamicClassPipe,
  ],
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
})
export class TableListComponent {
  private tableManagementService = inject(TableManagementService);
  private constantService = inject(ConstantService);
  private encDecObj = inject(EncryptionService);
  private toastr = inject(ToastrService);
  readonly dialog = inject(MatDialog);
  @ViewChild('tableScrollContainer', { static: false }) tableScrollContainer!: ElementRef<HTMLDivElement>;
  isClassAdded = false;
  isEditModeOn: boolean = false;
  tableList: any = [];
  tableStatusCount: any = [];
  totalRecords: number = 0;
  selectedTableId: number = 0;
  selectTableType: any = 1;
  tableProgressLoader = false;
  typeOfReservation = typeOfTableReservation;
  subscriptionList!: Subscription;
  subscriptionDelete!: Subscription;
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  tableListPayload = {
    // page: 1,
    // per_page: 15,
    user_business_location_id: 0,
    tableType: this.selectTableType,
  }
  @Input() moduleType: number = 1;

  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.tableProgressLoader = state;
    });
  }

  ngOnInit(): void {
    this.getTableList();
  }

  toggleClass() {
    this.isClassAdded = !this.isClassAdded;
  }

  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }

  startEditMode() {
    this.isEditModeOn = !this.isEditModeOn;
  }

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    moveItemInArray(this.tableList, dragIndex, dropIndex);

    this.tableList.forEach((table: { sequence: any; }, index: number) => {
      table.sequence = index + 1;
    });
  }

  dragEnded(event: CdkDragEnd) {
    this.updateSequence();
  }

  onDragMove(event: CdkDragMove) {
    if (!this.tableScrollContainer?.nativeElement) {
      console.error('tableScrollContainer is undefined during drag');
      return;
    }
    const container = this.tableScrollContainer.nativeElement;
    const { y } = event.pointerPosition;
    const topBoundary = container.offsetTop + 20; // Adjust as needed
    const bottomBoundary = container.offsetTop + container.offsetHeight - 20;

    if (y < topBoundary) {
      container.scrollBy({ top: -15, behavior: 'smooth' });
    } else if (y > bottomBoundary) {
      container.scrollBy({ top: 15, behavior: 'smooth' });
    }
  }

  updateSequence() {
    this.constantService.progressLoader.next(true);
    const obj = {
      data: JSON.stringify(this.tableList)
    }
    this.tableManagementService.updateSequence(obj).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })
    ).subscribe((res: any) => {
      if (res.code == 200) {
        this.toastr.success(res.message);
        this.getTableList();
      } else {
        this.toastr.error(res.error);
      }
    })
  }

  createEditTable(action: String, id?: number) {
    let obj: any = { action };

    if (action === 'Edit' && id) {
      obj.id = id;
    }
    const dialogRef = this.dialog.open(AddTableComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      data: obj
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.success) {
        this.getTableList();
      }
    })
  }

  selectTableTypeFilter(id: number) {
    // this.selectTableType = id;
    //this.getTableList();
  }

  getTableList() {
    this.getBusinesslocation();
    if (this.subscriptionList) this.subscriptionList.unsubscribe();
    const tablePayload = {
      // table_type: this.selectTableType,
      business_location_id: this.selectedBranchLocationId,
    }
    this.constantService.progressLoader.next(true);
    this.subscriptionList = this.tableManagementService.getTableList(tablePayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: any) => {
        if (res.code == 200) {
          this.tableList = res.data.data;
          this.tableStatusCount = res.data.table_status_count;
          this.totalRecords = this.tableList.length;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  // tableListScrollDown() {
  //   this.constantService.progressLoader.next(true);
  //   this.tableManagementService.tableList(this.tableListPayload).pipe(
  //     finalize(() => this.constantService.progressLoader.next(false)),
  //     catchError((error) => {
  //       this.toastr.error(error.error.message);
  //       return throwError(() => error.error.message)
  //     })).subscribe((res: any) => {
  //       if (res.code == 200) {
  //         this.tableList = [...this.tempTableList, ...res.data.data];
  //         this.tempTableList = this.tableList;
  //       } else {
  //         this.toastr.error(res.message);
  //       }
  //     })
  // }

  //#region Delete table

  deleteTableDialog(id: number) {
    let data = {
      type: 3,
      title: 'delete',
      message: 'delete_table_message',
      buttonName: { cancel: 'cancel', yes: 'yes', },
      id: id
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.deleteTable(id);
      }
    })
  }

  deleteTable(id: any) {
    this.constantService.progressLoader.next(true);
    if (this.subscriptionDelete) this.subscriptionDelete.unsubscribe();
    this.subscriptionDelete = this.tableManagementService.deleteTable(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })
    ).subscribe((res: any) => {
      if (res.code == 200) {
        this.toastr.success(res.message);
        this.getTableList();
      } else {
        this.toastr.error(res.error);
      }
    })
  }
  //#endregion Delete table

  selectTable(id: number) {
    this.selectedTableId = id;
  }

  ngOnDestroy(): void {
    if (this.subscriptionList) this.subscriptionList.unsubscribe();
    if (this.subscriptionDelete) this.subscriptionDelete.unsubscribe();
  }
}
