<div>
  <div class="header-box">
    <button class="flex-shrink-0 close-btn cursor-pointer" mat-icon-button (click)="close()">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_2)">
          <rect width="25" height="25" rx="12.5" fill="white" />
          <g clip-path="url(#clip1_1_2)">
            <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd"
              d="M11.4014 -0.0244141C12.1175 -0.0244141 12.8336 -0.0244141 13.5498 -0.0244141C17.5117 0.405402 20.6284 2.24459 22.9003 5.49316C24.0634 7.28169 24.7552 9.23481 24.9756 11.3525C24.9756 12.1012 24.9756 12.85 24.9756 13.5986C24.5314 17.5403 22.6922 20.6409 19.458 22.9003C17.6552 24.0736 15.6858 24.7653 13.5498 24.9756C12.8336 24.9756 12.1175 24.9756 11.4014 24.9756C7.5127 24.5497 4.42837 22.7594 2.14844 19.6045C0.925048 17.7816 0.200767 15.7795 -0.0244141 13.5986C-0.0244141 12.85 -0.0244141 12.1012 -0.0244141 11.3525C0.41973 7.4108 2.25892 4.31019 5.49316 2.05078C7.29595 0.877519 9.26533 0.185786 11.4014 -0.0244141ZM7.78809 7.05566C8.15781 6.99628 8.48334 7.0858 8.76466 7.32422C9.99414 8.56186 11.2312 9.79072 12.4756 11.0107C13.7702 9.72422 15.0723 8.44658 16.3819 7.17773C17.0025 6.91264 17.4827 7.06728 17.8222 7.64159C17.9297 7.95781 17.9133 8.26705 17.7734 8.56934C16.5045 9.87886 15.227 11.181 13.9404 12.4756C15.227 13.7702 16.5045 15.0723 17.7734 16.3819C18.0386 17.0025 17.8839 17.4827 17.3095 17.8222C16.9252 17.9517 16.567 17.9028 16.2353 17.6758C14.9895 16.4219 13.7363 15.1767 12.4756 13.9404C11.181 15.227 9.87886 16.5045 8.56934 17.7734C7.94869 18.0386 7.46855 17.8839 7.12891 17.3095C7.02153 16.9933 7.03784 16.6841 7.17773 16.3819C8.44658 15.0723 9.72422 13.7702 11.0107 12.4756C9.72422 11.181 8.44658 9.87886 7.17773 8.56934C6.90048 7.86186 7.10391 7.35733 7.78809 7.05566Z"
              fill="#03A7F6" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1_2">
            <rect width="25" height="25" rx="12.5" fill="white" />
          </clipPath>
          <clipPath id="clip1_1_2">
            <rect width="25" height="25" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  </div>
  <iframe [src]="(localData.pdfUrl) | safeUrl" width="100%" height="700px" frameborder="0" (load)="onPdfLoad()"
    (error)="onPdfError()"></iframe>
  <div *ngIf="loading">{{ 'loading_pdf'| translate}}</div>
</div>