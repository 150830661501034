import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../modules/auth/auth.service';
import { environment } from 'src/environments/environment';

// // Check Before Login OLD
// export const authGuard: CanActivateFn = (route, state) => {
//   if (!inject(AuthService).getToken()) {
//     inject(Router).navigate(['/login'])
//     return false;
//   }
//   return true;
// };

// Check Before Login NEW
export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.getToken()) {
    router.navigate(['/login']);
    return false; // Block navigation if the user is not authenticated.
  }
  return true; // Allow navigation if the user is authenticated.
};

// // Check after Login OLD
// export const authGuardafterLogin: CanActivateFn = (route, state) => {
//   if (inject(AuthService).getToken()) {
//     if ((route.routeConfig?.path == 'login' || route.routeConfig?.path == 'signup') && !(route.queryParams.token && route.queryParams.lang)) {
//       inject(Router).navigate(['/dashboard'])
//       return true;
//     }
//     else if(route.queryParams.token && route.queryParams.lang && (route.routeConfig?.path == 'login')){
//       localStorage.clear();
//       inject(Router).navigateByUrl(state.url); // Redirect to the same URL with fresh state.
//       return false; // Block the initial navigation.
//     }
//   }
//   return true;
// };

// Check After Login NEW
export const authGuardafterLogin: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const hasToken = authService.getToken();
  const isLoginOrSignup = ['login', 'signup'].includes(route.routeConfig?.path || '');
  const hasQueryParams = route.queryParams.token && route.queryParams.lang;

  if (hasToken) {
    if (isLoginOrSignup && !hasQueryParams) {
      router.navigate(['/dashboard']);
      return false; // Block navigation to login/signup if query params are missing.
    }

    if (hasQueryParams && route.routeConfig?.path === 'login') {

      if (environment.production) {
        // Code for DIff domain
        localStorage.clear();
      } else {
        // Code for same domain
        localStorage.removeItem('token');
        localStorage.removeItem('select_business_location');
      }
      router.navigateByUrl(state.url); // Refresh state with token and lang.
      return false; // Block initial navigation.
    }
  }

  return true; // Allow navigation if no conditions are met.
};
