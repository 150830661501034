import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslateModule } from '@ngx-translate/core';
import { ReservationService } from '../services/reservation.service';
import { ConstantService } from 'src/app/shared/services/constant.service';
import { ResponseMessage } from 'src/app/model/interfaces/req.res.interface';
import { ConfirmationComponent } from 'src/app/shared/components/dialog/confirmation/confirmation.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EncryptionService } from 'src/app/shared/services/encryption.service';
import { NoRecordsAvailableComponent } from 'src/app/shared/components/common/no-records-available/no-records-available.component';
import { MatSortModule, SortDirection } from '@angular/material/sort';

@Component({
  selector: 'app-reservation-list',
  templateUrl: './reservation-list.component.html',
  styleUrls: ['./reservation-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    RouterModule,
    InfiniteScrollModule,
    TranslateModule,
    MatSortModule,
    NoRecordsAvailableComponent,
  ],
})
export class ReservationListComponent {
  private reservationService = inject(ReservationService);
  private constantService = inject(ConstantService);
  readonly dialog = inject(MatDialog);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  subscription!: Subscription;
  subscriptionDetail!: Subscription;
  displayedColumns: string[] = ['customer_name', 'phone_number', 'number_of_guest', 'reservation_date_time', 'reserved_table', 'event_name', 'action'];
  dataSourceReservation: any;
  reservationList: any = [];
  totalRecords: number = 0;
  subscribeListAPI!: Subscription;
  subscribeDeleteAPI!: Subscription;
  reservationPayload: {
    pageNumber: number;
    perPages: number;
    sortBy: SortDirection; // Typed as SortDirection
    sortField: string;
  } = {
      pageNumber: 1,
      perPages: 25,
      sortBy: 'desc', // Matches SortDirection type
      sortField: 'customer_name',
    };
  tableListData: any;
  selectTable: any;
  defaultReservationSearchPayload = {
    searchValue: '',
    table: '',
  };
  isResetButtonDisabled: boolean = true;
  reservationSearchPayload: any = { ...this.defaultReservationSearchPayload };
  serachCustomer: String = '';
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  private encDecObj = inject(EncryptionService);

  reservationProgressLoader = false;
  reservationPermission: any;

  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.reservationProgressLoader = state;
    });
  }

  ngOnInit(): void {
    this.constantService.progressLoader.next(true);
    const permission = this.constantService.getModuleWiseRole('reservation_management');

    if (permission?.update === 0 && permission?.delete === 0) {
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'action');
    }
    this.reservationPermission = permission;
    this.getTableList();
    this.getReservationList();
  }

  getTableList() {
    this.getBusinesslocation();
    this.reservationService.getAllTableList(this.selectedBranchLocationId).pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.tableListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }

  getReservationList() {
    this.getBusinesslocation();
    this.reservationPayload.pageNumber = 1;
    let reservationPayload = {
      page: this.reservationPayload.pageNumber,
      per_page: this.reservationPayload.perPages,
      sortBy: this.reservationPayload.sortBy,
      sortField: this.reservationPayload.sortField,
      business_location_id: this.selectedBranchLocationId,
      search_text: this.reservationSearchPayload?.searchValue,
      select_table: this.reservationSearchPayload?.table,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.reservationService.getReservationList(reservationPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.reservationList = res.data.data;
          this.totalRecords = res.data.pagination.total;
          this.dataSourceReservation = new MatTableDataSource(this.reservationList)
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  reservationListScrollDown() {
    this.reservationPayload.pageNumber += 1;
    let reservationPayload = {
      page: this.reservationPayload.pageNumber,
      per_page: this.reservationPayload.perPages,
      sortBy: this.reservationPayload.sortBy,
      sortField: this.reservationPayload.sortField,
      business_location_id: this.selectedBranchLocationId,
      search_text: this.reservationSearchPayload?.searchValue,
      select_table: this.reservationSearchPayload?.table,
    }
    this.constantService.progressLoader.next(true);
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.subscribeListAPI = this.reservationService.getReservationList(reservationPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.reservationList = [...this.reservationList, ...res.data.data];
          this.dataSourceReservation = new MatTableDataSource(this.reservationList);
          this.totalRecords = res.data.pagination.total;
        } else {
          this.toastr.error(res.message);
        }
      })
  }



  selectSearch(type: number, value: number) {
    // 1 = Table
    if (type == 1 && value == 0 && this.reservationSearchPayload.table == 0) {
      return;
    }
    else if (type == 1) {
      this.reservationSearchPayload.table = value;
    }
    this.getReservationList();
    this.checkResetButtonShouldBeDisabled();
  }

  reservationSort(event: any) {
    this.reservationPayload.pageNumber = 1;
    this.reservationPayload.perPages = 25;
    this.reservationPayload.sortBy = event.direction;
    this.reservationPayload.sortField = event.active;
    this.getReservationList();
  }

  // #region Search 
  searchCustomers() {
    this.serachCustomer = this.serachCustomer.trimStart();
    const searchText = this.serachCustomer.trim();
    if (searchText !== this.reservationSearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.reservationSearchPayload.searchValue = searchText;
        this.getReservationList();
      } else if (!searchText && this.reservationSearchPayload.searchValue !== '') {
        this.reservationSearchPayload.searchValue = '';
        this.getReservationList();
      }
    }
    this.checkResetButtonShouldBeDisabled();
  }
  checkResetButtonShouldBeDisabled(): void {
    this.isResetButtonDisabled = JSON.stringify(this.reservationSearchPayload) === JSON.stringify(this.defaultReservationSearchPayload);
  }

  resetFilter(): void {
    this.reservationSearchPayload = { ...this.defaultReservationSearchPayload };
    this.checkResetButtonShouldBeDisabled();
    this.serachCustomer = '';
    this.selectTable = '';
    this.isResetButtonDisabled = true;
    this.getReservationList();
  }

  deleteReservationDialog(id: number) {
    let data = {
      type: 3,
      title: 'delete',
      message: 'delete_reservation_messag',
      buttonName: { cancel: 'cancel', yes: 'yes', },
      id: id
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.deleteReservation(id);
      }
    })
  }

  deleteReservation(id: any) {
    this.constantService.progressLoader.next(true);
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
    this.subscribeDeleteAPI = this.reservationService.deleteReservation(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          this.getReservationList();
        } else {
          this.toastr.error(res.message);
        }
      })
  }



  addEditReservation(action: string, id?: number) {
    if (action == 'Add') {
      this.router.navigate(['/reservation/add']);
    } else {
      this.router.navigate([`/reservation/edit/${id}`]);
    }
  }


  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.subscriptionDetail) this.subscriptionDetail.unsubscribe();
  }
}
