<div class="heading-box flex-justify-between flex-wrap gap-8px mb-16 flex-align-center section-box">
  <h1 class="mb-0 mobile-full h3 fw-bold">{{"menu" | translate}}</h1>
  <div class="flex gap-16px flex-grow-1 flex-justify-end searchbox-child inputwidth-auto">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
      <div class="w-full relative input-rightspace">
        <input type="search" class="form-control" matInput placeholder="{{'search_menu' | translate}}"
          (keyup)="serachMenus()" [(ngModel)]="serachMenu" />
        <mat-icon class="ic-input cursor-pointer">search</mat-icon>
      </div>
    </mat-form-field>
    <button mat-flat-button *ngIf="menuPermission?.add == 1" (click)="createEditMenu('Add')"
      class="flex-shrink-0 createmenu-btn">
      {{"create_menu" | translate}}
    </button>
  </div>
</div>

<div class="grid flex-wrap filter-boxwrapper flex-justify-end">
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-category filterbox select-bgwrap">
    <mat-select [(ngModel)]="selectCategory" placeholder="{{ 'select_category' | translate }}">
      <mat-option value="" (click)="selectSearch(1, 0)">
        {{ "select_category" | translate }}
      </mat-option>
      <mat-option *ngFor="let category of categoryListData;" (click)="selectSearch(1, category.category_id)"
        [value]="category.category_id">{{ category.name
        }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-location filterbox select-bgwrap">
    <mat-select placeholder="{{ 'branch_location' | translate }}" [(ngModel)]="selectBranch">
      <mat-option value="" (click)="selectSearch(2, 0)">
        {{ "branch_location" | translate }}
      </mat-option>
      <mat-option *ngFor="let branch of branchListData;" (click)="selectSearch(2, branch.business_location_id)"
        [value]="branch.business_location_id">{{
        branch.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-status filterbox select-bgwrap">
    <mat-select [(ngModel)]="selectedInventory" placeholder="{{ 'invetory_status' | translate }}">
      <mat-option value="" (click)="selectSearch(3, 0)">
        {{ "invetory_status" | translate }}
      </mat-option>
      <mat-option *ngFor="let status of inventoryStatus;" (click)="selectSearch(3, status.id)" [value]="status.id">{{
        status.name
        }}</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button class="btn-outline-primary" [disabled]="isResetButtonDisabled"
    (click)="resetFilter()">{{'reset' | translate}}</button>
</div>

<!-- Starts Food List Section -->
<div class="menu-listwrap" infinite-scroll (scrolled)="menuListScrollDown()" [immediateCheck]="true"
  [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
  <div class="menu-item text-decoration-none" *ngFor="let menu of menuListData;">
    <button mat-fab aria-label="Edit" class="edit-productbtn"
      *ngIf="menuPermission?.update == 1 || menuPermission?.delete == 1" (click)="createEditMenu('Edit',menu.menu_id)">
      <span class="icon-edit-pencil"></span>
    </button>
    <div class="image-box">
      <div class="flex product-imagewrap">
        <div class="product-img" [ngClass]="{'no-image': !menu.image_url }">
          <img [src]="menu.image_url ? menu.image_url : muybiLogo" [alt]="menu.title"
            [ngClass]="{'img-fit': menu.image_url }" />
        </div>
      </div>
      <div class="curve-wrapper">
        <div class="curve"></div>
      </div>
    </div>
    <div class="product-textwrap block">
      <h5 class="mb-4 text-white fw-semibold">{{menu.title}}</h5>
    </div>
    <div class="items-shape"></div>
  </div>
</div>

<!-- <div class="no-records flex flex-align-center flex-justify-center flex-column"
  *ngIf="totalRecord == 0 && !menuProgressLoader">
  <img src="assets/images/empty-state.png" alt="No Record Found" class="mb-24" />
  <div class="text-primary fw-medium">{{'no_records' | translate}}</div>
</div> -->

<div *ngIf="totalRecord == 0 && !menuProgressLoader">
  <app-no-records-available></app-no-records-available>
</div>

<!-- Ends Food List Section -->

<!-- HTML for Product Curve Design -->
<svg style="visibility: hidden; position: absolute;" width="0" height="0" xmlns="http://www.w3.org/2000/svg"
  version="1.1">
  <defs>
    <filter id="goo">
      <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
      <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
      <feComposite in="SourceGraphic" in2="goo" operator="atop" />
    </filter>
  </defs>
</svg>
<!-- Ends HTML for Product Curve Design -->