import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import {
  AUTHORIZATION_SCOPE,
  LoginFormInputFields,
} from '../../model/interfaces/auth.interface';
import { ResponseMessage } from '../../model/interfaces/req.res.interface';
import { defaultCountryCode, IPurl } from '../../model/const/common.const';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })

export class AuthService {

  tokenName = 'AuthToken';
  // api = environment.apiUrl;
  private preferredCountries: Array<string> = defaultCountryCode; // Default value
  private preferredCountriesKey = 'preferredCountries';


  /**
   * Supply JWT token data to APP
   */
  private authorizationScope = new ReplaySubject<AUTHORIZATION_SCOPE>(1);
  authorizationScope$ = this.authorizationScope.asObservable();

  constructor(private http: HttpClient) {
    const tokenName = this.getToken();
    if (tokenName !== '{}') this.setAuthorizationScope(this.decodeToken());
  }

  // getCountryName(): Observable<any> {
  //   const url = IPurl;
  //   return this.http.get(url);
  // }

  decodeToken(
    token = localStorage.getItem(this.tokenName)
  ): AUTHORIZATION_SCOPE {
    // this.helper is jwt function
    // this.helper = new JwtHelperService();
    // return this.helper.decodeToken(token || '{}') ?? ({} as AUTHORIZATION_SCOPE);
    return {} as AUTHORIZATION_SCOPE;
  }

  public setAuthorizationScope(data: AUTHORIZATION_SCOPE): void {
    this.authorizationScope.next(data);
  }

  getToken(): string {
    return localStorage.getItem('token') as string;
  }

  /**
   *
   * @param params token
   * @returns sets the token
   */

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  /**
   * SignUp API
   */
  signup(params: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('register', params);
  }

  /**
   * get business questions
   */
  businessQuestionBaseBusinessType(): Observable<ResponseMessage> {
    return this.http.get<ResponseMessage>('business-questions');
  }

  /**
   * Add business details
   */
  addBusinessDetails(params: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('add-business-details', params);
  }

  /**
   * Add business details
   */
  saveAccountProtection(params: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('save-account-protection', params);
  }

  /**
   * sendOTP API
   */
  sendOTP(params: unknown): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('register-send-otp', params);
  }

  /**
   * verifySignupOTP API
   */
  verifyOTP(params: unknown): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('verfiy-otp', params);
  }

  /**
   * resendOTP API
   */
  resendOTP(params: unknown): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('resend-otp', params);
  }

  /**
   * verifyProtectionToken API
   */
  verifyProtectionToken(params: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('verfiy-protection-token', params);
  }

  /**
   * getMicrosoftQRCode API
   */
  getMicrosoftQRCode(): Observable<ResponseMessage> {
    return this.http.get<ResponseMessage>('get-qr-code');
  }

  /**
   * getProfileCompleteStatus API
   */
  getProfileCompleteStatus(): Observable<ResponseMessage> {
    return this.http.get<ResponseMessage>('profile-complete-status');
  }

  /** signin api */
  signin(data: LoginFormInputFields): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('login', data);
  }

  /** signin Via api */
  signinViaSwitch(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('login-via-switch', data);
  }

  /**
   *
   * @param data sends email in payload
   * @returns sends forgot password link in mail
   */
  forgotPassword(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('forget-password', data);
  }

  /**
   *
   * @param data it's reset password forms data
   * @returns
   * resets the password
   */
  resetPassword(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('reset-password', data);
  }

  /** logoutapi */
  logOut(): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('logout', '');
  }

  /**
   *
   * @param data it's reset password forms data
   * @returns
   * Change password
   */
  changePassword(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('change-password', data);
  }

  // Add bank account
  addEditBankAccount(data: any): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>('add-edit-bank-account', data);
  }

  setPreferredCountries(countries: Array<string>) {
    localStorage.setItem(this.preferredCountriesKey, JSON.stringify(countries));
  }

  getPreferredCountries(): Array<string> {
    const storedCountries = localStorage.getItem(this.preferredCountriesKey);
    return storedCountries ? JSON.parse(storedCountries) : ['us']; // Default to 'us' if nothing is stored
  }

  removePreferredCountries() {
    localStorage.removeItem(this.preferredCountriesKey);
  }
}
