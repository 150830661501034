<!-- Starts Header -->
<div
  class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">{{'waiting_list_management' | translate}}</h1>
  <a mat-flat-button class="btn-outline-secondary backbtn" (click)="backToWaitingList()">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    {{'back' | translate}}
  </a>
</div>
<app-table-list [moduleType]="2"></app-table-list>
<!-- <div class="bg-grey-lighter radius-12 table-rowsbox">
    <div class="block mb-20">
  
      <div class="table-row">
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
       
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02d</span>
          </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="row-info table-boxwrap">
          <mat-card-content class="table-block rows-infoblock" >
            A
          </mat-card-content>
        </mat-card>
      </div>
     
     
     
      <div class="table-row">
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
       
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="row-info table-boxwrap">
          <mat-card-content class="table-block rows-infoblock" >
            A
          </mat-card-content>
        </mat-card>
      </div>
     
     
     
      <div class="table-row">
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="selected table-boxwrap cursor-pointer" routerLink="/waiting-list">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span> 
          </mat-card-content>
        </mat-card>
       
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="row-info table-boxwrap">
          <mat-card-content class="table-block rows-infoblock" >
            A
          </mat-card-content>
        </mat-card>
      </div>
     
   
     
      <div class="table-row">
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
       
        <mat-card appearance="outlined" class="not-available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        
        <mat-card appearance="outlined" class="available table-boxwrap">
          <mat-card-content class="table-block">
            <span class="reserved-table text-truncate">T-02</span>
          </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="row-info table-boxwrap">
          <mat-card-content class="table-block rows-infoblock" >
            A
          </mat-card-content>
        </mat-card>
      </div>
     
  
  
    </div>
    <div class="text-center">
      <div class="table-status flex-wrap fs-12">
        <span class="status-info flex flex-align-center available-text">Available : 3</span>
        <span class="status-info flex flex-align-center notavailable-text">Reserved : 6</span>
        <span class="status-info flex flex-align-center selected-text">Selected</span>
      </div>
    </div>
</div> -->