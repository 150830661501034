<div class="form-boxwrapper flex flex-wrap ">
  <!-- on click list box add class open-descwrapper in form-leftwrapper and add class 'active' in form-rightwrapper -->
  <div class="form-leftwrapper section-box" [ngClass]="{'open-descwrapper': deviceType == 2}">
    <div class="navtab-wrap mb-24">
      <!-- <button type="button" class="tablink-btn active">All</button>
      <button type="button" class="tablink-btn">Refund List</button> -->
      <mat-button-toggle-group [value]="selectedListType" (change)="selectList($event.value)"
        class="w-full button-togglewrapper">
        <mat-button-toggle value="1">{{'all' | translate}}</mat-button-toggle>
        <mat-button-toggle value="2">{{'refund_list' | translate}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx">
      <div class="w-full relative input-rightspace">
        <input type="search" class="form-control" matInput (keyup)="serachTransactions()"
          placeholder="{{'search_invoice' | translate}}" [(ngModel)]="serachRefund" />
        <mat-icon class="ic-input cursor-pointer">search</mat-icon>
      </div>
    </mat-form-field>

    <div class="refund-listbox custom-scroll" infinite-scroll (scrolled)="refundListScrollDown()"
      [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">

      <div *ngFor="let item of refundListData" class="list-box cursor-pointer flex flex-align-center gap-16px"
        [ngClass]="{'active': item.isSelected}">
        <div class="block leftlist-info" (click)="selectTransaction(item.id,true)">
          <span class="text-grey-medium fs-14 fw-medium d-block mb-10">{{item.transaction_date_time |
            dateTime24Hr:true}}</span>
          <span class="text-grey-light refund-code mb-4 fs-14 d-block">#{{item.order_id}}</span>
          <p class="text-truncate refund-product">{{item.customer_first_name}} {{item.customer_last_name}}</p>
        </div>
        <h3 class="mb-0 text-grey-light fw-medium price-text flex-shrink-0">${{item.total_amount.toFixed(2)}}</h3>
      </div>

      <!-- <div class="list-box cursor-pointer flex flex-align-center gap-16px">
        <div class="block leftlist-info">
          <span class="text-grey-medium fs-14 fw-medium d-block mb-10">7/18/2024</span>
          <span class="text-grey-light refund-code mb-4 fs-14 d-block">#12349</span>
          <p class="text-truncate refund-product">Leo Torff</p>
        </div>
        <h3 class="mb-0 text-grey-light fw-medium price-text flex-shrink-0">$1225.24</h3>
      </div> -->


      <div class="no-records flex flex-align-center flex-justify-center flex-column" *ngIf="totalRecord == 0">
        <div class="text-primary fw-medium">{{'no_records' | translate}}</div>
      </div>

    </div>
  </div>

  <!-- Starts Right form Box -->
  <div class="form-rightwrapper block" [ngClass]="{'active': deviceType == 2}">
    <ng-container *ngIf="refundDetailData">
      <a mat-flat-button class="btn-outline-secondary backbtn show mb-10" (click)="backToList()">
        <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
            stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        {{'back' | translate}}</a>
      <div class="table-sectionbox mb-24 section-box boxblock order-productbox">
        <div
          class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box table-sectionbx header-card">
          <h1 class="mb-0 text-grey-light">#{{refundDetailData?.order_id}}</h1>
        </div>
        <div class="table-responsive custom-scroll table-wrapper">
          <table mat-table [dataSource]="dataSourceRefundDetail" class="mat-elevation-z8">

            <!-- ITEMS Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef class="item-namecol">{{'invoice_items' | translate}}</th>
              <td mat-cell *matCellDef="let element" class="nowrap">{{element.name}}</td>
            </ng-container>

            <!-- PRICE Column -->
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>{{'invoice_price' | translate}}</th>
              <td mat-cell *matCellDef="let element">${{element.price}} </td>
            </ng-container>

            <!-- QTY Column -->
            <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef class="action-col">{{'invoice_quantity' | translate}}.</th>
              <td mat-cell *matCellDef="let element">{{selectedListType == '1'
                ?element.total_quantity:element.quantity}}
              </td>
            </ng-container>

            <ng-container matColumnDef="total_amount">
              <th mat-header-cell *matHeaderCellDef class="text-center">{{'transaction_total' | translate}}</th>
              <td mat-cell *matCellDef="let element" class="text-center">${{ (element.price *
                element.quantity).toFixed(2)
                }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>

      <div class="section-box mb-24 boxblock ordertotal-box">
        <div class="card-box card-header ">
          <h3 class="text-grey-light mb-0">{{'order_total' | translate}}</h3>
        </div>
        <div class="totalcount-wrapper card-box block">
          <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between"
            *ngIf="selectedListType=='1'">
            <span class="fw-medium">{{'discount' | translate}}</span>
            <span>${{refundDetailData?.discount? refundDetailData?.discount.toFixed(2):'00.00'}}</span>
          </div>
          <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between"
            *ngIf="selectedListType=='1'">
            <span class="fw-medium">{{'additional_amount' | translate}}</span>
            <span>${{refundDetailData?.additional_amount?
              refundDetailData?.additional_amount.toFixed(2):'00.00'}}</span>
          </div>
          <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between">
            <span class="fw-medium">{{'subtotal' | translate}}</span>
            <span>${{refundDetailData?.sub_total? refundDetailData?.sub_total.toFixed(2):'00.00'}}</span>
          </div>
          <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between"
            *ngIf="selectedListType=='1'">
            <span class="fw-medium">{{'tax' | translate}} ({{taxPer}}%)</span>
            <span>${{refundDetailData?.tax? refundDetailData?.tax.toFixed(2):'00.00'}}</span>
          </div>
          <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between"
            *ngIf="selectedListType=='1'">
            <span class="fw-medium">{{'credit_card_processing_fee' | translate}}</span>
            <span>${{refundDetailData?.processing_fee? refundDetailData?.processing_fee.toFixed(2):'00.00'}}</span>
          </div>
          <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between"
            *ngIf="selectedListType=='1'">
            <span class="fw-medium">{{'shipping_fee' | translate}}</span>
            <span>${{refundDetailData?.shipping_fee ? refundDetailData?.shipping_fee.toFixed(2):'00.00'}}</span>
          </div>
          <div class="text-grey-medium fs-16 ordered-totalbx mb-20 flex flex-justify-between"
            *ngIf="selectedListType=='1'">
            <span class="fw-medium">{{'late_fee' | translate}}</span>
            <span>${{refundDetailData?.late_fee ? refundDetailData?.late_fee.toFixed(2):'00.00'}}</span>
          </div>
        </div>
        <div class="text-grey-medium fs-16 ordered-totalbx totalcount-bx flex flex-justify-between">
          <span class="h3 text-primary fw-bold ">{{'total' | translate}}</span>
          <span class="text-primary h3 fw-bold">${{refundDetailData?.total_amount.toFixed(2)}}</span>
        </div>
      </div>


      <div class="btn-boxwrap flex flex-wrap">
        <button *ngIf="selectedListType == '1' && refundPermission?.add == 1" mat-flat-button class="btn-primary btn-lg"
          (click)="refundRequest(refundDetailData?.order_id)">
          {{'refund_request' | translate}}
        </button>
        <button *ngIf="selectedListType == '2'" mat-flat-button class="btn-outline-primary btn-lg"
          (click)="sendReceiptAgain(refundDetailData?.order_id)">
          {{'send_reciept_again' | translate}}
        </button>
      </div>
    </ng-container>

    <!-- <div *ngIf="!refundDetailData"
      class="no-records flex flex-align-center flex-justify-center flex-column boxright-record">
      <img src="assets/images/empty-state.png" alt="No Record Found" class="mb-24" />
      <div class="text-primary fw-medium">{{'no_details_available' | translate}}</div>
    </div> -->

    <ng-container *ngIf="!refundDetailData">
      <app-no-records-available></app-no-records-available>
    </ng-container>

  </div>


</div>