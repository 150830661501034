import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMessage } from 'src/app/model/interfaces/req.res.interface';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {

  private httpClient = inject(HttpClient);

  //#region Purchase Order API's

  // Get Purchase Order List
  getPurchaseOrderList(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('page', data.page);
    params = params.append('per_page', data.per_page);
    data.search ? (params = params.append('search', data.search)) : '';
    data.from_date ? (params = params.append('from_date', data.from_date)) : '';
    data.to_date ? (params = params.append('to_date', data.to_date)) : '';
    data.item_id ? (params = params.append('item_id', data.item_id)) : '';
    data.user_id ? (params = params.append('user_id', data.user_id)) : '';
    data.user_business_location_id ? (params = params.append('user_business_location_id', data.user_business_location_id)) : '';
    return this.httpClient.get<ResponseMessage>('purchase-order-list', { params: params });
  }


  // Get Purchase Order Detail
  getPurchaseOrderDetail(id: number): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>(`get-purchase-order-details/${id}`);
  }

  // ------- Filter--------------

  // Get Product List filter
  getProducts(data: any): Observable<ResponseMessage> {
    let params = new HttpParams();
    params = params.append('business_location_id', data.business_location_id);
    return this.httpClient.get<ResponseMessage>('get-items', { params: params });
  }

  // Get Vendor List
  getVendors(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('get-business-branch-user');
  }


  // Get Branch List
  getBranches(): Observable<ResponseMessage> {
    return this.httpClient.get<ResponseMessage>('view-branch');
  }

  //#endregion Purchase Order API's
}
