<div *ngIf="moduleType == 1" class="flex flex-wrap gap-20px flex-align-center section-box table-sectionbx mb-24">
  <h1 class="mb-0 mobile-full h3 mobile-full me-auto">{{'table_management' | translate}}</h1>
  <!-- <div class="flex gap-16px flex-align-center mobile-full filter-optionswrap flex-wrap">
    <div class="flex flex-wrap header-radiobx flex-align-center" *ngIf="!isEditModeOn">
      <label id="example-radio-group-label" class="text-grey-light d-block option-label fw-medium">
        {{'select_type_of_table' | translate}}
      </label>
      <mat-radio-group aria-label="Select an option" class="vendor-radiocheck" [(ngModel)]="selectTableType"
        (ngModelChange)="selectTableTypeFilter($event)">
        <mat-radio-button *ngFor="let type of typeOfReservation;" [value]="type.id">
          {{type.translateLabel | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div> -->

  <div class="filter-wrapper flex-align-center flex-grow-1 gap-16px mt-md-15 text-end filter-section flex-wrap">

    <ng-container *ngIf="!isEditModeOn && totalRecords > 0">
      <label id="example-radio-group-label" class="text-grey-light option-label fw-medium me-15">
        {{'select_type_of_table' | translate}}
      </label>
      <mat-radio-group aria-label="Select an option" class="vendor-radiocheck me-md-15" [(ngModel)]="selectTableType"
        (ngModelChange)="selectTableTypeFilter($event)">
        <mat-radio-button *ngFor="let type of typeOfReservation;" [value]="type.id">
          {{type.translateLabel | translate}}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>

    <button mat-flat-button class="btn-outline-primary btn-lg btn-half me-15"
      [disabled]="totalRecords == 0 && !isEditModeOn" (click)="startEditMode()">
      {{ isEditModeOn ? ('update' | translate) : ('edit' | translate) + ' ' + ('table' | translate) }}
    </button>
    <button mat-flat-button class="btn-lg btn-half" (click)="createEditTable('Add')" [disabled]="isEditModeOn">
      {{ 'add' | translate }} {{ 'table' | translate }}
    </button>
  </div>

</div>

<ng-container *ngIf="totalRecords > 0">
  <div class=" bg-grey-lighter radius-12 pb-30 text-center">
    <div class="mb-20" #tableScrollContainer class="custom-scroll table-box-wrapper" cdkDropListGroup>
      <div class="table-rowsbox example-container">
        <div *ngFor="let table of tableList; let i = index" cdkDropList [cdkDropListData]="i" class="table-boxwrap"
          [ngClass]="table | tableDynamicClasses:'table':selectTableType:selectedTableId" (click)="selectTable(table)">
          <div cdkDrag [cdkDragDisabled]="!isEditModeOn && !!tableScrollContainer" [cdkDragData]="i" (cdkDragMoved)="onDragMove($event)"
            (cdkDragEntered)="dragEntered($event)" (cdkDragEnded)="dragEnded($event)" class="table-box cursor-move"
            [ngClass]="{'cursor-move': isEditModeOn}" cdkDragHandle>
            <span class="reserved-table text-truncate"
              [ngClass]="table | tableDynamicClasses:'text'"><span>{{table.name}}</span></span>
            <div *ngIf="isEditModeOn">
              <div class="example-handle text-grey-medium drag-pointer">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14 7L11.606 9.394C11.3825 9.6175 11 9.459 11 9.143V7.75H7.75V11H9.143C9.4595 11 9.6175 11.3825 9.394 11.606L7 14L4.606 11.606C4.3825 11.3825 4.541 11 4.857 11H6.25V7.75H3V9.143C3 9.4595 2.6175 9.6175 2.394 9.394L0 7L2.394 4.606C2.6175 4.3825 3 4.541 3 4.857V6.25H6.25V3H4.857C4.5405 3 4.3825 2.6175 4.606 2.394L7 0L9.394 2.394C9.6175 2.6175 9.459 3 9.143 3H7.75V6.25H11V4.857C11 4.5405 11.3825 4.3825 11.606 4.606L14 7Z"
                    fill="#989898" />
                </svg>
              </div>
              <div class="btn-edit" (click)="createEditTable('Edit',table.id)">
                <span class="icon-edit-pencil"></span>
              </div>
              <div class="btn-delete" (click)="deleteTableDialog(table.id)">
                <span class="icon-ic-trash"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-status flex-wrap fs-12">
      <span class="status-info flex flex-align-center available-text">
        {{'available' | translate}} : {{tableStatusCount.available}}
      </span>
      <span class="status-info flex flex-align-center notavailable-text m-18">
        {{'reserved' | translate}} : {{tableStatusCount.reserved}}
      </span>
      <span class="status-info flex flex-align-center selected-text">{{'selected' | translate}}</span>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="totalRecords == 0 && !tableProgressLoader">
  <app-no-records-available></app-no-records-available>
</ng-container>



<!-- Starts HTML for detail Order -->
 <ng-container *ngIf="false">
<div class="detail-ordermenu flex flex-column " [class.active]="isClassAdded">
  <div class="flex flex-justify-between mb-20 flex-shrink-0">
    <div class="flex gap-8px flex-grow-1 flex-align-center flex-wrap">
      <h5 class="mb-0 fw-bold">{{'detail_order' | translate}}</h5>
      <span class="badge badge-success order-status">{{'ready_to_serve' | translate}}</span>
    </div>
    <!-- Close Drawer -->
    <button class="flex-shrink-0 close-btn" mat-icon-button (click)="toggleClass()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd"
          d="M7.29688 -0.015625C7.75522 -0.015625 8.21353 -0.015625 8.67188 -0.015625C11.2075 0.259457 13.2022 1.43654 14.6562 3.51562C15.4006 4.66028 15.8433 5.91028 15.9844 7.26562C15.9844 7.74478 15.9844 8.22397 15.9844 8.70312C15.7001 11.2258 14.523 13.2102 12.4531 14.6562C11.2993 15.4071 10.0389 15.8498 8.67188 15.9844C8.21353 15.9844 7.75522 15.9844 7.29688 15.9844C4.80813 15.7118 2.83416 14.566 1.375 12.5469C0.592031 11.3802 0.128491 10.0989 -0.015625 8.70312C-0.015625 8.22397 -0.015625 7.74478 -0.015625 7.26562C0.268627 4.74291 1.44571 2.75852 3.51562 1.3125C4.66941 0.561612 5.92981 0.118903 7.29688 -0.015625ZM4.98438 4.51562C5.221 4.47762 5.42934 4.53491 5.60938 4.6875C6.39625 5.47959 7.18794 6.26606 7.98438 7.04688C8.81294 6.2235 9.64628 5.40581 10.4844 4.59375C10.8816 4.42409 11.1889 4.52306 11.4062 4.89062C11.475 5.093 11.4645 5.29091 11.375 5.48438C10.5629 6.32247 9.74525 7.15581 8.92188 7.98438C9.74525 8.81294 10.5629 9.64628 11.375 10.4844C11.5447 10.8816 11.4457 11.1889 11.0781 11.4062C10.8321 11.4891 10.6029 11.4578 10.3906 11.3125C9.59331 10.51 8.79125 9.71309 7.98438 8.92188C7.15581 9.74525 6.32247 10.5629 5.48438 11.375C5.08716 11.5447 4.77987 11.4457 4.5625 11.0781C4.49378 10.8757 4.50422 10.6778 4.59375 10.4844C5.40581 9.64628 6.2235 8.81294 7.04688 7.98438C6.2235 7.15581 5.40581 6.32247 4.59375 5.48438C4.41631 5.03159 4.5465 4.70869 4.98438 4.51562Z"
          fill="#03A7F6" />
      </svg>
    </button>
  </div>
  <div class="order-scroll flex-grow-1 custom-scroll">
    <div class="order-infobox flex flex-wrap mb-28 block">
      <div class="gap-12px w-full fs-12 block mb-12">
        <span class="text-block mb-4">{{'recipent' | translate}}</span>
        <h5 class="text-grey-medium fs-14 fw-bold">Akhmad Maariz</h5>
      </div>
      <div class="grid-2 w-full">
        <div class="gap-12px w-full fs-12 block mb-12 half-detailbx">
          <span class="text-block mb-4">{{'table_management_transaction_id' | translate}}</span>
          <h5 class="text-grey-medium fs-14 fw-bold">#231128001</h5>
        </div>
        <div class="gap-12px w-full fs-12 block mb-12 half-detailbx">
          <span class="text-block mb-4">{{'table_number' | translate}}</span>
          <h5 class="text-grey-medium fs-14 fw-bold">19</h5>
        </div>
      </div>
      <div class="gap-12px w-full fs-12 block mb-12">
        <span class="text-block mb-4">{{'cooking_instruction' | translate}}</span>
        <h5 class="text-grey-medium fs-14 fw-normal">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h5>
      </div>
    </div>

    <div class="product-list flex flex-column gap-20px">
      <div class="ordered-product gap-16px flex">
        <div class="ordered-productimg flex-shrink-0">
          <img src="assets/images/product/img1.jpg" alt="American Style Burger" class="img-fit" />
        </div>
        <div class="ordered-detailbox flex flex-column flex-justify-between flex-grow-1">
          <h5 class="text-white product-name mb-4">American Style Burger</h5>
          <div>
            <span class="text-primary mb-6 d-block fs-13">2 items</span>
            <div class="flex flex-justify-between flex-wrap flex-align-center fw-medium">
              <span class="fs-12 text-grey-medium">Total Price :</span>
              <span class="text-primary fs-16">$27.09</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ordered-product gap-16px flex">
        <div class="ordered-productimg flex-shrink-0">
          <img src="https://images.pexels.com/photos/6089662/pexels-photo-6089662.jpeg?auto=compress&cs=tinysrgb&w=600"
            alt="Mocktail" class="img-fit" />
        </div>
        <div class="ordered-detailbox flex flex-column flex-justify-between flex-grow-1">
          <h5 class="text-white product-name mb-4">Mocktail</h5>
          <div>
            <span class="text-primary mb-6 d-block fs-13">2 items</span>
            <div class="flex flex-justify-between flex-wrap flex-align-center fw-medium">
              <span class="fs-12 text-grey-medium">Total Price :</span>
              <span class="text-primary fs-16">$55.17</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ordered-product gap-16px flex">
        <div class="ordered-productimg flex-shrink-0">
          <img src="assets/images/product/img1.jpg" alt="Burger French Fries Combo" class="img-fit" />
        </div>
        <div class="ordered-detailbox flex flex-column flex-justify-between flex-grow-1">
          <h5 class="text-white product-name mb-4">Burger French Fries Combo</h5>
          <div>
            <span class="text-primary mb-6 d-block fs-13">2 items</span>
            <div class="flex flex-justify-between flex-wrap flex-align-center fw-medium">
              <span class="fs-12 text-grey-medium">Total Price :</span>
              <span class="text-primary fs-16">$55.17</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="order-totalbottom flex-shrink-0 block">
    <div class="total-paymentbox flex flex-justify-between gap-8px mb-20 flex-align-center">
      <span class="fs-14 fw-bold">{{'total_payment' | translate}}</span>
      <span class="fs-18 text-primary fw-bold">$55.17</span>
    </div>
    <button mat-flat-button class="btn-outline-primary w-full">{{'create_new_order' | translate}}</button>
  </div>

</div>
<div class="overlay-drawer" (click)="toggleClass()"></div>
</ng-container>
<!-- Ends HTML for detail Order -->