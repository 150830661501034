<!-- Starts Header -->
<div
  class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">{{"view_item" | translate}}</h1>
  <ng-container *ngIf="isOpenDialog">
    <button class="flex-shrink-0 close-btn" mat-icon-button (click)="closeDialog()">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.992" fill-rule="evenodd" clip-rule="evenodd"
          d="M7.29688 -0.015625C7.75522 -0.015625 8.21353 -0.015625 8.67188 -0.015625C11.2075 0.259457 13.2022 1.43654 14.6562 3.51562C15.4006 4.66028 15.8433 5.91028 15.9844 7.26562C15.9844 7.74478 15.9844 8.22397 15.9844 8.70312C15.7001 11.2258 14.523 13.2102 12.4531 14.6562C11.2993 15.4071 10.0389 15.8498 8.67188 15.9844C8.21353 15.9844 7.75522 15.9844 7.29688 15.9844C4.80813 15.7118 2.83416 14.566 1.375 12.5469C0.592031 11.3802 0.128491 10.0989 -0.015625 8.70312C-0.015625 8.22397 -0.015625 7.74478 -0.015625 7.26562C0.268627 4.74291 1.44571 2.75852 3.51562 1.3125C4.66941 0.561612 5.92981 0.118903 7.29688 -0.015625ZM4.98438 4.51562C5.221 4.47762 5.42934 4.53491 5.60938 4.6875C6.39625 5.47959 7.18794 6.26606 7.98438 7.04688C8.81294 6.2235 9.64628 5.40581 10.4844 4.59375C10.8816 4.42409 11.1889 4.52306 11.4062 4.89062C11.475 5.093 11.4645 5.29091 11.375 5.48438C10.5629 6.32247 9.74525 7.15581 8.92188 7.98438C9.74525 8.81294 10.5629 9.64628 11.375 10.4844C11.5447 10.8816 11.4457 11.1889 11.0781 11.4062C10.8321 11.4891 10.6029 11.4578 10.3906 11.3125C9.59331 10.51 8.79125 9.71309 7.98438 8.92188C7.15581 9.74525 6.32247 10.5629 5.48438 11.375C5.08716 11.5447 4.77987 11.4457 4.5625 11.0781C4.49378 10.8757 4.50422 10.6778 4.59375 10.4844C5.40581 9.64628 6.2235 8.81294 7.04688 7.98438C6.2235 7.15581 5.40581 6.32247 4.59375 5.48438C4.41631 5.03159 4.5465 4.70869 4.98438 4.51562Z"
          fill="#03A7F6" />
      </svg>
    </button>
  </ng-container>
  <ng-container *ngIf="!isOpenDialog">
    <a mat-flat-button class="btn-outline-secondary backbtn" (click)="backToProductList()">
      <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
          stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      {{"back" | translate}}
    </a>
  </ng-container>

</div>

<div class="section-box product-infowrapper card-boxblock custom-scroll">
  <div class="fs-14 text-white mb-20" *ngIf="isOpenDialog">
    <span class="text-grey-medium mb-6 d-block">{{ "item_for" | translate}}</span>
    <div>{{productDetail?.item_for == 1 ? ( 'restaurant'| translate): ( 'bar'| translate)}}</div>
  </div>
  <div class="fs-14 text-white mb-20">
    <span class="text-grey-medium mb-6 d-block">{{"item_name" | translate}}</span>
    <div>{{productDetail?.name}}</div>
  </div>
  <div class="fs-14 text-white mb-20">
    <span class="text-grey-medium mb-6 d-block">{{"item_description" | translate}}</span>
    <div>{{productDetail?.description ? productDetail?.description :'-'}}</div>
  </div>
  <div class="fs-14 text-white mb-20">
    <span class="text-grey-medium mb-6 d-block">{{'image' |translate}}</span>
    <div class="d-inline-block gap-8px flex-wrap">
      <ng-container *ngFor="let imageData of productDetail?.item_image;">
        <span class="overflow-hidden food-smallimg d-inline-block innergap">
          <img [src]="imageData.url" [alt]="imageData?.image_name" class="img-fit" />
        </span>
      </ng-container>
    </div>
  </div>
  <div class="info-rowbx">
    <div class="fs-14 text-white" *ngIf="isOpenDialog">
      <span class="text-grey-medium mb-6 d-block">{{"branch_location" | translate}}</span>
      <div>{{productDetail?.user_business_location.name}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"price" | translate}}</span>
      <div>${{productDetail?.price}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"item_unit" | translate}}</span>
      <div>{{productDetail?.unit ?(productDetail?.unit | itemUnits):'-'}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"available_stock" | translate}}</span>
      <div>
        {{ productDetail?.available_stock !== '' ? productDetail?.available_stock : '-'}}
      </div>
    </div>
    <div class="fs-14 text-white" *ngIf="isOpenDialog">
      <span class="text-grey-medium mb-6 d-block">{{"low_stock_alert" | translate}}</span>
      <div>{{productDetail?.low_stock_alert ? productDetail?.low_stock_alert : '-'}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"menu_list" | translate}}</span>
      <div>{{productDetail?.food_menu_title ? productDetail?.food_menu_title :'-'}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"category" | translate}}</span>
      <div>{{productDetail?.category_name}}</div>
    </div>
    <div class="fs-14 text-white">
      <span class="text-grey-medium mb-6 d-block">{{"available_to_sell" | translate}}</span>
      <div>{{productDetail?.available_to_sell == 1?('yes'|translate):('no'|translate)}}</div>
    </div>
  </div>
</div>