<div class="section-box mb-24 flex flex-wrap flex-align-center gap-16px header-bx mb-25">
  <h1 class="text-white mb-sm-0 "> {{ title | translate }}</h1>
  <div class="flex filter-wrapper flex-align-center flex-grow-1 flex-justify-end filter-section flex-wrap">
    <mat-form-field subscriptSizing="dynamic" appearance="fill" class="select-category select-dark nolabel">
      <mat-select [(ngModel)]="selectCategory" placeholder="{{ 'select_category' | translate }}">
        <mat-option value="" (click)="selectSearch(1, 0)">
          {{ "select_category" | translate }}
        </mat-option>
        <ng-container *ngFor="let category of categoryListData">
          <mat-option (click)="selectSearch(1, category.category_id)" [value]="category.category_id">{{ category.name
            }}</mat-option>
        </ng-container>

      </mat-select>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="fill" class="select-branchlocation select-dark nolabel">
      <mat-select placeholder="{{ 'branch_location' | translate }}" [(ngModel)]="selectBranch">
        <mat-option value="" (click)="selectSearch(2, 0)">
          {{ "branch_location" | translate }}
        </mat-option>
        <ng-container *ngFor="let branch of branchListData">
          <mat-option (click)="selectSearch(2, branch.business_location_id)" [value]="branch.business_location_id">{{
            branch.name }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="fill" class="select-inventorystatus select-dark nolabel">
      <mat-select [(ngModel)]="selectedInventory" placeholder="{{ 'invetory_status' | translate }}">
        <mat-option value="" (click)="selectSearch(3, 0)">
          {{ "invetory_status" | translate }}
        </mat-option>
        <ng-container *ngFor="let status of inventoryStatus;">
          <mat-option (click)="selectSearch(3, status.id)" [value]="status.id">{{
            status.name
            }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button class="btn-outline-primary btn-lg" [disabled]="isResetButtonDisabled"
      (click)="resetFilter()">
      {{ "reset" | translate }}
    </button>
  </div>
</div>

<div>
  <div class="heading-box flex-justify-between flex-wrap gap-8px mb-16 flex-align-center section-box">
    <h1 class="mb-0 mobile-full h3"> {{ "items" | translate }}</h1>
    <div class="flex gap-16px flex-grow-1 flex-justify-end flex-wrap searchbox-child inputwidth-auto">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="small-input search-inputbx input-lg">
        <div class="w-full relative input-rightspace">
          <input type="search" class="form-control" matInput (keyup)="searchInventorys()" [(ngModel)]="searchInventory"
            placeholder="{{ 'search_item' | translate }}" />
          <mat-icon class="ic-input cursor-pointer">
            {{ "serach" | translate }}</mat-icon>
        </div>
      </mat-form-field>

      <ng-container *ngIf="inventoryPermission?.add == 1">
        <button mat-flat-button class="flex-shrink-0 btn-lg" (click)="addEditItem('Add')">
          {{ "add_item" | translate }}
        </button>
        <button mat-flat-button class="flex-shrink-0 btn-lg" (click)="createMenu()">
          {{ "create_menu" | translate }}
        </button>
      </ng-container>

    </div>
  </div>
  <div class="table-responsive custom-scroll table-wrapper" infinite-scroll (scrolled)="inventoryListScrollDown()"
    [immediateCheck]="true" [scrollWindow]="false" [infiniteScrollDistance]="20" [infiniteScrollThrottle]="0">
    <table mat-table [dataSource]="dataSourceInventory" class="mat-elevation-z8">

      <!-- ITEM NAME Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="item-namecol"> {{ "inventory_columns.item_name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="nowrap">
          <div class="item-infobox">
            <img [src]="element.url" [alt]="element?.image_name" class="img-fit item-image" />
            <span class="text-truncate max-w-300" [matTooltip]="element.name">{{ element.name }}</span>
          </div>
        </td>
      </ng-container>

      <!-- CATEGORY Column -->
      <ng-container matColumnDef="category_name">
        <th mat-header-cell *matHeaderCellDef> {{ "inventory_columns.category" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.category_name}} </td>
      </ng-container>

      <!-- BRANCH LOCATION Column -->
      <ng-container matColumnDef="user_business_location">
        <th mat-header-cell *matHeaderCellDef> {{ "inventory_columns.branch_location" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.user_business_location.name}} </td>
      </ng-container>

      <!-- STOCK Column -->
      <ng-container matColumnDef="available_stock">
        <th mat-header-cell *matHeaderCellDef> {{ "inventory_columns.stock" | translate }} </th>
        <td mat-cell *matCellDef="let element">
          {{ element.available_stock !== '' ? element.available_stock : '-'}}
        </td>
      </ng-container>

      <!-- AVAILABLE TO SELL Column -->
      <ng-container matColumnDef="available_to_sell">
        <th mat-header-cell *matHeaderCellDef> {{ "inventory_columns.available_to_sell" | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <div [ngClass]="{'text-grey-dark not-availabletxt': element?.available_to_sell != 1}">
            {{ element?.available_to_sell == 1 ? ('available' | translate) : ('not_available' | translate) }}
          </div>
        </td>
      </ng-container>

      <!-- PRICE Column -->
      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>{{ "inventory_columns.price" | translate }}</th>
        <td mat-cell *matCellDef="let element">${{element.price.toFixed(2)}} </td>
      </ng-container>

      <!-- ACTION Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="action-col text-center">{{ "inventory_columns.action" | translate
          }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex table-actionbtn flex-justify-center">
            <button mat-icon-button aria-label="View" (click)="viewInventory(element.id,element)"
              [matTooltip]="('view' | translate)">
              <span class="icon-eye"></span></button>
            <button mat-icon-button aria-label="Edit" matTooltip="{{ 'edit' | translate }}"
              *ngIf="inventoryPermission?.update == 1" (click)="addEditItem('Edit',element.id)">
              <span class="icon-development"> </span>
            </button>
            <button mat-icon-button aria-label="Edit" matTooltip="{{ 'delete' | translate }}"
              *ngIf="inventoryPermission?.delete == 1" (click)="deleteProductDialog(element?.id)">
              <span class="icon-ic-trash"></span>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- <div *ngIf="totalRecord == 0 && !itemProgressLoader"
      class="no-records flex flex-align-center flex-justify-center flex-column">
      <img src="assets/images/empty-state.png" alt="No Record Found" class="mb-24" />
      <div class="text-primary fw-medium">{{'no_records' | translate}}</div>
    </div> -->

    <ng-container *ngIf="totalRecord == 0 && !itemProgressLoader">
      <app-no-records-available></app-no-records-available>
    </ng-container>
  </div>

</div>