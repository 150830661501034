import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImageLibraryComponent } from './components/image-library/image-library.component';
import { ManageCategoryComponent } from './components/categories/manage-category/manage-category.component';
import { AddCategoryComponent } from './components/categories/add-category/add-category.component';
import { SettingsService } from './services/settings.service';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './components/profile/profile.component';
import { SubVendorManagementComponent } from './components/sub-vendor-management/sub-vendor-management.component';
import { TranslateModule } from '@ngx-translate/core';
import { UnderDevelopmentComponent } from 'src/app/shared/components/under-development/under-development.component';
import { ConstantService } from 'src/app/shared/services/constant.service';

interface MenuItem {
  type: number;
  label: string;
  icon: string;
  altText: string;
  labelTransalte: string;
}
@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    RouterModule,
    ImageLibraryComponent,
    ManageCategoryComponent,
    AddCategoryComponent,
    ProfileComponent,
    TranslateModule,
    // SubVendorManagementComponent,
    UnderDevelopmentComponent,
    CommonModule
  ],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public settingService = inject(SettingsService);
  private constantService = inject(ConstantService);
  menuType: number = 3; // Default or initial menu type
  menuItems: MenuItem[] = [
    { type: 3, label: 'Profile', labelTransalte: 'profile', icon: 'assets/images/food-image/profile.png', altText: 'Profile Icon' },
    { type: 4, label: 'Sub-Vendor Management', labelTransalte: 'sub_vendor_management', icon: 'assets/images/food-image/sub-vendor.png', altText: 'Sub Vendor' },
    { type: 1, label: 'Image Library', labelTransalte: 'image_library', icon: 'assets/images/food-image/camera.png', altText: 'Image Library Icon' },
    { type: 2, label: 'Manage Category', labelTransalte: 'manage_category', icon: 'assets/images/food-image/category.png', altText: 'Manage Category Icon' },
    { type: 5, label: 'Manage Notification Preference', labelTransalte: 'manage_notification_preference', icon: 'assets/images/food-image/bell.png', altText: 'Manage Notification' },
  ];
  isShowList: any = true;
  constructor() {

    this.settingService.isShowCategory.subscribe(res => {
      if (res) {
        this.isShowList = res.displayList;
      }
    });

  }

  ngOnInit() {
    const categoryPermission = this.constantService.getModuleWiseRole('manage_category');
    if (categoryPermission) {
      if (categoryPermission?.view === 0) {
        this.menuItems = this.menuItems.filter(item => item.type !== 2);
      }
      const userDetail = this.constantService.getUserDetail();
      if (userDetail.user_type != 2) {
        this.menuItems = this.menuItems.filter(item => item.type !== 4);
      }
    }
  }

  selectSettingMenu(type: number) {
    if (type) {
      this.menuType = type;
      this.isShowList = type == 2;
    }
  }

}
