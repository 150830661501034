import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TableListComponent } from "../../table-management/table-list/table-list.component";

@Component({
  selector: 'app-waiting-tables',
  standalone: true,
  imports: [
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    TranslateModule,
    TableListComponent
  ],
  templateUrl: './waiting-tables.component.html',
  styleUrls: ['./waiting-tables.component.scss']
})
export class WaitingTablesComponent {
  private router = inject(Router);

  backToWaitingList() {
    this.router.navigate(['/waiting-list'])
  }
}
