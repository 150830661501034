import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective, InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { SettingsService } from '../../../services/settings.service';
import { ResponseMessage } from '../../../../../model/interfaces/req.res.interface';
import { ConfirmationComponent } from '../../../../../shared/components/dialog/confirmation/confirmation.component';
import { ConstantService } from '../../../../../shared/services/constant.service';
import { CommonModule } from '@angular/common';
import { NoRecordsAvailableComponent } from 'src/app/shared/components/common/no-records-available/no-records-available.component';

@Component({
  selector: 'app-manage-category',
  standalone: true,
  imports: [
    MatTableModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    InfiniteScrollModule,
    TranslateModule,
    CommonModule,
    NoRecordsAvailableComponent
  ],
  templateUrl: './manage-category.component.html',
  styleUrls: ['./manage-category.component.scss']
})

export class ManageCategoryComponent {

  displayedColumns: string[] = ['category_id', 'name', 'image', 'action'];
  dataSourceCategories: any;
  serachCategory: String = '';
  categoryListData: any;
  totalRecord: number = 0;
  subscribeListAPI!: Subscription;
  subscribeDeleteAPI!: Subscription;
  public constantService = inject(ConstantService);
  private settingService = inject(SettingsService);
  private toastr = inject(ToastrService);
  private dialog = inject(MatDialog);
  categorySearchPayload = {
    searchValue: '',
  };

  pageNumber: number = 1;
  perPages: number = 25;
  categoryProgressLoader = false;
  categoryPermission: any;
  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.categoryProgressLoader = state;
    });
  }

  ngOnInit() {
    const permission = this.constantService.getModuleWiseRole('manage_category');

    if (permission?.update === 0 && permission?.delete === 0) {
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'action');
    }
    this.categoryPermission = permission;

    this.constantService.progressLoader.next(true);
    this.getCategoryList();
  }

  //#region Category List
  getCategoryList() {
    this.pageNumber = 1;
    let categoryPayload = {
      page: this.pageNumber,
      per_page: this.perPages,
      search_text: this.categorySearchPayload?.searchValue,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.settingService.getCategoryList(categoryPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.categoryListData = res.data.data;
          this.totalRecord = res.data.pagination.total;
          this.dataSourceCategories = new MatTableDataSource(res.data.data);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  categoryListScrollDown() {
    this.pageNumber += this.pageNumber;
    let categoryPayload = {
      page: this.pageNumber,
      per_page: this.perPages
    }
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.settingService.getCategoryList(categoryPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.totalRecord = res.data.pagination.total;
          this.categoryListData = [...this.categoryListData, ...res.data.data];
          this.dataSourceCategories = new MatTableDataSource(this.categoryListData);
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Category List

  //#region Search Category
  serachCategories() {

    this.serachCategory = this.serachCategory.trimStart();
    const searchText = this.serachCategory.trim();
    if (searchText !== this.categorySearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.categorySearchPayload.searchValue = searchText;
        this.getCategoryList();
      } else if (!searchText && this.categorySearchPayload.searchValue !== '') {
        this.categorySearchPayload.searchValue = '';
        this.getCategoryList();
      }
    }
  }

  //#endregion Search Category

  //#region Add,Edit and Remove category

  //Add
  addCategory() {
    let obj = {
      displayList: false,
      action: 'Add'
    }
    this.settingService.isShowCategory.next(obj);
  }

  //Edit
  editCategory(data: any) {
    let obj = {
      displayList: false,
      action: 'Edit',
      categoryData: data
    }
    this.settingService.isShowCategory.next(obj);
  }

  //Remove
  deleteCategoryDialog(id: number, srNumber: number) {
    let data = {
      type: 1,
      title: 'delete',
      message: 'delete_message', // Delete Category messgae
      buttonName: { cancel: 'cancel', yes: 'yes', },
      // id: id,
      id: srNumber
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      panelClass: ['modal-wrapper'],
      disableClose: true,
      autoFocus: false,
      data
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        this.deleteCategory(id);
      }
    })
  }

  deleteCategory(id: number) {
    this.constantService.progressLoader.next(true);
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
    this.subscribeDeleteAPI = this.settingService.deleteCategory(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          this.getCategoryList();
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  //#endregion  Add,Edit and Remove category

  //#region Destroy
  ngOnDestroy(): void {
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    if (this.subscribeDeleteAPI) this.subscribeDeleteAPI.unsubscribe();
  }
  //#endregion Destroy
}
