import { Component, inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { monthList, paymentMethodOptions } from '../../../../model/const/common.const';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, finalize, Subscription, takeUntil, throwError } from 'rxjs';
import { CheckoutService } from '../service/checkout.service';
import { ConstantService } from '../../../../shared/services/constant.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { EncryptionService } from '../../../../shared/services/encryption.service';
import { CommonModule } from '@angular/common';
import { CardMaskDirective } from '../../../../shared/directives/card-mask.directive';
import { FlotNumberDirective } from '../../../../shared/directives/flot-number.directive';
import { SendPaymentLinkComponent } from '../../../billing/components/send-payment-link/send-payment-link.component';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { TrimValueDirective } from '../../../../shared/directives/trim-value.directive';
import { SplitPaymentComponent } from '../../../billing/components/split-payment/split-payment.component';
import { UppercaseAlphaNumericDirective } from '../../../../shared/directives/uppercase-alpha-numeric.directive';

@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [
    RouterModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatTooltipModule,
    MatRadioModule,
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    CardMaskDirective,
    FlotNumberDirective,
    TrimValueDirective,
    UppercaseAlphaNumericDirective,
  ],
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [EncryptionService]
})
export class PaymentComponent {
  paymentMethodOption = paymentMethodOptions;
  monthsList = monthList;
  taxPer = 0;
  yearsList: number[] = [];
  panelOpenState: boolean = true;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  paymentFormGroup: any;
  subscribeOrder!: Subscription;
  subscribeGiftCard!: Subscription;
  appliedGiftCard: any;
  giftCardDetail: any;
  orderDetails: any;
  @ViewChild('paymentPanel') paymentPanel!: MatExpansionPanel;
  public checkoutService = inject(CheckoutService);
  public constantService = inject(ConstantService);
  private dialog = inject(MatDialog);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  private encDecObj = inject(EncryptionService);
  private activateRoute = inject(ActivatedRoute);
  private router = inject(Router);
  selectedBranchLocation = 'select_business_location';
  tempConfirmOrderDetail = 'tempConfirmOrderDetail';
  tempCouponDetail = 'coupon_detail';

  constructor() {
    const orderCreated = this.activateRoute.snapshot.queryParams['orderCreated'];
    if (orderCreated != 'Yes') {
      this.router.navigate(['/checkout']);
      return;
    }
    this.initialOrderForm();
    this.constantService.replaceStateUrl();
  }

  initialOrderForm() {
    this.paymentFormGroup = this.fb.group({
      order_id: [''],
      giftCard: [''],
      paymentMethod: [1, Validators.required],
      cashAmount: [''],
      cardPayment: this.fb.group({
        card_holder_name: [''],
        card_number: [''],
        expiry_month: [''],
        expiry_year: [''],
        cvv: [''],
      }),
      giftCardAmount: [''],
      additionalAmount: [''],
      subTotal: [''],
      discount: [''],
      tax: [''],
      tip: [''],
      branchLocation: [''],
      grandTotal: [''],
      changeAmount: [''],
    })
  }


  get cardPaymentForm() {
    return this.paymentFormGroup.controls.cardPayment;
  }

  ngOnInit() {
    const configurationDetail = this.constantService.getConfigurationDetail();
    this.taxPer = configurationDetail ? configurationDetail.tax : 0;

    const tempOrderData = localStorage.getItem(this.tempConfirmOrderDetail);
    if (tempOrderData) {
      this.orderDetails = JSON.parse(this.encDecObj.getDecrypt(tempOrderData));
      this.setFromData();
    }
    this.generateYearRange();
    this.onYearChange();
    this.changePaymentMethod();
    this.paymentFormGroup.controls['cashAmount'].valueChanges.pipe(debounceTime(10), distinctUntilChanged()).subscribe((res: any) => {
      this.checkOrderAmount();
    })
  }

  setFromData() {
    this.paymentFormGroup.patchValue({
      order_id: this.orderDetails.order_id,
      discount: this.orderDetails.discount.toFixed(2),
      additionalAmount: this.orderDetails.additional_amount.toFixed(2),
      subTotal: this.orderDetails.sub_total.toFixed(2),
      tax: this.orderDetails.tax.toFixed(2),
      tip: this.orderDetails.tip.toFixed(2),
      grandTotal: this.orderDetails.total_amount.toFixed(2),
    })
  }

  private generateYearRange() {
    for (let i = 0; i <= 10; i++) {
      this.yearsList.push(this.currentYear + i);
    }
  }

  private onYearChange(): void {
    this.cardPaymentForm.controls.expiry_year.valueChanges.subscribe((selectedYear: number) => {
      this.updateMonthListBasedOnYear(selectedYear);
    });
  }

  private updateMonthListBasedOnYear(selectedYear: number): void {
    const isCurrentYear = selectedYear === this.currentYear;

    // Update the months list: Disable past months for the current year
    this.monthsList.forEach((month) => {
      month.disable = isCurrentYear && parseInt(month.value, 10) < this.currentMonth;
    });

    // Reset the month if it's no longer valid
    const selectedMonth = parseInt(this.cardPaymentForm.controls.expiry_month.value, 10);
    if (isCurrentYear && selectedMonth < this.currentMonth) {
      this.cardPaymentForm.controls.expiry_month.setValue('');
    }
  }



  changePaymentMethod() {
    const paymentMethod = this.paymentFormGroup.value.paymentMethod;
    const cardPaymentForm = this.cardPaymentForm; // Using the getter method
    const cashAmountControl = this.paymentFormGroup.get('cashAmount');

    if (paymentMethod === 1 && +this.paymentFormGroup.value.grandTotal != 0) {
      // Set validators for cardPayment fields
      cardPaymentForm.get('card_holder_name').setValidators([Validators.required]);
      cardPaymentForm.get('card_number').setValidators([Validators.required]);
      cardPaymentForm.get('expiry_month').setValidators([Validators.required]);
      cardPaymentForm.get('expiry_year').setValidators([Validators.required]);
      cardPaymentForm.get('cvv').setValidators([Validators.required, Validators.pattern('^[0-9]{3,4}$')]);
      cardPaymentForm.updateValueAndValidity();

      // Clear validators for cashAmount
      cashAmountControl.clearValidators();
      cashAmountControl.updateValueAndValidity();
    } else if (paymentMethod === 2 && +this.paymentFormGroup.value.grandTotal != 0) {
      // Set validators for cashAmount
      cashAmountControl.setValidators([Validators.required]);
      cashAmountControl.updateValueAndValidity();
      // this.paymentFormGroup.controls['cashAmount'].setValue(this.paymentFormGroup.value.grandTotal);

      // Clear validators for cardPayment fields
      cardPaymentForm.get('card_holder_name').clearValidators();
      cardPaymentForm.get('card_number').clearValidators();
      cardPaymentForm.get('expiry_month').clearValidators();
      cardPaymentForm.get('expiry_year').clearValidators();
      cardPaymentForm.get('cvv').clearValidators();
      cardPaymentForm.clearValidators();
      cardPaymentForm.updateValueAndValidity();
      cardPaymentForm.reset();
    }
    this.paymentFormGroup.updateValueAndValidity();
  }

  // 1 = Send payment link 2 = Process to Pay, 3= split payment
  private payment(type: number, order: any) {

    let data = {
      orderDetail: order
    };
    if (type == 1) {
      const dialogRef = this.dialog.open(SendPaymentLinkComponent, {
        width: '500px',
        panelClass: ['modal-wrapper'],
        disableClose: true,
        autoFocus: false,
        data
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res?.success) {
          // this.discardOrder('Discard');
        }
      })
    } else if (type == 2) {
      // localStorage.setItem('')
    } else if (type == 3) {
      const data = {
        grandTotal: order.grandTotal,
        order_id: order.order_id
      }
      const dialogRef = this.dialog.open(SplitPaymentComponent, {
        width: '500px',
        panelClass: ['modal-wrapper'],
        disableClose: true,
        autoFocus: false,
        data
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res?.success) {
          // this.discardOrder('Discard');
        }
      })
    }
  }
  splitPayment() {
    let obj = {
      grandTotal: this.paymentFormGroup.value.grandTotal,
      order_id: this.orderDetails.order_id
    }
    this.payment(3, obj)
  }

  private getBusinessLocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.paymentFormGroup.controls['branchLocation'].setValue(Number(id))
    }
  }
  // 1 = Send payment link, 2 = Process to Pay, 3= Generate Order
  orderPayment(type: number) {
    // const paymentType = this.paymentFormGroup.value.paymentMethod;
    // if (paymentType === 1) {
    //   this.cardPaymentForm.markAllAsTouched();
    // } else if (paymentType === 2) {
    //   this.paymentFormGroup.controls.cashAmount.markAsTouched();
    // }

    this.paymentFormGroup.controls.cashAmount.markAsTouched();
    if (this.paymentFormGroup.invalid) return;

    this.getBusinessLocation();
    let obj = {
      order_id: this.orderDetails.order_id,
      user_business_location_id: this.paymentFormGroup.value.branchLocation,
      coupon_id: this.giftCardDetail?.id || '',
      coupon_code_amount: this.giftCardDetail ? this.paymentFormGroup.value.giftCardAmount : '',
      payment_method: this.paymentFormGroup.value.paymentMethod,
    };

    if (this.subscribeOrder) this.subscribeOrder.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeOrder = this.checkoutService.orderPayment(obj).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          if (type == 1 || type == 2) {
            this.payment(type, res.data);
          } else {
            this.toastr.success('Order placed successfully');
          }
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  applyGiftCardCode() {
    let formData = new FormData();
    this.getBusinessLocation();
    formData.append('code', this.paymentFormGroup.value.giftCard);
    formData.append('user_business_location_id', this.paymentFormGroup.value.branchLocation);
    formData.append('coupon_type', '2');
    if (this.subscribeGiftCard) this.subscribeGiftCard.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeGiftCard = this.checkoutService.applyCoupon(formData).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.appliedGiftCard = this.paymentFormGroup.value.giftCard;
          this.paymentFormGroup.controls['giftCard'].disable();
          this.giftCardDetail = res.data;
          this.calculateOrderPrice();
          this.toastr.success(res.message);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  removeGiftCardCode1(): void {
    this.appliedGiftCard = null;
    this.paymentFormGroup.controls['giftCard'].enable();
    this.giftCardDetail = null;
    this.paymentFormGroup.controls['grandTotal'].setValue(Number(this.paymentFormGroup.value.grandTotal + Number(this.paymentFormGroup.value.giftCardAmount)));
    this.paymentFormGroup.controls['giftCard'].setValue('');
    this.paymentFormGroup.controls['giftCardAmount'].setValue('');
    this.calculateOrderPrice();
  }

  removeGiftCardCode(): void {
    // Clear applied gift card and reset related fields
    this.appliedGiftCard = null;
    this.giftCardDetail = null;

    // Enable the gift card control and clear its value
    const paymentForm = this.paymentFormGroup.controls;
    paymentForm['giftCard'].enable();
    paymentForm['giftCard'].setValue('');
    this.paymentFormGroup.controls.giftCardAmount.setValue(0)
    // Update the grand total by adding back the gift card amount
    // const grandTotal = +this.paymentFormGroup.value.grandTotal;
    const grandTotal = (+this.paymentFormGroup.value.additionalAmount) + (+this.paymentFormGroup.value.subTotal) + (+this.paymentFormGroup.value.tip) + (+this.paymentFormGroup.value.tax) - (+this.paymentFormGroup.value.discount)
    const giftCardAmount = +this.paymentFormGroup.value.giftCardAmount || 0; // Use 0 if the value is null/undefined
    paymentForm['grandTotal'].setValue(grandTotal + giftCardAmount);

    // Clear gift card amount
    // paymentForm['giftCardAmount'].setValue(0);

    // Recalculate the order price
    this.calculateOrderPrice();
  }



  calculateOrderPrice() {
    const giftCardValue = this.appliedGiftCard ? this.giftCardDetail.value : 0;
    const grandTotal = Number(this.paymentFormGroup.value.grandTotal) || 0;

    // Set gift card value in the form
    this.paymentFormGroup.controls['giftCardAmount'].setValue(giftCardValue.toFixed(2));

    // Calculate new grand total after applying the gift card discount
    const total = Math.max(grandTotal - giftCardValue, 0);
    this.paymentFormGroup.controls['grandTotal'].setValue(total.toFixed(2));
    this.checkOrderAmount();
  }

  backToCheckout(action: string) {
    this.router.navigate(['/checkout'], { queryParams: { orderCreated: action } });
  }
  // paidForCheckout() {
  //   this.router.navigate(['/checkout'], { queryParams: { orderCreated: 'paid' } });
  // }
  confirmOrder() {
    const cardPaymentForm = this.cardPaymentForm; // Using the getter method
    const cashAmountControl = this.paymentFormGroup.get('cashAmount');
    if (this.paymentFormGroup.value.grandTotal == 0) {
      cardPaymentForm.get('card_holder_name').clearValidators();
      cardPaymentForm.get('card_number').clearValidators();
      cardPaymentForm.get('expiry_month').clearValidators();
      cardPaymentForm.get('expiry_year').clearValidators();
      cardPaymentForm.get('cvv').clearValidators();
      cardPaymentForm.get('card_holder_name').updateValueAndValidity();
      cardPaymentForm.get('card_number').updateValueAndValidity();
      cardPaymentForm.get('expiry_month').updateValueAndValidity();
      cardPaymentForm.get('expiry_year').updateValueAndValidity();
      cardPaymentForm.get('cvv').updateValueAndValidity();
      cardPaymentForm.clearValidators();
      cashAmountControl.clearValidators();
      cashAmountControl.updateValueAndValidity();
    }
    this.paymentFormGroup.updateValueAndValidity();
    this.cardPaymentForm.markAllAsTouched();

    // Check if form is invalid and open payment panel
    if (this.paymentFormGroup.invalid) {
      this.paymentPanel.open();
      return;
    }

    const { paymentMethod, cashAmount, grandTotal } = this.paymentFormGroup.value;

    // Validate cash amount when payment method is cash
    if (paymentMethod === 2 && +grandTotal > +cashAmount) {
      this.constantService.showError('enter_amount_error')
      return;
    }

    const {
      order_id,
      discount,
      additional_amount,
      sub_total,
      tax,
      tip,
    } = this.orderDetails;

    // Build order object
    const obj: any = {
      order_id,
      discount,
      additional_amount,
      sub_total,
      tax,
      tip,
      total_amount: grandTotal,
      payment_method: paymentMethod,
      coupon_id: this.giftCardDetail?.id || '',
      coupon_code_amount: this.giftCardDetail?.giftCardAmount || '',
      cash_amount: paymentMethod === 2 ? cashAmount : 0,
    };

    // Add card details if payment method is card
    if (paymentMethod === 1) {
      Object.assign(obj, this.cardPaymentForm.value);
    }

    // Proceed with checkout
    this.checkoutOrder();
  }


  checkoutOrder() {
    let formData = new FormData();
    this.getBusinessLocation();
    formData.append('order_id', this.orderDetails.order_id);
    formData.append('user_business_location_id', this.paymentFormGroup.value.branchLocation);
    formData.append('payment_method', '1'); // 1=cash,2=creditCard,3=giftCard
    formData.append('giftcard_id', this.giftCardDetail?.id || '');
    if (this.subscribeGiftCard) this.subscribeGiftCard.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeGiftCard = this.checkoutService.checkout(formData).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.toastr.success(res.message);
          this.backToCheckout('paid');
          //Temp code
          this.checkoutService.isDisplayOrder.next({
            success: true,
            action: 'Discard'
          });
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  panel(type: boolean) {
    this.panelOpenState = type;
  }

  checkOrderAmount() {

    const cashAmount = +this.paymentFormGroup.value.cashAmount;
    const grandTotal = +this.paymentFormGroup.value.grandTotal;

    if (cashAmount >= grandTotal) {
      const changeAmount = (cashAmount - grandTotal).toFixed(2);
      this.paymentFormGroup.get('changeAmount')?.setValue(changeAmount);
    } else {
      this.paymentFormGroup.get('changeAmount')?.setValue(0);
    }
  }

  ngOnDestroy(): void {
    if (this.subscribeOrder) this.subscribeOrder.unsubscribe();
    if (this.subscribeGiftCard) this.subscribeGiftCard.unsubscribe();
    const tempOrderData = localStorage.getItem(this.tempConfirmOrderDetail);
    if (tempOrderData) {
      localStorage.removeItem(this.tempConfirmOrderDetail)
    }
    const tempCoupon = localStorage.getItem(this.tempCouponDetail);
    if (tempCoupon) {
      localStorage.removeItem(this.tempCouponDetail)
    }
    localStorage.removeItem(this.tempCouponDetail)
  }
}
