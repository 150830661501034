<mat-dialog-content class="text-center" [ngClass]="{'pointer-none':  waitingProgressLoader}">
  <div class="mb-24 radius-12">
    <img src="assets/images/update-ic.png" alt="{{'update_waiting_list' | translate}}" class="dialog-smallimg" />
  </div>
  <div class="block text-grey-light mb-24">
    <h3 class="h1 mb-24 text-white">
      {{ (action == 'Add' ? 'add_waiting_list' : 'update_waiting_list') | translate }}
    </h3>
    <p>
      {{ (action == 'Add' ? 'fill_in_below_detail' : 'edit_below_to_modify_waiting') | translate }}
    </p>
  </div>

  <form [formGroup]="waitingListForm">
    <div class="mb-24 updatelist-field">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{'enter_customer_name' | translate}}</mat-label>
        <input matInput formControlName="customer_name" appAlphaWithSpaces>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{'queue_number' | translate | titlecase}}</mat-label>
        <input matInput formControlName="queue_number" allowNumberOnly>
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{'no_of_guests' | translate | titlecase}}</mat-label>
        <input matInput formControlName="number_of_people" allowNumberOnly max="9999" [maxlength]="4">
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic"
        *ngIf="preferredCountries && preferredCountries[0]">
        <mat-label>{{'mobile_number' | translate | titlecase}}*</mat-label>
        <ngx-mat-intl-tel-input formControlName="phone_number" class="w-full" [preferredCountries]="preferredCountries"
          [enablePlaceholder]="true" [enableSearch]="true" name="phone" #phone>
        </ngx-mat-intl-tel-input>
      </mat-form-field>
    </div>

  </form>
  <div class="btn-box text-center">
    <button mat-flat-button (click)="close(false)" class="btn-outline-primary">{{'cancel' | translate}}</button>
    <button mat-flat-button type="submit" (click)="addUpdateWaiting()">
      {{ (action === 'Add' ? 'add_now' : 'update_now') | translate }}
    </button>
  </div>
</mat-dialog-content>