import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  Inject,
  OnInit,
  inject,
  HostListener,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ConstantService } from '../../../services/constant.service';
import { languageArray } from '../../../../model/const/common.const';
import { ThemeType } from '../../../../model/types/GlobalTypes';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { ResponseMessage } from '../../../../model/interfaces/req.res.interface';
import { TranslateModule } from '@ngx-translate/core';
import { EncryptionService } from '../../../services/encryption.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { BranchLocationComponent } from '../branch-location/branch-location.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LanguageNamePipe } from 'src/app/shared/pipes/language-name.pipe';

// Services

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    RouterModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    BranchLocationComponent,
    MatTooltipModule,
    LanguageNamePipe,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [EncryptionService],
})
export class HeaderComponent {
  @Input() isOpenSideBar: boolean = false;

  @Output() openCloseSideMenu = new EventEmitter();
  private constantService = inject(ConstantService);
  private toastr = inject(ToastrService);
  private encDecObj = inject(EncryptionService);
  private router = inject(Router);
  languages = languageArray;
  selectedLanguage: any;
  subscribe!: Subscription;
  subscribeLogin!: Subscription;
  load = true;
  selectedBranchLocationId: any;
  userDetails: any = '';
  deviceType = this.constantService.getDeviceType();

  //#region Localstorage
  language = 'selected_language';
  theme = 'selected_theme';
  selectedBranchLocation = 'select_business_location';
  businessConfiguration = 'business_configuration';
  branchListData: any;
  productPermission: any;

  toggleMenuClicked() {
    this.openCloseSideMenu.emit();
  }

  /**
   * Hold theme mode
   */
  themeType = 'light';
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.initializeTheme();
    this.initializeLanguage();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.deviceType = this.constantService.getDeviceType();
  }

  ngOnInit(): void {
    // this.getBranches();
    this.getConfiguration();
    if (localStorage.getItem('userDetail')) {
      let userDetail = this.encDecObj.getDecrypt(localStorage.getItem('userDetail'));
      this.userDetails = JSON.parse(userDetail)
    }
  }

  private initializeTheme(): void {
    const storedTheme = this.constantService.getTheme();
    const theme = storedTheme || 'light';
    this.changeTheme(theme);
  }

  private initializeLanguage() {
    const storedLang = this.constantService.getLanguage();
    this.selectedLanguage = storedLang;
    this.constantService.translateData(storedLang);
    this.constantService.setLang(storedLang);
  }

  changeLanguage() {
    this.constantService.changeLanguages(this.selectedLanguage);
    this.getLang();
  }

  getLang() {
    this.selectedLanguage = this.constantService.getLanguage();
  }
  // changeLanguage(lan: string) {
  //   this.constantService.translateData(lan);
  //   this.constantService.langChangeSub.next(lan);
  //   // let lang = {
  //   //   language: lan === 'es' ? 1 : 2, // Use 1 for 'es' (Spanish) and 2 for (English)
  //   // };
  //   // this.load = true;
  //   // this.constantService.progressLoader.next(true);
  //   // this.subscribe = this.constantService
  //   //   .setLanguageAPI(lang)
  //   //   .pipe(
  //   //     finalize(() => this.constantService.progressLoader.next(false)),
  //   //     catchError((error) => {
  //   //       this.load = false;
  //   //       this.toastr.error(error.error.message);
  //   //       return throwError(() => error.error.message);
  //   //     })
  //   //   )
  //   //   .subscribe((res: ResponseMessage) => {
  //   //     if (res.code === 200) {
  //   //       this.constantService.translateData(lan);
  //   //       this.constantService.langChangeSub.next(lan);
  //   //     } else {
  //   //       this.toastr.error(res.message);
  //   //     }
  //   //   });
  // }

  changeTheme(theme: any) {
    this.constantService.setTheme(theme);
    this.themeType = theme;
  }
  getConfiguration() {
    this.constantService.getConfiguration().pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        const tempBusinessConfiguration = res.data;
        this.userDetails.name = tempBusinessConfiguration.name;
        localStorage.setItem('userDetail', this.encDecObj.setEncrypt(JSON.stringify(this.userDetails)));
        this.constantService.updateName.next(true);
        const businessConfigurationDetail = this.encDecObj.setEncrypt(JSON.stringify(tempBusinessConfiguration));
        localStorage.setItem(this.businessConfiguration, businessConfigurationDetail);
        this.productPermission = this.constantService.getModuleWiseRole('product_management');
      } else {
        this.toastr.error(res.message);
      }
    });
  }
  // getBranches() {
  //   this.constantService
  //     .getBranchList()
  //     .pipe(
  //       catchError((error) => {
  //         this.toastr.error(error.error.message);
  //         return throwError(() => error.error.message);
  //       })
  //     )
  //     .subscribe((res: ResponseMessage) => {
  //       if (res.code === 200) {
  //         this.branchListData = res.data.data;
  //         this.setBranchLocation()
  //       } else {
  //         this.toastr.error(res.message);
  //       }
  //     });
  // }

  setBranchLocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(
        localStorage.getItem(this.selectedBranchLocation)
      );
      this.selectedBranchLocationId = Number(id);
    } else if (this.branchListData?.length > 0) {
      this.selectLocaiton(this.branchListData[0].business_location_id);
    }
  }

  selectLocaiton(id: number) {
    this.selectedBranchLocationId = id;
    const temp = this.encDecObj.setEncrypt(id.toString());
    localStorage.setItem(this.selectedBranchLocation, temp);
  }

  redirectToModule(type: number) {
    let res = {
      success: true,
      title: type == 1 ? 'home' : 'product_management',
    }
    this.constantService.selectedSidebarMenu.next(res);
    if (type == 1) this.router.navigate(['/dashboard'])
    else if (type == 2) this.router.navigate(['/product-list/add'])
  }

  swicthToVendorAdmin() {
    const token = localStorage.getItem('token');
    const lang = this.constantService.getLanguage();
    const url = environment.vendorAdminUrl + `?token=${token}&lang=${lang}`;
    this.constantService.redirectToURL(url, 'true');
  }

  ngOnDestroy(): void {
    if (this.subscribe) this.subscribe.unsubscribe();
    if (this.subscribeLogin) this.subscribeLogin.unsubscribe();
  }
}
