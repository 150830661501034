import { Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConstantService } from 'src/app/shared/services/constant.service';

@Component({
  selector: 'app-access-denied',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  private constantService = inject(ConstantService);
  themeType: any;
  constructor() { }

  ngOnInit() {
    this.themeType = this.constantService.getTheme();
  }
}
