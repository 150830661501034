<!-- Header -->
<div
  class="section-box mb-24 flex flex-wrap flex-justify-between flex-align-center header-section gap-8px header-box backbtn-wrap">
  <h1 class="text-white mb-0 text-truncate">
    {{reservationId?('edit_reservation' | translate):('add_reservation' | translate)}}
  </h1>
  <a mat-flat-button class="btn-outline-secondary backbtn" (click)="backToReservationList()">
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.2876 13.4386L1.3976 8.54859C0.820097 7.97109 0.820097 7.02609 1.3976 6.44859L6.2876 1.55859"
        stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    {{'back' | translate}}</a>
</div>

<!-- Form -->
<form class="section-box form-box" [formGroup]="reservationForm">
  <div class="form-grid3">

    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{'enter_customer_name' | translate}}</mat-label>
      <input matInput formControlName="customer_name" appAlphaWithSpaces>
    </mat-form-field>

    <mat-form-field appearance="outline" subscriptSizing="dynamic" *ngIf="preferredCountries && preferredCountries[0]">
      <mat-label>{{'mobile_number' | translate | titlecase}}*</mat-label>
      <ngx-mat-intl-tel-input formControlName="mobile_number" class="w-full" [preferredCountries]="preferredCountries"
        [enablePlaceholder]="true" [enableSearch]="true" name="userNamePhone" #userNamePhone>
      </ngx-mat-intl-tel-input>
    </mat-form-field>

    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="small-select">
      <mat-label>{{'no_of_guests' | translate | titlecase}}</mat-label>
      <input matInput formControlName="no_of_guests" max="9999" [maxlength]="4" allowNumberOnly>
    </mat-form-field>

    <mat-form-field class="example-form-field" subscriptSizing="dynamic" appearance="outline">
      <mat-label>{{ 'select_date' | translate }}</mat-label>
      <div class="w-full relative input-rightspace">
        <input matInput [matDatepicker]="datepicker" placeholder="{{ 'select_date' | translate }}"
          (click)="datepicker.open()" (dateChange)="selectDateTime()" formControlName="select_date" [min]="currentDate"
          readonly />
        <mat-datepicker-toggle matIconSuffix [for]="datepicker" class="ic-input cursor-pointer"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #datepicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="example-form-field" subscriptSizing="dynamic" appearance="outline">
      <mat-label>{{ 'select_time' | translate }}</mat-label>
      <div class="icon-input w-full">
        <input matInput [format]="24" [ngxTimepicker]="picker" [min]="minTime" formControlName="select_time" readonly>
        <span class="icon-icon-clock"></span>
      </div>
      <ngx-material-timepicker #picker [minutesGap]="5" (timeSet)="onTimeSet($event)"
        [defaultTime]="currentTime"></ngx-material-timepicker>
    </mat-form-field>



    <div class="">
      <mat-form-field subscriptSizing="dynamic" appearance="outline" class="small-select">
        <mat-label>{{ 'select_table' | translate }}</mat-label>
        <mat-select formControlName="select_table">
          <mat-option *ngFor="let table of tableListData" [value]="table.id" (click)="checkTableAvailability(table.id)">
            {{ table.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error class="small-warning-text" *ngIf="tableListData && isTableNotAvailable && reservationId">
        {{'table_reservation_warning' | translate}}
      </mat-error>
    </div>

    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{'reservation_event' | translate | titlecase}}</mat-label>
      <input matInput formControlName="event" appTrimSpaces>
    </mat-form-field>
    
    <div class="text-end grid flex-align-end flex-justify-end update-btnwrap">
      <button mat-flat-button class="btn-lg mobile-full" (click)="addUpdateReservation()">
        {{(reservationId ? 'update_reservation' : 'add_reservation' )| translate}}
      </button>
    </div>

  </div>

</form>