<div class="flex flex-justify-between flex-wrap gap-8px mb-16 flex-align-center section-box">
  <h1 class="mb-0 mobile-full h3 fw-bold">{{'purchase_order' | translate}}</h1>
  <div class="flex gap-16px flex-grow-1 flex-justify-end">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" class="search-inputbx input-lg">
      <div class="w-full relative input-rightspace">
        <input type="search" class="form-control" (keyup)="serachPurchase()" [(ngModel)]="serachPurchaseOrder" matInput
          placeholder="{{'search_purchase' | translate}}" />
        <mat-icon class="ic-input cursor-pointer">search</mat-icon>
      </div>
    </mat-form-field>
    <button *ngIf="purchaseOrderPermission?.add == 1" mat-flat-button class="flex-shrink-0 createmenu-btn btn-lg"
      (click)="createEditPurchaseOrder('Add')">
      {{'create_purchase_order' | translate}}
    </button>
  </div>
</div>

<!-- Starts Filter Section -->
<div class="filter-boxwrapper flex flex-justify-end flex-wrap mb-20">

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-date date-field select-bgwrap">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="fromDate" [max]="currentDate" placeholder="{{'from' | translate}}"
        (click)="fromDate.open()" [(ngModel)]="selectFromDate" readonly (dateChange)="selectSearch(1, selectFromDate)">
      <mat-datepicker-toggle matIconSuffix [for]="fromDate" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #fromDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-date date-field select-bgwrap">
    <div class="w-full relative input-rightspace">
      <input matInput [matDatepicker]="toDate" [max]="currentDate" [min]="selectFromDate"
        placeholder="{{'to' | translate}}" (click)="toDate.open()" [disabled]="!selectFromDate"
        [(ngModel)]="selectToDate" (dateChange)="selectSearch(2, selectToDate)" readonly>
      <mat-datepicker-toggle matIconSuffix [for]="toDate" class="ic-input cursor-pointer"></mat-datepicker-toggle>
    </div>
    <mat-datepicker #toDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-item select-bgwrap">
    <mat-select placeholder="{{'items' | translate}}" [(ngModel)]="selectProduct">
      <mat-option value="" (click)="selectSearch(3, 0)">
        {{'items' | translate}}
      </mat-option>
      <mat-option *ngFor="let item of productList;" (click)="selectSearch(3, item.id)" [value]="item.id">
        {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-item select-bgwrap">
    <mat-select placeholder="{{'vendor' | translate}}" [(ngModel)]="selectVendor">
      <mat-option value="" (click)="selectSearch(4, 0)">
        {{'vendor' | translate}}
      </mat-option>
      <mat-option *ngFor="let vendor of vendorList;" (click)="selectSearch(4, vendor.user_id)"
        [value]="vendor.user_id">{{vendor.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field subscriptSizing="dynamic" appearance="fill" class="filter-location select-bgwrap">
    <mat-select placeholder="{{'locations' | translate}}" [(ngModel)]="selectBranch">
      <mat-option value="" (click)="selectSearch(5, 0)">
        {{'locations' | translate}}
      </mat-option>
      <mat-option *ngFor="let branch of branchList;" (click)="selectSearch(5, branch.business_location_id)"
        [value]="branch.business_location_id">
        {{ branch.name }}
      </mat-option>

    </mat-select>
  </mat-form-field>
  <button mat-flat-button class="btn-outline-primary mobile-filterbtn export-btn" [disabled]="isResetButtonDisabled"
    (click)="resetFilter()">{{'reset' | translate}}</button>
</div>
<!-- Ends Filter Section -->

<div class="table-sectionbox">
  <div class="flex flex-justify-between flex-wrap gap-8px flex-align-center section-box  table-sectionbx">
    <h1 class="mb-0 mobile-full h3">{{'purchase_order_list' | translate}}</h1>
    <div class="flex gap-16px flex-grow-1 flex-justify-end flex-align-center mobile-full radio-box flex-wrap fs-14">

      <label id="example-radio-group-label" class="text-grey-medium  mb-0 mobile-full fs-14">
        {{'purchase_amount' | translate}}
      </label>

      <mat-radio-group [(ngModel)]="selectPurchaseAmount" (ngModelChange)="selectPurchaseAmountFilter($event)"
        aria-label="Select an option" class="hr-radiogroup">
        <mat-radio-button *ngFor="let option of filterOptions" [value]="option.id">
          {{ option.translateLabel | translate }}
        </mat-radio-button>
      </mat-radio-group>

    </div>
  </div>
  <div class="table-responsive custom-scroll table-wrapper">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

      <!-- DATE Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'purchase_order_columns.date' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.date}} </td>
      </ng-container>

      <!-- VENDOR NAME Column -->
      <ng-container matColumnDef="vendorName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'purchase_order_columns.vendor_name' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.vendorName}} </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#{{'purchase_order_columns.id' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- TITLE Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'purchase_order_columns.title' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="nowrap"> {{element.title}} </td>
      </ng-container>

      <!-- purchase Status Column -->
      <ng-container matColumnDef="purchaseStatus">
        <th mat-header-cell *matHeaderCellDef>{{'purchase_order_columns.purchase_status' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <span class="badge badge-light-success badge-small">Billed</span>
        </td>
      </ng-container>

      <!-- TOTAL AMOUNT Column -->
      <ng-container matColumnDef="totalAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'purchase_order_columns.total_amount' | translate}}</th>
        <td mat-cell *matCellDef="let element">${{element.totalAmount}} </td>
      </ng-container>

      <!-- ACTION Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="action-col">{{'purchase_order_columns.actions' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex table-actionbtn">
            <button mat-icon-button aria-label="View" matTooltip="{{'view' | translate}}" (click)="openDialog()"><span
                class="icon-eye"></span></button>
            <button *ngIf="purchaseOrderPermission?.update == 1" mat-icon-button aria-label="Edit"
              matTooltip="{{'edit' | translate}}" (click)="createEditPurchaseOrder('Edit',element.id)">
              <span class="icon-development"></span>
            </button>
            <button mat-icon-button aria-label="Download" matTooltip="{{'download' | translate}}"><span
                class="icon-ic-download"></span></button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <ng-container *ngIf="totalRecord == 0 && !purchaseOrderProgressLoader">
      <app-no-records-available></app-no-records-available>
    </ng-container>
  </div>
</div>