import { CommonModule } from '@angular/common';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { SafeUrlPipe } from 'src/app/shared/pipes/safe-url.pipe';

@Component({
  selector: 'app-invoice-preview',
  standalone: true,
  imports: [
    SafeUrlPipe,
    MatDialogModule,
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.scss']
})
export class InvoicePreviewComponent implements OnInit {
  loading = true;
  localData: any;
  private dialogRef = inject(MatDialogRef<InvoicePreviewComponent>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.localData = { ...data };
  }

  ngOnInit() { }

  onPdfLoad(): void {
    this.loading = false; // Stop showing the loading message when the PDF is loaded
  }

  onPdfError(): void {
    this.loading = false; // Stop loading and handle error (if needed)
    console.error('Failed to load PDF.');
  }

  close(): void {
    this.dialogRef.close(
      {
        success: true
      }
    );
  }


}
