import { Component, inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { ViewOrderComponent } from '../view-order/view-order.component';
import { PurchaseOrderService } from '../service/purchase-order.service';
import { ToastrService } from 'ngx-toastr';
import { EncryptionService } from 'src/app/shared/services/encryption.service';
import { ConstantService } from 'src/app/shared/services/constant.service';
import { CommonModule, DatePipe } from '@angular/common';
import { ResponseMessage } from 'src/app/model/interfaces/req.res.interface';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NoRecordsAvailableComponent } from 'src/app/shared/components/common/no-records-available/no-records-available.component';
import { filterPurchaseOrder } from 'src/app/model/const/common.const';
export interface PeriodicElement {
  date: string;
  vendorName: string;
  id: string;
  title: string;
  totalAmount: number,
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    date: '28/05/2024',
    vendorName: 'Makenna Schleifer',
    id: '#12345',
    title: 'Bread',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
  {
    date: '28/05/2024',
    vendorName: 'Cheyenne Westervelt',
    id: '#12345',
    title: 'Cheese',
    totalAmount: 540.55
  },
];
@Component({
  selector: 'app-purchase-list',
  standalone: true,
  providers: [
    DatePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatSortModule,
    MatRadioModule,
    MatTooltipModule,
    TranslateModule,
    NoRecordsAvailableComponent,
  ],
  templateUrl: './purchase-list.component.html',
  styleUrls: ['./purchase-list.component.scss']
})
export class PurchaseListComponent {

  private purchaseOrderService = inject(PurchaseOrderService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private encDecObj = inject(EncryptionService);
  public constantService = inject(ConstantService);
  private datePipe = inject(DatePipe);
  private dialog = inject(MatDialog);
  subscribeListAPI!: Subscription;
  subscribeDeleteAPI!: Subscription;

  displayedColumns: string[] = ['date', 'vendorName', 'id', 'title', 'purchaseStatus', 'totalAmount', 'action'];
  dataSource = ELEMENT_DATA;
  dataSourcePurchaseOrder: any;
  purchaseOrderListData: any;
  totalRecord: number = 0;
  productList: any;
  vendorList: any;
  branchList: any;
  selectProduct = 0;
  selectVendor = 0;
  selectBranch = 0;
  selectFromDate: any;
  selectToDate: any;
  maxDate: any;
  currentDate: any;
  filterOptions = filterPurchaseOrder;
  selectPurchaseAmount = 1;
  purchaseOrderProgressLoader = false;
  purchaseOrderPermission: any;
  defaultPurchaseOrderSearchPayload = {
    searchValue: '',
    fromDate: '',
    toDate: '',
    vendor: 0,
    product: 0,
    branch: 0,
  };
  isResetButtonDisabled: boolean = true;
  purchaseOrderSearchPayload: any = { ...this.defaultPurchaseOrderSearchPayload };
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  pageNumber: number = 1;
  perPages: number = 25;
  serachPurchaseOrder: String = '';

  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.purchaseOrderProgressLoader = state;
    });
  }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.constantService.progressLoader.next(true);
    const permission = this.constantService.getModuleWiseRole('purchase_order');

    // if (permission?.update === 0 && permission?.delete === 0) {
    //   this.displayedColumns = this.displayedColumns.filter(column => column !== 'action');
    // }
    this.purchaseOrderPermission = permission;
    // this.getProducts();
    // this.getVendors();
    // this.getBranches();
    // this.getPurchaseOrderList();
  }

  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }

  //#region Filters API's

  getProducts() {
    this.getBusinesslocation();
    let payload = {
      business_location_id: this.selectedBranchLocationId,
    }
    this.purchaseOrderService.getProducts(payload).pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.productList = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      });
  }

  getVendors() {
    this.purchaseOrderService.getVendors().pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.vendorList = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  getBranches() {
    this.purchaseOrderService.getBranches().pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.branchList = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Filters API's

  //#region Purchase Order List

  getPurchaseOrderList() {
    this.pageNumber = 1;
    const payload = {
      page: this.pageNumber,
      per_page: this.perPages,
      search: this.purchaseOrderSearchPayload?.searchValue,
      from_date: this.purchaseOrderSearchPayload?.fromDate,
      to_date: this.purchaseOrderSearchPayload?.toDate,
      item_id: this.purchaseOrderSearchPayload?.product,
      user_id: this.purchaseOrderSearchPayload?.vendor,
      user_business_location_id: this.purchaseOrderSearchPayload?.branch,
    }
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribeListAPI = this.purchaseOrderService.getPurchaseOrderList(payload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.purchaseOrderListData = res.data.data;
          this.totalRecord = res.data.pagination.total;
          this.dataSourcePurchaseOrder = new MatTableDataSource(res.data.data);
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  purchaseOrderListScrollDown() {
    this.pageNumber += this.pageNumber;
    const payload = {
      page: this.pageNumber,
      per_page: this.perPages,
      search: this.purchaseOrderSearchPayload?.searchValue,
      from_date: this.purchaseOrderSearchPayload?.fromDate,
      to_date: this.purchaseOrderSearchPayload?.toDate,
      item_id: this.purchaseOrderSearchPayload?.product,
      user_id: this.purchaseOrderSearchPayload?.vendor,
      user_business_location_id: this.purchaseOrderSearchPayload?.branch,
    }
    this.constantService.progressLoader.next(true);
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
    this.subscribeListAPI = this.purchaseOrderService.getPurchaseOrderList(payload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.purchaseOrderListData = [...this.purchaseOrderListData, ...res.data.data];
          this.totalRecord = res.data.pagination.total;
          this.dataSourcePurchaseOrder = new MatTableDataSource(this.purchaseOrderListData);
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  //#endregion Purchase Order List

  //#region Search Purchase Order
  serachPurchase() {
    this.serachPurchaseOrder = this.serachPurchaseOrder.trimStart();
    const searchText = this.serachPurchaseOrder.trim();

    if (searchText !== this.purchaseOrderSearchPayload.searchValue) {
      if (searchText && searchText.length > 1) {
        this.purchaseOrderSearchPayload.searchValue = searchText;
        this.getPurchaseOrderList();
        this.checkResetButtonShouldBeDisabled();
      } else if (!searchText && this.purchaseOrderSearchPayload.searchValue !== '') {
        this.purchaseOrderSearchPayload.searchValue = '';
        this.checkResetButtonShouldBeDisabled();
        this.getPurchaseOrderList();
      }
    }
  }

  selectSearch(type: number, value: any) {
    const payloadMap: any = {
      1: { key: 'fromDate', transform: true },
      2: { key: 'toDate', transform: true },
      3: { key: 'product' },
      4: { key: 'vendor' },
      5: { key: 'branch' },
    };

    const payloadItem = payloadMap[type];
    if (!payloadItem) {
      return;
    }

    // Check if value is 0 and corresponding payload field is empty or zero
    if (value == 0 && (this.purchaseOrderSearchPayload[payloadItem.key] == '' || this.purchaseOrderSearchPayload[payloadItem.key] == 0)) {
      return;
    }

    // Apply the value or transform it if needed
    this.purchaseOrderSearchPayload[payloadItem.key] = payloadItem.transform ?
      this.datePipe.transform(value, 'yyyy-MM-dd') : value;

    this.getPurchaseOrderList();
    this.checkResetButtonShouldBeDisabled();
  }

  resetFilter() {
    // Reset Purchase Order search payload
    this.purchaseOrderSearchPayload = {
      fromDate: '',
      toDate: '',
      product: 0,
      vendor: 0,
      branch: 0,
    };

    // Reset selected filter variables
    this.selectVendor = this.selectBranch = this.selectProduct = 0;

    // Reset selected date variables
    this.selectFromDate = this.selectToDate = this.serachPurchaseOrder = '';
    this.pageNumber = 1;
    this.getPurchaseOrderList();
    this.isResetButtonDisabled = true;
  }

  checkResetButtonShouldBeDisabled(): void {
    this.isResetButtonDisabled = JSON.stringify(this.purchaseOrderSearchPayload) === JSON.stringify(this.defaultPurchaseOrderSearchPayload);
  }

  createEditPurchaseOrder(action: string, id?: number) {
    if (action == 'Add') {
      this.router.navigate(['/purchase-order/create']);
    } else {
      this.router.navigate([`/purchase-order/edit/${id}`]);
    }
  }

  selectPurchaseAmountFilter(id: number) {
    // this.selectPurchaseAmount = id;
    // this.getPurchaseOrderList();
  }

  ngOnDestroy(): void {
    if (this.subscribeListAPI) this.subscribeListAPI.unsubscribe();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ViewOrderComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
    });
  }
}
