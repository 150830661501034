import { Component, Inject, inject, Optional } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TableManagementService } from '../services/table-management.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConstantService } from '../../../shared/services/constant.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TrimSpacesDirective } from 'src/app/shared/directives/trim-spaces.directive';
import { ResponseMessage } from 'src/app/model/interfaces/req.res.interface';
import { EncryptionService } from 'src/app/shared/services/encryption.service';

@Component({
  selector: 'app-add-table',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    TrimSpacesDirective,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss']
})
export class AddTableComponent {
  private tableManagementService = inject(TableManagementService);
  private fb = inject(FormBuilder);
  private toastr = inject(ToastrService);
  private constantService = inject(ConstantService);
  private encDecObj = inject(EncryptionService);
  private dialogRef = inject(MatDialogRef<AddTableComponent>);
  tableForm!: FormGroup;
  subscription!: Subscription;
  subscriptionDetail!: Subscription;
  localData: any;
  action = 'Add';
  tableDetail: any;
  tableProgressLoader = false;
  selectedBranchLocation = 'select_business_location';
  selectedBranchLocationId = 0;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AddTableComponent) {
    this.localData = { ...data };
    this.action = this.localData.action;

    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.tableProgressLoader = state;
    });

  }

  initialTableForm() {
    this.tableForm = this.fb.group({
      name: ['', Validators.required],
      business_location_id: ['']
    })
  }

  ngOnInit() {
    this.initialTableForm();
    if (this.action == 'Edit') {
      this.getTableDetail(this.localData.id);
    }
  }

  getBusinesslocation() {
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.selectedBranchLocationId = Number(id);
    }
  }

  getTableDetail(id: number) {
    if (this.subscriptionDetail) this.subscriptionDetail.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscriptionDetail = this.tableManagementService.getTableDetail(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code == 200) {
          this.tableDetail = res.data;
          this.setFormData(this.tableDetail)
        } else {
          this.toastr.error(res.message);
        }
      })
  }
  setFormData(data: any) {
    this.tableForm.patchValue({
      name: data.name,
      business_location_id: data.business_location_id,
    })
  }

  addUpdateTable() {
    this.getBusinesslocation();
    if (this.tableForm.invalid) {
      return;
    }

    if (this.subscription) this.subscription.unsubscribe();
    this.constantService.progressLoader.next(true);
    if (this.action == 'Add') {
      const tableObj: any = {
        name: this.tableForm.value.name,
        business_location_id: this.selectedBranchLocationId,
      }
      this.subscription = this.tableManagementService.addTable(tableObj).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((err) => {
          this.toastr.error(err.error.message);
          return throwError(() => err.error.message);
        })).subscribe((res: any) => {
          if (res.code == 200) {
            this.toastr.success(res.message);
            this.cancel(true);
          } else {
            this.toastr.error(res.message);
          }
        })
    } else if (this.action == 'Edit') {
      let tableObj: any = {
        id: this.localData.id,
        name: this.tableForm.value.name,
        business_location_id: this.tableForm.value.business_location_id,
      }
      this.subscription = this.tableManagementService.updateTable(tableObj).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((err) => {
          this.toastr.error(err.error.message);
          return throwError(() => err.error.message);
        })).subscribe((res: any) => {
          if (res.code == 200) {
            this.toastr.success(res.message);
            this.cancel(true);
          } else {
            this.toastr.error(res.message);
          }
        })
    }
  }

  cancel(value: boolean) {
    this.dialogRef.close({
      success: value,
      action: this.localData.action,
    })
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    if (this.subscriptionDetail) this.subscriptionDetail.unsubscribe();
  }

}
