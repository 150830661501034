import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { LoginComponent } from './modules/auth/login/login.component';
import { authGuard, authGuardafterLogin, } from './guards/auth.guard';
import { PrimarySetupComponent } from './modules/auth/primary-setup/primary-setup.component';
import { AccountProtectionComponent } from './modules/auth/account-protection/account-protection.component';
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component';
import { SignupComponent } from './modules/auth/signup/signup.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { LayoutComponent } from './shared/components/common/layout/layout.component';
import { UnderDevelopmentComponent } from './shared/components/under-development/under-development.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ListComponent } from './modules/bar-inventory/list/list.component';
import { BarStationComponent } from './modules/bar-station/bar-station.component';
import { BillingComponent } from './modules/billing/pages/billing/billing.component';
import { PaymentStatusComponent } from './modules/billing/pages/payment-status/payment-status.component';
import { DeviceAddComponent } from './modules/device-management/add/add.component';
import { DeviceEditComponent } from './modules/device-management/edit/edit.component';
import { DeviceListComponent } from './modules/device-management/list/list.component';
import { DeviceViewComponent } from './modules/device-management/view/view.component';
import { EditMenuComponent } from './modules/food-menus/edit-menu/edit-menu.component';
import { FoodListComponent } from './modules/food-menus/food-list/food-list.component';
import { AddItemComponent } from './modules/inventory-management/add-item/add-item.component';
import { InventoryListComponent } from './modules/inventory-management/inventory-list/inventory-list.component';
import { KitchenStationComponent } from './modules/kitchen-station/kitchen-station.component';
import { OnlineOrderingComponent } from './modules/online-ordering/online-ordering.component';
import { OrderHistoryComponent } from './modules/order-history/order-history.component';
import { AddLinesProductComponent } from './modules/purchase-order/add-lines-product/add-lines-product.component';
import { CreatePurchaseOrderComponent } from './modules/purchase-order/create-purchase-order/create-purchase-order.component';
import { PurchaseListComponent } from './modules/purchase-order/purchase-list/purchase-list.component';
import { AddReservationComponent } from './modules/reservation/add-reservation/add-reservation.component';
import { ReservationListComponent } from './modules/reservation/reservation-list/reservation-list.component';
import { MyOrdersComponent } from './modules/retail/checkout/my-orders/my-orders.component';
import { PaymentComponent } from './modules/retail/checkout/payment/payment.component';
import { ProductListingComponent } from './modules/retail/checkout/product-listing/product-listing.component';
import { CreateInvoiceComponent } from './modules/retail/invoice/create-invoice/create-invoice.component';
import { InvoiceListComponent } from './modules/retail/invoice/invoice-list/invoice-list.component';
import { RefundListComponent } from './modules/retail/manage-refunds/refund-list/refund-list.component';
import { RefundRequestComponent } from './modules/retail/manage-refunds/refund-request/refund-request.component';
import { CreateMenuComponent } from './modules/retail/menu/create-menu/create-menu.component';
import { MenuListComponent } from './modules/retail/menu/menu-list/menu-list.component';
import { AddEditProductComponent } from './modules/retail/product-management/add-edit-product/add-edit-product.component';
import { ProductListComponent } from './modules/retail/product-management/product-list/product-list.component';
import { ProductViewComponent } from './modules/retail/product-management/product-view/product-view.component';
import { TransactionListComponent } from './modules/retail/transaction/transaction-list/transaction-list.component';
import { AddCategoryComponent } from './modules/settings/components/categories/add-category/add-category.component';
import { CreateEditSubVendorComponent } from './modules/settings/components/sub-vendor-management/create-edit-sub-vendor/create-edit-sub-vendor.component';
import { SubVendorManagementComponent } from './modules/settings/components/sub-vendor-management/sub-vendor-management.component';
import { SettingsComponent } from './modules/settings/settings.component';
import { TableListComponent } from './modules/table-management/table-list/table-list.component';
import { WaitingListComponent } from './modules/waiting-list/waiting-list/waiting-list.component';
import { WaitingTablesComponent } from './modules/waiting-list/waiting-tables/waiting-tables.component';
import { PolicyAndTermConditionsComponent } from './modules/policy-and-term-conditions/policy-and-term-conditions.component';
import { AddVendorComponent } from './modules/vendor-management/add-vendor/add-vendor.component';
import { VendorListComponent } from './modules/vendor-management/vendor-list.component';
import { AccessDeniedComponent } from './shared/components/common/access-denied/access-denied.component';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [authGuardafterLogin],
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [authGuardafterLogin],
  },
  {
    path: 'primary-setup',
    component: PrimarySetupComponent,
    canActivate: [authGuard],
  },
  {
    path: 'account-protection',
    component: AccountProtectionComponent,
    canActivate: [authGuard],
  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'terms-and-conditions', component: PolicyAndTermConditionsComponent },
  { path: 'privacy-policy', component: PolicyAndTermConditionsComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [authGuard],
      },
      {
        path: 'checkout',
        component: ProductListingComponent,
        canActivate: [authGuard],
      },
      {
        path: 'my-orders',
        component: MyOrdersComponent,
        canActivate: [authGuard],
      },
      {
        path: 'kitchen-station',
        component: KitchenStationComponent,
        canActivate: [authGuard],
      },
      {
        path: 'reservation',
        children: [
          {
            path: '',
            component: ReservationListComponent,
            canActivate: [authGuard],
          },
          {
            path: 'add',
            component: AddReservationComponent,
            canActivate: [authGuard],
          },
          {
            path: 'edit/:id',
            component: AddReservationComponent,
            canActivate: [authGuard],
          },
        ],
      },
      {
        path: 'bar-station',
        component: BarStationComponent,
        canActivate: [authGuard],
      },
      {
        path: 'order-history',
        component: OrderHistoryComponent,
        canActivate: [authGuard],
      },
      {
        path: 'bar-inventory',
        children: [
          // {
          //   path: 'edit',
          //   component: EditComponent,
          //   canActivate: [authGuard],
          // },
          {
            path: '**',
            component: ListComponent,
            pathMatch: 'full',
            canActivate: [authGuard],
          },
        ],
      },
      {
        path: 'settings',
        children: [
          {
            path: 'add',
            component: AddCategoryComponent,
            canActivate: [authGuard],
          },
          {
            path: 'sub-vendor',
            component: SubVendorManagementComponent,
            canActivate: [authGuard],
            children: [
              {
                path: 'create',
                component: CreateEditSubVendorComponent,
                canActivate: [authGuard],
              },
              {
                path: 'edit/:id',
                component: CreateEditSubVendorComponent,
                canActivate: [authGuard],
              },
            ],
          },
          {
            path: '**',
            component: SettingsComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'food-menu',
        children: [
          {
            path: 'add',
            component: EditMenuComponent,
            canActivate: [authGuard],
          },
          {
            path: 'edit/:id',
            component: EditMenuComponent,
            canActivate: [authGuard],
          },
          {
            path: '**',
            component: FoodListComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'device-management',
        children: [
          {
            path: 'list',
            component: DeviceListComponent,
          },
          {
            path: 'add',
            component: DeviceAddComponent,
          },
          {
            path: 'edit',
            component: DeviceEditComponent,
          },
          {
            path: 'view',
            component: DeviceViewComponent,
          },
        ],
      },

      {
        path: 'inventory-management',
        children: [
          {
            path: 'add',
            component: AddItemComponent,
            canActivate: [authGuard],
          },
          {
            path: 'edit/:id',
            component: AddItemComponent,
            canActivate: [authGuard],
          },
          {
            path: '**',
            component: InventoryListComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'vendor-list',
        children: [
          {
            path: '',
            component: VendorListComponent,
          },
          {
            path: 'add',
            component: AddVendorComponent,
          },
          {
            path: 'edit/:id',
            component: AddVendorComponent,
          },
        ],
      },
      {
        path: 'purchase-order',
        children: [
          {
            path: 'create',
            component: CreatePurchaseOrderComponent,
          },
          {
            path: 'edit/:id',
            component: CreatePurchaseOrderComponent,
          },
          {
            path: 'add-lines-product',
            component: AddLinesProductComponent,
          },
          {
            path: '**',
            component: PurchaseListComponent,
            pathMatch: 'full',
          },
        ],
      },
      { path: 'table-management', 
        component: TableListComponent,
        canActivate: [authGuard],
       },
      {
        path: 'billing',
        children: [
          {
            path: 'payment',
            component: PaymentStatusComponent,
          },
          {
            path: '**',
            component: BillingComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'waiting-list',
        children: [
          {
            path: 'tables',
            component: WaitingTablesComponent,
          },
          {
            path: '**',
            component: WaitingListComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'product-list',
        children: [
          {
            path: 'add',
            component: AddEditProductComponent,
          },
          {
            path: 'edit/:id',
            component: AddEditProductComponent,
          },
          {
            path: 'view/:id',
            component: ProductViewComponent,
          },
          {
            path: '**',
            component: ProductListComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'invoice',
        children: [
          {
            path: 'create',
            component: CreateInvoiceComponent,
          },
          {
            path: 'edit/:id',
            component: CreateInvoiceComponent,
          },
          {
            path: '**',
            component: InvoiceListComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'transaction',
        component: TransactionListComponent,
      },
      {
        path: 'menu',
        children: [
          {
            path: 'create',
            component: CreateMenuComponent,
          },
          {
            path: 'edit/:id',
            component: CreateMenuComponent,
          },
          {
            path: '**',
            component: MenuListComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'refunds',
        children: [
          {
            path: 'request/:id',
            component: RefundRequestComponent,
          },
          {
            path: '**',
            component: RefundListComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'checkout/payment',
        component: PaymentComponent,
      },
      {
        path: 'online-ordering',
        component: OnlineOrderingComponent,
      },
      {
        path: 'under-development',
        component: UnderDevelopmentComponent,
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
      },

      { path: '**', component: DashboardComponent, pathMatch: 'full' },
    ],
  },

  { path: '**', pathMatch: 'full', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

