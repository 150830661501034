import { Component, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InventoryService } from '../Service/inventory.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConstantService } from '../../../shared/services/constant.service';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { catchError, delay, finalize, map, Observable, of, Subscription, throwError } from 'rxjs';
import { availableToSellList, itemForList } from '../../../model/const/common.const';
import { ImageLibraryComponent } from '../../settings/components/image-library/image-library.component';
import { ResponseMessage } from '../../../model/interfaces/req.res.interface';
import { ProductsService } from '../../retail/product-management/Service/products.service';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NumberOnlyDirective } from '../../../shared/directives/number-only.directive';
import { FlotNumberDirective } from '../../../shared/directives/flot-number.directive';

@Component({
  selector: 'app-add-item',
  standalone: true,
  imports: [
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    RouterModule,
    MatInputModule,
    MatRadioModule,
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    MatTooltipModule,
    NumberOnlyDirective,
    FlotNumberDirective,
  ],
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent {
  action: string = 'Add';
  productFormGroup!: FormGroup;
  productDetail: any;
  subscribe!: Subscription;
  subscribeGetAPI!: Subscription;
  availableToSell = availableToSellList;
  itemForList = itemForList;
  productId = 0;
  unitListData: any = [];
  menuListData: any = [];
  categoryListData: any = [];
  selectedImageList: any = [];
  isImageSelcted: boolean = true;
  selectedBranchLocation = 'select_business_location';
  private fb = inject(FormBuilder);
  private inventoryService = inject(InventoryService);
  private productService = inject(ProductsService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private dialog = inject(MatDialog);
  private encDecObj = inject(EncryptionService);
  public constantService = inject(ConstantService);
  moduleType: any = this.router.getCurrentNavigation()?.extras?.state;
  itemProgressLoader = false;

  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.itemProgressLoader = state;
    });
  }
  initialProductForm() {
    this.productFormGroup = this.fb.group({
      itemFor: [1, Validators.required],
      productName: ['', Validators.required],
      productDescription: [''],
      productImage: [''],
      productPrice: ['', Validators.required],
      productUnits: [''],
      productStock: [''],
      productAvailableToSell: [1, Validators.required],
      productMenu: [''],
      lowStockAlert: ['', this.lowStockAlertValidator.bind(this)],
      branchLocation: [''],
      productCategory: ['', Validators.required],
    });

    if (this.moduleType?.inventoryData) {
      const itemFor = this.moduleType.inventoryData == 1 ? 1 : 2;
      this.productFormGroup.controls['itemFor'].setValue(itemFor);
    }

    this.productFormGroup.get('productStock')?.valueChanges.subscribe(() => {
      this.productFormGroup.get('lowStockAlert')?.updateValueAndValidity();
    });
  }
  ngOnInit(): void {
    this.initialProductForm();
    this.constantService.progressLoader.next(true);
    const id = this.route.snapshot.params['id'];
    if (localStorage.getItem(this.selectedBranchLocation)) {
      const id = this.encDecObj.getDecrypt(localStorage.getItem(this.selectedBranchLocation));
      this.productFormGroup.controls['branchLocation'].setValue(Number(id))
    }
    this.getItemUnits();
    this.getMenus();
    this.getCategories();
    if (id) {
      this.productId = id;
      this.action = 'Edit';
      this.getProductDetail(id);
    }
  }

  lowStockAlertValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const availableStock = +this.productFormGroup?.get('productStock')?.value || 0;
    const lowStockAlert = +control.value;

    if (control.value && lowStockAlert > availableStock) {
      return { exceedsAvailableStock: true }; // Error if entered value exceeds stock
    }

    return null; // Valid
  }

  getProductDetail(id: number) {
    if (this.subscribeGetAPI) this.subscribeGetAPI.unsubscribe();
    this.subscribeGetAPI = this.productService.getProductDetail(id).pipe(
      catchError((error) => {
        this.constantService.progressLoader.next(false);
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.productDetail = res.data;
          this.setFormData(res.data)
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  setFormData(data: any) {
    this.productFormGroup.patchValue({
      ItemFor: data.item_for,
      productName: data.name,
      productDescription: data.description,
      productPrice: data.price,
      productUnits: data.unit,
      productStock: data.available_stock,
      productAvailableToSell: data.available_to_sell,
      productMenu: data.food_menu_id,
      productCategory: data.category_id,
      lowStockAlert: data.low_stock_alert,
    })
    this.selectedImageList = data.item_image
    this.constantService.progressLoader.next(false);
  }

  //#region Add,update Product API
  addUpdateProduct() {
    this.productFormGroup.markAllAsTouched();
    if (this.productFormGroup.invalid) return;

    const selectedImageIds = this.selectedImageList.map((item: any) => item.media_id).join(',');
    if (!selectedImageIds && this.selectedImageList && this.selectedImageList.length == 0) {
      this.isImageSelcted = false;
      return;
    } else this.isImageSelcted = true;

    let formData = new FormData();
    formData.append('item_for', this.productFormGroup.value.itemFor);
    formData.append('name', this.productFormGroup.value.productName);
    formData.append('description', this.productFormGroup.value.productDescription);
    formData.append('media_id', selectedImageIds);
    formData.append('price', this.productFormGroup.value.productPrice);
    formData.append('unit', this.productFormGroup.value.productUnits);
    formData.append('available_stock', this.productFormGroup.value.productStock);
    formData.append('available_to_sell', this.productFormGroup.value.productAvailableToSell);
    formData.append('food_menu_id', this.productFormGroup.value.productMenu);
    formData.append('category_id', this.productFormGroup.value.productCategory);
    formData.append('low_stock_alert', this.productFormGroup.value.lowStockAlert);
    formData.append('business_location_id', this.productFormGroup.value.branchLocation);

    this.constantService.progressLoader.next(true);
    if (this.subscribe) this.subscribe.unsubscribe();
    if (this.action == 'Add') {
      this.subscribe = this.productService.addProduct(formData).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message)
        })).subscribe((res: ResponseMessage) => {
          if (res.code === 200) {
            this.toastr.success(res.message);
            this.backToList();
          } else {
            this.toastr.error(res.message);
          }
        })
    } else if (this.action == 'Edit') {
      formData.append('id', this.productDetail.item_id)
      this.subscribe = this.productService.updateProduct(formData).pipe(
        finalize(() => this.constantService.progressLoader.next(false)),
        catchError((error) => {
          this.toastr.error(error.error.message);
          return throwError(() => error.error.message)
        })).subscribe((res: ResponseMessage) => {
          if (res.code === 200) {
            this.toastr.success(res.message);
            this.backToList();
          } else {
            this.toastr.error(res.message);
          }
        })
    }
  }
  //#endregion Add,update Product API

  openImageLibrary() {
    let data = {
      type: 1,
      name: 'product',
      selectedProductImage: this.selectedImageList
    }
    const dialogRef = this.dialog.open(ImageLibraryComponent, {
      width: '1000px',
      autoFocus: false,
      panelClass: ['modal-wrapper'],
      data,
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res?.success) {
        const tempImageArray = [...res.selectImage];
        const uniqueMediaMap = new Map();

        for (const item of tempImageArray) {
          uniqueMediaMap.set(item.media_id, item);
        }

        this.selectedImageList = Array.from(uniqueMediaMap.values());
        this.isImageSelcted = this.selectedImageList.length > 0;
      }
    })
  }

  removeSelectedImage(id: number) {
    const indexToRemove = this.selectedImageList.findIndex((item: any) => item.media_id === id);
    if (indexToRemove !== -1) {
      this.selectedImageList.splice(indexToRemove, 1);
      this.constantService.showSuccess('image_remove')
    }
  }
  //#region get list API's

  getItemUnits() {
    this.productService.getItemUnitsList().pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.unitListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  getMenus() {
    let payload = {
      business_location_id: this.productFormGroup.value.branchLocation,
    }
    this.productService.getMenuList(payload).pipe(
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.menuListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  getCategories() {
    this.productService.getCategoryList().pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message)
      })).subscribe((res: ResponseMessage) => {
        if (res.code === 200) {
          this.categoryListData = res.data.data;
        } else {
          this.toastr.error(res.message);
        }
      })
  }

  //#endregion get list API's
  backToList() {
    //moduleType=>  1 = management, 2= Bar
    if (this.moduleType && this.moduleType.inventoryData == 2) {
      this.router.navigate(['/bar-inventory']);
    } else {
      // this.moduleType && this.moduleType.inventoryData == 1
      this.router.navigate(['/inventory-management']);
    }
  }

  ngOnDestroy(): void {
    if (this.subscribe) this.subscribe.unsubscribe();
    if (this.subscribeGetAPI) this.subscribeGetAPI.unsubscribe();
  }
}
