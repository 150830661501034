import { Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConstantService } from 'src/app/shared/services/constant.service';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
  ],
  selector: 'app-no-records-available',
  templateUrl: './no-records-available.component.html',
  styleUrls: ['./no-records-available.component.scss']
})
export class NoRecordsAvailableComponent implements OnInit {

  private constantService = inject(ConstantService);
  themeType: any;

  constructor() { }

  ngOnInit() {
    this.themeType = this.constantService.getTheme();
  }

}
