import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioGroup, MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { NoRecordsAvailableComponent } from 'src/app/shared/components/common/no-records-available/no-records-available.component';
import { ConfirmationComponent } from 'src/app/shared/components/dialog/confirmation/confirmation.component';
import { MaxcharlengthPipe } from 'src/app/shared/pipes/maxcharlength.pipe';
import { ConstantService } from 'src/app/shared/services/constant.service';
import { VendorManagementService } from './service/vendor-management.service';


@Component({
  selector: 'app-vendor-list',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    MatRadioModule,
    RouterModule,
    InfiniteScrollModule,
    TranslateModule,
    MaxcharlengthPipe,
    NoRecordsAvailableComponent,
  ],
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss']
})
export class VendorListComponent {
  vendorService = inject(VendorManagementService);
  constantService = inject(ConstantService);
  toastr = inject(ToastrService);
  router = inject(Router);
  displayedColumns: string[] = ['name', 'email', 'number', 'description', 'address', 'action'];
  dataSource: any = [];
  vendorPayload: any = {
    page: 1,
    per_page: 15,
    search: ''
  }
  vendorListData: any = []
  pageNumber = 1;
  totalRecords: any = 0;
  subscriberList!: Subscription;
  maxcharLimit = 100;
  readonly dialog = inject(MatDialog);
  vendorProgressLoader = false;
  vendorPermission: any;

  constructor() {
    this.constantService.progressLoader.subscribe((state: boolean) => {
      this.vendorProgressLoader = state;
    });
  }

  ngOnInit(): void {
    const permission = this.constantService.getModuleWiseRole('vendor_management');

    if (permission?.update === 0 && permission?.delete === 0) {
      this.displayedColumns = this.displayedColumns.filter(column => column !== 'action');
    }
    this.vendorPermission = permission;
    this.constantService.progressLoader.next(true);
    this.getVendorList();
  }

  openDeleteVendorDialog(id: any, name: any) {
    let obj = {
      type: 6,
      title: name,
      message: 'are_you_sure_delete_vendor',
      buttonName: { cancel: 'cancel', yes: 'yes' },
      id: id,
    };
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
      data: obj,
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.deleteVendor(id);
      }
    })
  }

  createEditVendor(action: string, id?: number) {
    if (action == 'Add') {
      this.router.navigate(['/vendor-list/add']);
    } else {
      this.router.navigate([`/vendor-list/edit/${id}`]);
    }
  }

  getVendorList() {
    this.constantService.progressLoader.next(true);
    this.vendorPayload.page = 1;
    if (this.subscriberList) this.subscriberList.unsubscribe();
    this.subscriberList = this.vendorService.getVendorList(this.vendorPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })
    ).subscribe((res: any) => {
      if (res.code == 200) {
        this.totalRecords = res.data.pagination.total;
        this.dataSource = res.data.data;
        this.vendorListData = res.data.data;
      } else {
        this.toastr.error(res.error);
      }
    })
  }
  vendorListScrollDown() {
    this.constantService.progressLoader.next(true);
    this.vendorPayload.page = this.pageNumber + 1;
    if (this.subscriberList) this.subscriberList.unsubscribe();
    this.subscriberList = this.vendorService.getVendorList(this.vendorPayload).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })
    ).subscribe((res: any) => {
      if (res.code == 200) {
        this.totalRecords = res.data.pagination.total;
        this.vendorListData = [...this.vendorListData, ...res.data.data];
        this.dataSource = this.vendorListData;
      } else {
        this.toastr.error(res.message);
      }
    })
  }
  deleteVendor(id: any) {
    this.constantService.progressLoader.next(true);
    if (this.subscriberList) this.subscriberList.unsubscribe();
    this.subscriberList = this.vendorService.deleteVendor(id).pipe(
      finalize(() => this.constantService.progressLoader.next(false)),
      catchError((err) => {
        this.toastr.error(err.error.message);
        return throwError(() => err.error.message)
      })
    ).subscribe((res: any) => {
      if (res.code == 200) {
        this.getVendorList();
        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.error);
      }
    })
  }
  searchVendor(event: any) {
    let serachVendor = event.target.value.trimStart();
    const searchText = serachVendor.trim();
    if (searchText !== this.vendorPayload.search) {
      if (searchText && searchText.length > 1) {
        this.vendorPayload.search = searchText;
        this.getVendorList();
      } else if (!searchText && this.vendorPayload.search !== '') {
        this.vendorPayload.search = '';
        this.getVendorList();
      }
    }
  }
}
