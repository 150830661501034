import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LogoutComponent } from '../../../../modules/auth/logout/logout.component';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ConstantService } from '../../../services/constant.service';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { BranchLocationComponent } from '../branch-location/branch-location.component';
import { EncryptionService } from 'src/app/shared/services/encryption.service';
import { moduleList, moduleListTemp } from 'src/app/model/const/common.const';
import { ResponseMessage } from 'src/app/model/interfaces/req.res.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    TranslateModule,
    CommonModule,
    BranchLocationComponent
  ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  private ref = inject(ElementRef);
  readonly dialog = inject(MatDialog);
  private constantService = inject(ConstantService);
  private encDecObj = inject(EncryptionService);
  readonly router = inject(Router);
  private toastr = inject(ToastrService);
  moduleListArray: any; 
  currentUrl = '';
  tempModuleName = 'home';
  modulePermission = 'module_permission';
  subscribeSubject!: Subscription;
  subscribe!: Subscription;
  deviceType = this.constantService.getDeviceType();
  productPermission: any;
  @Input() isOpenSideBar: boolean = false;
  @Output() openCloseSideMenu = new EventEmitter();

  constructor() {
    this.getRolePermission();
    if (this.subscribeSubject) this.subscribeSubject.unsubscribe();
    this.subscribeSubject = this.constantService.selectedSidebarMenu.subscribe(res => {
      if (res.success) {
        this.setModuleTitle(res.title);
      }
    })
    this.constantService.langChangeSub.subscribe(res => {
      if (res) {
        this.checkModuleTitleName();
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.deviceType = this.constantService.getDeviceType();
  }
  ngOnInit(): void { }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (
      target.id !== 'menuItem' &&
      target.parentElement &&
      target.parentElement.id !== 'mobileMenu'
    ) {
      // this.openCloseSideMenu.emit();
    }
  }

  getModules(data: any) {

    this.productPermission = this.constantService.getModuleWiseRole('product_management');
    const permissionModules = data;
    const viewableModules = moduleListTemp.filter((module: { slug: string; }) =>
      permissionModules.some((permission: { slug: string; view: number; }) => (permission.slug === module.slug && permission.view === 1) || module.slug === 'settings')
    );

    this.moduleListArray = viewableModules;
    this.checkModuleTitleName();
    this.redirectUnAuth();
  }
  checkModuleTitleName() {
    const urlSegment = this.router.url.split("/")[1];
    this.currentUrl = `/${urlSegment}`;
    const module = this.moduleListArray?.find((item: { route: string; }) => item?.route === this.currentUrl);
    module?.translateVar && this.setModuleTitle(module.translateVar);
  }

  redirectUnAuth() {
    const index = this.moduleListArray?.findIndex((item: { route: string; }) => item?.route === this.currentUrl);
    if (index == -1) {
      this.router.navigate(['/access-denied'], { replaceUrl: true });
      return;
    }
  }

  getRolePermission() {
    if (this.subscribe) this.subscribe.unsubscribe();
    this.constantService.progressLoader.next(true);
    this.subscribe = this.constantService.getUserModulePermission().pipe(
      finalize(() => (this.constantService.progressLoader.next(false))),
      catchError((error) => {
        this.toastr.error(error.error.message);
        return throwError(() => error.error.message);
      })
    ).subscribe((res: ResponseMessage) => {
      if (res.code === 200) {
        const tempModuleList = this.encDecObj.setEncrypt(JSON.stringify(res.data));
        localStorage.setItem(this.modulePermission, tempModuleList);
        this.getModules(res.data);
      } else {
        this.toastr.error(res.message);
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(LogoutComponent, {
      width: '564px',
      panelClass: ['modal-wrapper'],
    });
  }

  setModuleTitle(name: string) {
    this.tempModuleName = name;
    this.constantService.setModuleTitle(name);
    this.openCloseSideMenu.emit();
  }

  closeSidemenu() {
    this.openCloseSideMenu.emit();
  }
  
  redirectToModule() {
    let res = {
      success: true,
      title: 'product_management'
    }
    this.constantService.selectedSidebarMenu.next(res);
    this.router.navigate(['/product-list/add'])
  }

  ngOnDestroy(): void {
    if (this.subscribeSubject) this.subscribeSubject.unsubscribe();
  }
}
